import {
  mapJsonToSupplierContainerEntity,
  SupplierContainerEntity,
} from "../container/container_supplier_entity";

export interface SupplierEntity {
  id: string;
  name: string;
  contactName: string;

  phone: string;
  email: string;
  address: string;
  city: string;
  country: string;
  accountCurrency: string;
  bankName: string;
  accountType: string;
  accountNumber: number;
  swiftCode: string;
  supplierType: string;
  rut: string;
  containers: SupplierContainerEntity[];
}
export function mapJsonToSupplierEntity(json): SupplierEntity {
  return {
    id: json.id,
    name: json.name,
    contactName: json.contact_name,

    phone: json.phone,
    email: json.email,
    address: json.address,
    city: json.city,
    country: json.country,
    accountCurrency: json.account_currency,
    bankName: json.bank_name,
    accountType: json.account_type,
    accountNumber: json.account_number,
    swiftCode: json.swift_code,
    supplierType: json.supplier_type,
    rut: json.rut,
    containers: json.containers.map(mapJsonToSupplierContainerEntity),
  } as SupplierEntity;
}

import { ContainerStatusEnum } from "./container_entity";

import {
  ProductEntity,
  mapJsonToProductEntity,
} from "../product/product_entity";

export interface SupplierContainerEntity {
  id: string; // Corresponde a models.CharField
  status: ContainerStatusEnum; // Corresponde a models.IntegerField con choice
  containerProducts: SupplierContainerProductEntity[];
  debt: number;
}

export function mapJsonToSupplierContainerEntity(
  json
): SupplierContainerEntity {
  return {
    id: json.id,
    status: json.status,
    containerProducts: json.container_products.map(
      mapJsonToSupplierContainerProductEntity
    ),
    debt: json.debt,
  } as SupplierContainerEntity;
}

export interface SupplierContainerProductEntity {
  id: string;
  product: ProductEntity; // Debe definirse una interfaz Product correspondiente
  quantity: number;
  createdAt: string; // Utilizamos string para las fechas en formato ISO
  updatedAt: string; // Utilizamos string para las fechas en formato ISO
}

export function mapJsonToSupplierContainerProductEntity(
  json
): SupplierContainerProductEntity {
  return {
    id: json.id,
    product: mapJsonToProductEntity(json.product),
    quantity: json.quantity,
    createdAt: json.created_at,
    updatedAt: json.updated_at,
  } as SupplierContainerProductEntity;
}

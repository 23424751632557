import GetContainerClientInfo from "../../../domain/usecases/get_container_client_info_usecase";
import { setContainerClient } from "../../redux/slices/admin_simple_container_slice";
import apiRepository from "../../../data/repository";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const GetContainerClientInfoEvent = (id: string) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetContainerClientInfo(apiRepository);

    const result = await usecase.execute({id: id});

    result.fold(
        () => null,
        (data) => {
            dispatch(setContainerClient(data));
        }
    );
}
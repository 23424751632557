import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SideMenu from '../../../components/cfo/layout/SideMenu';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { GetCompanyPaymentsEvent } from '../../../events/company/get_company_payments_event';
import CompanyDetails from '../../../components/cfo/clients/ClientDetail';
import CompanyPayments from '../../../components/cfo/clients/ClientPayments';
import { PutTransactionEvent } from '../../../events/cfo/put_transaction_event';
import { updateTransaction } from '../../../redux/slices/transaction_slice';

const ClientDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { companyPayments } = useAppSelector((state) => state.company_payments);
  const { companies } = useAppSelector((state) => state.company);
  const currentTransactions = useAppSelector((state) => state.transaction.transactions);

  // Buscar la compañía correspondiente usando el ID
  const company = companies.find((company) => company.id === id);

  const handleTransactionStatus = async (transactionId: string, paymentId: string, status: number) => {
    try {
      const updatedTransaction = await PutTransactionEvent(transactionId, paymentId, status);
      console.log("Updated Transaction: ", updatedTransaction); // Verificas la transacción actualizada
      dispatch(updateTransaction(updatedTransaction));
      console.log("Updated Transaction: ", updatedTransaction); // Verificas la transacción actualizada


    } catch (error) {
      console.error(error);
    }
    };
  

  // Dispatch para obtener los pagos de la compañía al cargar la página
  React.useEffect(() => {
    if (id) {
      dispatch(GetCompanyPaymentsEvent(id)); // Llamar al evento para obtener los pagos de la compañía
    }
  }, [id, dispatch]);

  if (!company) {
    return <div>Compañía no encontrada</div>;
  }

  return (
    <>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 5 }
            }}
          >
            {/* Pasamos los datos de la compañía al componente CompanyDetails */}
            <CompanyDetails
              name={company.name}
              rut={company.rut}
              address={company.address}
              phone={company.phone}
              email={company.email}
              owner={company.owner}
            />

            {/* Pasamos los pagos al componente CompanyPayments */}
            <CompanyPayments payments={companyPayments} handleTransactionStatus={handleTransactionStatus}/>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ClientDetailPage;


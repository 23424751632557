import React from 'react';
import { Modal, Button, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { useEffect, useState } from 'react';


const PaymentDetailsModal = ({ open, handleClose, proforma, handleTransactionStatus, getStatusLabel }) => {
  console.log("Proforma Payments: ", proforma.payments); // Aquí verificas los pagos de la proforma

  // Selecciona la transacción actualizada del estado de Redux
  const transactions = useSelector((state: RootState) => state.transaction.transactions); 

  // Estado local para controlar las transacciones actualizadas por el usuario
  const [updatedTransactions, setUpdatedTransactions] = useState({});

  // Encuentra la proforma actualizada (asumiendo que proforma tiene una lista de IDs de transacciones)
  const updatedProforma = {
    ...proforma,
    payments: proforma.payments.map(payment => {
      return {
        ...payment,
        transactions: payment.transactions.map(id => transactions.find(t => t.id === id))
      };
    })
  };

  const handleButtonClick = (transactionId, paymentId, status) => {
    setUpdatedTransactions(prev => ({
      ...prev,
      [transactionId]: status
    }));
    handleTransactionStatus(transactionId, paymentId, status);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh', // Ajusta la altura máxima del modal
        overflowY: 'auto' // Hace que el contenido sea desplazable
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detalles de Pagos y Transacciones
        </Typography>
        {proforma.payments && proforma.payments.length > 0 ? (
          proforma.payments.map((paymentDetail) => (
            <Box key={paymentDetail.id} sx={{ marginTop: '15px', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Pago ID: {paymentDetail.id}</Typography>
              <Typography variant="body2">Monto a pagar: ${Math.trunc(paymentDetail.amount)}</Typography>
              <Typography variant="body2">Fecha límite: {paymentDetail.deadline}</Typography>

              {/* Transacciones del pago */}
              <Typography variant="body2" sx={{ marginTop: '10px', fontWeight: 'bold' }}>Transacciones:</Typography>
              {paymentDetail.transactions.map((transaction, index) => {
                const isUpdated = updatedTransactions[transaction.id] !== undefined;
                const updatedStatus = updatedTransactions[transaction.id];

                return (
                  <Box key={transaction.id} sx={{ marginLeft: '20px', marginTop: '5px' }}>
                    <Typography variant="body2">Transacción ID: {transaction.id}</Typography>
                    <Typography variant="body2">Monto: ${Math.trunc(transaction.amount)}</Typography>
                    <Typography variant="body2">
                      Fecha: {new Date(transaction.created_at).toLocaleDateString('es-ES', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })} a las {new Date(transaction.created_at).toLocaleTimeString('es-ES', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Typography>
                    <Typography variant="body2">
                      Estado: {isUpdated ? (updatedStatus === 1 ? 'Aceptada' : 'Rechazada') : getStatusLabel(transaction.status)}
                    </Typography>
                    {/* Botones para aceptar o rechazar transacción */}
                    {!isUpdated && transaction.status === 0 && (
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleButtonClick(transaction.id, paymentDetail.id, 1)} // Aprobar
                        >
                          Aprobar
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleButtonClick(transaction.id, paymentDetail.id, 2)} // Rechazar
                        >
                          Rechazar
                        </Button>
                      </Box>
                    )}
                    {index !== paymentDetail.transactions.length - 1 && (
                      <Box sx={{ borderTop: '1px solid #ccc', marginTop: '10px' }} />
                    )}
                  </Box>
                );
              })}
            </Box>
          ))
        ) : (
          <Typography variant="body2">No hay pagos registrados para esta proforma.</Typography>
        )}
      </Box>
    </Modal>
  );
};


export default PaymentDetailsModal;
import React, { FC, useState ,useEffect} from 'react';
import { Box, Typography, Grid, TextField, Button, Paper, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch} from "../../hooks/hooks";
import {PutPasswordEvent} from "../../events/user/put_password_event";
import {PutProfileEvent} from "../../events/user/put_profile_event";

const UserProfile: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {user} = useAppSelector((state) => state.user);
    const [userInfo, setUserInfo] = useState({
        firstName: user.first_name,
        secondName: user.last_name,
        email: user.email
    });
    const [password, setPassword] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    useEffect(() => {
        if (user && user.company) {
            setUserInfo({
                firstName: user.first_name,
                secondName: user.last_name,
                email: user.email
            });
        }
    }, [user]);

    
    const [passwordError, setPasswordError] = useState(false);

    const handleInputChange = (field: string, value: string) => {
        setUserInfo(prevState => ({ ...prevState, [field]: value }));
    };
    const handlePasswordChange = (field: string, value: string) => {
        setPassword(prevState => ({ ...prevState, [field]: value }));
    };
    const saveChanges = () => {
        if(user.first_name!=null&&user.last_name!=null&&user.email!=null){
            const params = { email: userInfo.email,  first_name:userInfo.firstName, last_name:userInfo.secondName};
            dispatch(PutProfileEvent(params));
        }
    };

    const saveChangesPassword = async () => {
        if (password.newPassword !== password.confirmNewPassword) {
            setPasswordError(true);
            return;
        }
        const params = { old_password: password.currentPassword,  new_password: password.newPassword, confirm_password: password.confirmNewPassword};
        await dispatch(PutPasswordEvent(params));
        setPassword({currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''});
        setPasswordError(false);
    }
    const handleBackClick = () => {
        navigate('/profile');
    };

    return (
        <Box sx={{ padding: 4, maxWidth: '900px', margin: '0 auto' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 3 , alignContent:'center',justifyContent:'center'}}>
                        <Typography variant="h6">Información de Perfil</Typography>
                        <Divider sx={{ marginY: 2 }} />
                        <TextField
                            label="Primer Nombre"
                            value={userInfo.firstName}
                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Segundo Nombre"
                            value={userInfo.secondName}
                            onChange={(e) => handleInputChange('secondName', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Correo Electrónico"
                            value={userInfo.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Button variant="outlined" color="secondary" onClick={handleBackClick} fullWidth>
                                    Volver
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onClick={saveChanges} fullWidth>
                                    Actualizar Cambios
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: 3 }}>
                        <Typography variant="h6">Cambiar Contraseña</Typography>
                        <Divider sx={{ marginY: 2 }} />

                        <TextField
                            label="Contraseña Actual"
                            type="password"
                            variant="standard"
                            value={password.currentPassword}
                            onChange={(e) => handlePasswordChange('currentPassword', e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Nueva Contraseña"
                            type="password"
                            variant="standard"
                            value={password.newPassword}
                            onChange={(e) => handlePasswordChange('newPassword', e.target.value)}
                            fullWidth
                            error={passwordError}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Confirmar Nueva Contraseña"
                            type="password"
                            variant="standard"
                            value={password.confirmNewPassword}
                            onChange={(e) => handlePasswordChange('confirmNewPassword', e.target.value)}
                            fullWidth
                            error={passwordError}
                            helperText={passwordError ? "Las contraseñas no coinciden" : ""}
                            sx={{ mb: 2 }}
                        />
                        <Button variant="contained" color="secondary" onClick={saveChangesPassword} fullWidth>
                            Cambiar la Contraseña
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserProfile;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  LinearProgress,
 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import StepProgress from "./step_progress";
import { ProgressStepEntity } from "../../../domain/entities/progress_step";


interface ImportCardProps {
  title: string;
  image: string;
  status: string;
  nextStep: string;
  progressSteps: ProgressStepEntity[];
  paymentProgress: number;
  amountPaid: number;
  totalAmount: number;
  debt: number;
  dueDate: string;
  id: string;
  materials: string;
  medidas: string;
  peso: string;
}

const ImportCard: React.FC<ImportCardProps> = ({
  title,
  image,
  status,
  nextStep,
  progressSteps,
  paymentProgress,
  amountPaid,
  totalAmount,
  debt,
  dueDate,
  id,
  materials,
  medidas,
  peso,
}) => {
  const navigate = useNavigate();

  const handleEstadoDeCuentaClick = () => {
    navigate(`/estadodecuenta/${id}`);
  };
  const formatAmount = (amount) => {
    // Elimina puntos, convierte a número y luego aplica el formato con Intl.NumberFormat
    const numericAmount = Number(amount.toString().replace(/\./g, ""));
    return isNaN(numericAmount)
      ? amount
      : new Intl.NumberFormat("es-CL").format(numericAmount);
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
        p: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: "12px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Sección de imagen y detalles del producto */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "40%",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          p: 2,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "8px",
            marginRight: "16px",
          }}
        />
        <Box>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "500", marginBottom: "30px", color: "#0A66A5" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Material: {materials}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Medidas: {medidas}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Peso: {peso}
          </Typography>
        </Box>
      </Box>

      {/* Sección de progreso y detalles financieros */}
      <CardContent sx={{ width: "55%" }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" sx={{ color: "green" }}>
            Estado: {status}
          </Typography>
          {status !== "Entregado" && (
            <Typography variant="body1" sx={{ color: "red" }}>
              {nextStep}
            </Typography>
          )}
        </Box>
        <StepProgress steps={progressSteps} />
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
            Abonado al costo del producto
          </Typography>
          <LinearProgress
            variant="determinate"
            value={paymentProgress}
            sx={{ mb: 1 }}
          />
          <Typography variant="body2" color="text.secondary">
            {formatAmount(amountPaid.toFixed(0))} USD de{" "}
            {formatAmount(totalAmount.toFixed(0))} USD (
            {paymentProgress.toFixed(1)}%)
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Deuda:</strong>{" "}
            <span style={{ color: "red" }}>${formatAmount(debt)}</span>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Vencimiento:</strong> {dueDate}
          </Typography>
        </Box>
      </CardContent>

      {/* Botones */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 1 }}
          onClick={handleEstadoDeCuentaClick}
        >
          Estado de Cuenta
        </Button>
      </Box>
    </Card>
  );
};

export default ImportCard;

import apiRepository from "../../../data/repository";
import GetProductFullDetailUsecase from "../../../domain/usecases/get_product_full_detail_use_case";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const GetProductFullDetailEvent = (product_id) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetProductFullDetailUsecase(apiRepository);

    const result = await usecase.execute(product_id);

    result.fold(
        () => { },
        () => {
            dispatch(setStatus(StatusLayoutEnum.Success));
        }
    );
    return result.right;
}


// src/domain/entities/proforma/proforma_entity.ts
import {
  ContainerMovementEntityJson,
  ContainerMovementEntity,
  mapJsonToContainerMovementEntity,
} from "../container_movement/container_movement_entity";

import {
  PaymentEntity,
  mapJsonToPaymentEntity,
} from "../payment/payment_entity";
import { ProgressStepEntity } from "../progress_step/index";

interface ProformaJson {
  id: string;
  product_name: string;
  product_image_url: string;
  container_movements: ContainerMovementEntityJson[];
  payments: PaymentEntityJson[];
  total_amount: number;
  amount_paid: number;
  debt: number;
  payment_progress: number;
  current_step: string | null;
  next_step: string | null;
  progress_steps: ProgressStepEntity[];

  proforma_deadline: string
  product_weight: string;
  product_materials: string;
  product_size: string;

}

interface PaymentEntityJson {
  id: string;
  amount: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface ProformaEntity {
  id: string;
  productName: string;
  productImageUrl: string;
  containerMovements: ContainerMovementEntity[];
  payments: PaymentEntity[];
  totalAmount: number;
  amountPaid: number;
  debt: number;
  paymentProgress: number;
  current_step: string | null;
  next_step: string | null;
  progressSteps: ProgressStepEntity[];
  proforma_deadline: string;

  productWeight: string;
  productMaterials: string;
  productSize: string;

}

export function mapJsonToProformaEntity(json: ProformaJson): ProformaEntity {
  const steps = json.container_movements.map((movement) => {
    return {
      status: movement.status,
      is_completed: movement.is_completed,
    } as ProgressStepEntity;
  });

  return {
    id: json.id,
    productName: json.product_name,
    productImageUrl: json.product_image_url,
    productSize: json.product_size,
    productWeight: json.product_weight,
    productMaterials: json.product_materials,
    containerMovements: json.container_movements.map(
      mapJsonToContainerMovementEntity
    ),
    payments: json.payments.map(mapJsonToPaymentEntity),
    totalAmount: json.total_amount,
    amountPaid: json.amount_paid,
    debt: json.debt,
    paymentProgress: json.payment_progress,
    current_step: json.current_step,
    next_step: json.next_step,
    progressSteps: steps,
    proforma_deadline: json.proforma_deadline,

  };
}

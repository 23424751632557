export interface StatusEntity {
    id: number;
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

export function mapJsonToStatusEntity(json): StatusEntity {
    return {
        id: json.id,
        name: json.name,
        description: json.description,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as StatusEntity;
}
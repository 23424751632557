import React from "react";

import PaidIcon  from '@mui/icons-material/Paid';
import {

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { SupplierContainerEntity, SupplierContainerProductEntity } from "../../../../domain/entities";

interface ContainerProductsModalProps {
  container: SupplierContainerEntity;
  open: boolean;
  onClose: () => void;
  onOpenPaymentDialog: () => void;
}

const ContainerProductsModal: React.FC<ContainerProductsModalProps> = ({ container, open, onClose, onOpenPaymentDialog }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>Productos del Contenedor {container?.id} </DialogTitle>
    <DialogContent>
      {container?.containerProducts?.length > 0 ? (
        <List>
          {container.containerProducts.map((product: SupplierContainerProductEntity, index: number) => (
            <Card key={index} sx={{ display: 'flex', marginBottom: 2 }}>
              <CardMedia
                component="img"
                sx={{ width: 150 }}
                image={product.product.imageUrl || "/default-image.jpg"}
                alt={product.product.name || "Imagen del producto"}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6">
                    {`Container Product ID: ${product.id || "No disponible"}`}
                  </Typography>
                  <Typography variant="subtitle1">
                    {`Nombre: ${product.product.name || "No disponible"}, Cantidad: ${product.quantity || "No disponible"}`}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No hay productos en este contenedor.</Typography>
      )}
    </DialogContent>
    <DialogTitle>Deuda del Contenedor {container?.id}: {container?.debt?.toFixed(0)} </DialogTitle>
    <DialogActions>
      <Button onClick={onOpenPaymentDialog} color="primary" startIcon={<PaidIcon />}>
        Realizar Pago
      </Button>
      <Button onClick={onClose} color="primary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

export default ContainerProductsModal;

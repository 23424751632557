import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { TransactionEntity } from '../entities';

interface PutTransactionParams {
    id: string;
    payment_id: string;
    status: number;
}


export default class PutTransactionUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: PutTransactionParams): Promise<Either<TransactionEntity>> {
        return this.repository.putTransaction(
            params.id,
            params.payment_id,
            params.status
        );
    }
}

import React, { useState } from "react";
import { Box, Typography, IconButton, Grid, Card } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface Proforma {
  productImageUrl: string;
  productName: string;
  totalAmount: number;
  current_step: string;
}

interface ProformaCarouselProps {
  proformas: Proforma[];
}

const ProformaCarousel: React.FC<ProformaCarouselProps> = ({ proformas }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 3;

  const handleNext = () => {
    if (currentIndex + itemsPerPage < proformas.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {/* Flecha izquierda */}
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: "10px",
          zIndex: 1,
          top: "25%",
          transform: "translateY(-50%)",
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>

      {/* Contenido de las proformas */}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ width: "80%", mt: -2, mx: "auto" }}
      >
        {proformas
          .slice(currentIndex, currentIndex + itemsPerPage)
          .map((proforma, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  padding: "15px",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderRadius: "7px",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-10px)",
                  },
                }}
              >
                <img
                  src={proforma.productImageUrl}
                  alt={proforma.productName}
                  style={{ maxHeight: "120px", objectFit: "contain" }}
                />
                <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 580 }}>
                  {proforma.productName}
                </Typography>
                {/* <Typography variant="body1" sx={{ color: "#0A66A5" }}>
                  Monto total: ${proforma.totalAmount}
                </Typography> */}
                <Typography variant="body2" sx={{ color: "GrayText" }}>
                  Estado actual: {proforma.current_step}
                </Typography>
              </Card>
            </Grid>
          ))}
      </Grid>

      {/* Flecha derecha */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: "10px",
          zIndex: 1,
          top: "25%",
          transform: "translateY(-50%)",
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ProformaCarousel;

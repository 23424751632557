// ProvidersCFO.tsx
import React, { useEffect, useState } from "react";
import { Container, Box, Button } from "@mui/material";
import ProviderCard from "./provider_card";
import ProviderTable from "./provider_table";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetSuppliersEvent } from "../../../events/admin/get_suppliers_event";

const ProvidersCFO: React.FC = () => {
  const dispatch = useAppDispatch();
  const { suppliers } = useAppSelector((state) => state.suppliers);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  useEffect(() => {
    dispatch(GetSuppliersEvent());
  }, [dispatch]);

  const handleSelectSupplier = (supplier) => {
    setSelectedSupplier(supplier);
  };

  const handleBackToTable = () => {
    setSelectedSupplier(null); // Restablece el estado para mostrar la tabla
  };

  return (
    <Box>
      {!selectedSupplier ? (
        <ProviderTable suppliers={suppliers} onSelectSupplier={handleSelectSupplier} />
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          
          <ProviderCard supplier={selectedSupplier} />
          <Button variant="contained" onClick={handleBackToTable} sx={{ mb: 2 }}>
            Volver a la Tabla
          </Button>
        </Container>
      )}
    </Box>
  );
};

export default ProvidersCFO;


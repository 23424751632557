import React from 'react';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { ProgressStepEntity } from '../../../domain/entities/progress_step';

interface StepProgressProps {
  steps: ProgressStepEntity[];
}

const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
  // Separar los pasos completados y no completados
  const completedSteps = steps.filter(step => step.is_completed);
  const notCompletedSteps = steps.filter(step => !step.is_completed);

  // Obtener los últimos 2 pasos completados y los primeros 2 no completados
  const maxCompletedSteps = completedSteps.slice(-2);
  const maxNotCompletedSteps = notCompletedSteps.slice(0, 2);

  // Si no hay suficientes pasos en alguna categoría, llenar con la otra
  let displayedSteps = [
    ...maxCompletedSteps, 
    ...maxNotCompletedSteps
  ];

  // Si hay menos de 4 pasos, rellenar con más completados o no completados
  if (displayedSteps.length < 4) {
    if (maxNotCompletedSteps.length < 2) {
      // Si faltan no completados, rellenar con más completados
      const extraCompletedSteps = completedSteps.slice(-4 + maxNotCompletedSteps.length, -2);
      displayedSteps = [...extraCompletedSteps, ...displayedSteps];
    } else {
      // Si faltan completados, rellenar con más no completados
      const extraNotCompletedSteps = notCompletedSteps.slice(2, 4 - maxCompletedSteps.length);
      displayedSteps = [...displayedSteps, ...extraNotCompletedSteps];
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper alternativeLabel>
        {displayedSteps.map((step: ProgressStepEntity, index) => (
          <Step key={index} completed={step.is_completed}>
            <StepLabel>{step.status.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepProgress;

// import React from 'react';
// import { Box, Step, StepLabel, Stepper } from '@mui/material';
// import { ProgressStepEntity } from '../../domain/entities/progress_step';

// interface StepProgressProps {
//   steps: ProgressStepEntity[];
// }

// const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
//   // Separar los pasos completados y no completados
//   const completedSteps = steps.filter(step => step.is_completed);
//   const notCompletedSteps = steps.filter(step => !step.is_completed);

//   // Obtener los últimos 2 pasos completados y los primeros 2 no completados
//   const displayedSteps = [
//     ...completedSteps.slice(-2), 
//     ...notCompletedSteps.slice(0, 2)
//   ];

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Stepper alternativeLabel>
//         {displayedSteps.map((step: ProgressStepEntity, index) => (
//           <Step key={index} completed={step.is_completed}>
//             <StepLabel>{step.status.name}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// };

// export default StepProgress;

// import React from 'react';
// import { Box, Step, StepLabel, Stepper } from '@mui/material';
// import { ProgressStepEntity } from '../../domain/entities/progress_step';

// interface StepProgressProps {
//   steps: ProgressStepEntity[];
// }

// const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
//   // Obtener los últimos 4 pasos, o menos si hay menos de 4
//   const displayedSteps = steps.slice(-4);

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Stepper alternativeLabel>
//         {displayedSteps.map((step: ProgressStepEntity, index) => (
//           <Step key={index} completed={step.is_completed}>
//             <StepLabel>{step.status.name}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// };

// export default StepProgress;

// import React from 'react';
// import { Box, Step, StepLabel, Stepper } from '@mui/material';
// import { ProgressStepEntity } from '../../domain/entities/progress_step';

// interface StepProgressProps {
//   steps: ProgressStepEntity[];
// }

// const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
//   return (
//     <Box sx={{ width: '100%' }}>
//       <Stepper alternativeLabel>
//         {steps.map((step: ProgressStepEntity, index) => (
//           <Step key={index} completed={step.is_completed}>
//             <StepLabel>{step.status.name}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// };

// export default StepProgress;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentPlanEntity } from '../../../domain/entities/payment_plan/payment_plan_entity';

export interface CompanyPaymentsState {
  companyPayments: PaymentPlanEntity[];
}

const initialState = {
  companyPayments: [],
} as CompanyPaymentsState;

const companyPaymentsSlice = createSlice({
  name: 'company_payments',
  initialState,
  reducers: {
    setPaymentPlans(state, action: PayloadAction<PaymentPlanEntity[]>) {
      state.companyPayments = action.payload;
    },
  },
});

export const { setPaymentPlans } = companyPaymentsSlice.actions;
export default companyPaymentsSlice.reducer;



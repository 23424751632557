import React, {useState, useEffect} from 'react';
import { Grid, TextField, Container,Box, Button, Table,IconButton, TableBody,Dialog, DialogActions,DialogContent,TableCell,DialogTitle ,TableRow} from '@mui/material';
import { ProductEntity } from '../../../../../domain/entities';
import { setCancelAddProduct } from '../../../../redux/slices/admin_container_slice';
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { GetMetricsEvent } from '../../../../events/products/get_metrics_events';
import { CreateMetricEvent} from '../../../../events/admin/create_metric_event';
import { CreateProductContainerEvent } from '../../../../events/admin/create_product_container_event';

import AddBoxIcon from '@mui/icons-material/AddBox';

interface SelectProductCardProps {
    product: ProductEntity;
  }
  
const AddProductForm: React.FC<SelectProductCardProps> = ({ product }) => {
  const { metrics  } = useAppSelector((state) => state.metric);
  const {selectedContainer }= useAppSelector((state)=>state.admin_container);
  const [open, setOpen] = useState(false);
  const [metric, setMetric] = useState(-1);
  const [name,setName] = useState('');
  const [description,setDescription] = useState('');
  const [square_meters,setSquare] = useState('');
  const [metric_value,setMetricValue] = useState(0);

  const [minQuantity, setMinQuantity] = useState(''); 
  const [maxQuantity, setMaxQuantity] = useState(''); 
  const [unitBasePrice, setUnitBasePrice] = useState('');
  const [increasePercent, setIncreasePercent] = useState('');
  const [unitsPerPackage, setUnitsPerPackage] = useState('');
  const [interval, setInterval] = useState('');
  
  
  const dispatch = useAppDispatch();
    
  useEffect(() => {
    if (metrics && metric!=null && Object.values(metrics).length > 0) {
      metrics.map((metrica)=>{
        if(metrica.id == metric){
          setMetricValue(metrica.square_meters);
        }
      })
    }
  }, [metric]); 

  useEffect(() => {
        dispatch(GetMetricsEvent());
  }, []);

  const HandleAddProduct = () => {

    const container_id = selectedContainer?.id?.trim();
    if (!container_id) {
      alert("El ID del contenedor no puede estar vacío.");
      return;
    }
  
    const product_id = product?.id?.trim();
    if (!product_id) {
      alert("El ID del producto no puede estar vacío.");
      return;
    }
    const metric_id = Number(metric);
    console.log(metric);
    if (isNaN(metric_id) || metric_id <= 0) {
      alert("Métrica no válida");
      return;
    }
  

    const minQuantityNumber = parseFloat(minQuantity.replace(',', '.'));
    const maxQuantityNumber = parseFloat(maxQuantity.replace(',', '.'));
    
    if (isNaN(minQuantityNumber) || minQuantityNumber <= 0) {
      alert("La cantidad mínima debe ser un número válido y mayor que 0.");
      return;
    }
  
    if (isNaN(maxQuantityNumber) || maxQuantityNumber <= 0) {
      alert("La cantidad máxima debe ser un número válido y mayor que 0.");
      return;
    }
  
    if (minQuantityNumber >= maxQuantityNumber) {
      alert("La cantidad mínima debe ser menor que la cantidad máxima.");
      return;
    }
  
    const unitBasePriceNumber = parseFloat(unitBasePrice.replace(',', '.'));
    if (isNaN(unitBasePriceNumber) || unitBasePriceNumber <= 0) {
      alert("El precio base por unidad debe ser un número válido y mayor que 0.");
      return;
    }

    const increasePercentNumber = parseFloat(increasePercent.replace(',', '.'));
    if (isNaN(increasePercentNumber) || increasePercentNumber <= 0) {
      alert("El porcentaje de aumento debe ser un número válido y mayor que 0.");
      return;
    }
  
    const unitsPerPackageNumber = parseFloat(unitsPerPackage.replace(',', '.'));
    if (isNaN(unitsPerPackageNumber) || unitsPerPackageNumber <= 0) {
      alert("Las unidades por paquete deben ser un número válido y mayor que 0.");
      return;
    }
  
    const intervalNumber = parseFloat(interval.replace(',', '.'));
    if (isNaN(intervalNumber) || intervalNumber <= 0) {
      alert("El intervalo debe ser un número válido y mayor que 0.");
      return;
    }
    CreateProductContainerEvent(minQuantityNumber,maxQuantityNumber,unitBasePriceNumber,increasePercentNumber,intervalNumber,container_id,product_id,metric_id,unitsPerPackageNumber,dispatch,product);
  };
  
  const HandleCancel = () => {
    dispatch(setCancelAddProduct());
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCrearMetrica = () => {
    if (!name.trim()) {
      alert("El nombre no puede estar vacío.");
      return;
    }
    if (!description.trim()) {
      alert("La descripción no puede estar vacía.");
      return;
    }
    const squareMetersNumber = parseFloat(square_meters.replace(',', '.'));
    if (isNaN(squareMetersNumber) || squareMetersNumber <= 0) {
      alert("Los metros cuadrados deben ser un número positivo.");
      return;
    }
  
    CreateMetricEvent(name, description, squareMetersNumber,dispatch)
      .then(() => {
        alert("Métrica creada con éxito.");
        handleClose();
      })
      .catch((error) => {
        alert(`Error al crear la métrica: ${error.message}`);
      });
    }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh', width: '100%' }}>
         <Grid container spacing={2}>
            <Grid item xs={7} container direction="column" spacing={2}>
                <Grid item style={{ height: "14.2%", display: "flex",         justifyContent: "center", alignItems: "center", }}>
                    <TextField required label="Unidades por paquete" value={unitsPerPackage}  onChange={(e) => setUnitsPerPackage(e.target.value)} style={{ width: "60%" }}  inputProps={{ style: { textAlign: "center" } }} /> 
                </Grid>
                <Grid item style={{ height: "14.2%", display: "flex",         justifyContent: "center", alignItems: "center", }}>
                    <TextField required label="La cantidad mínima de productos a comprar" value={minQuantity}  onChange={(e) => setMinQuantity(e.target.value)} style={{ width: "95%" }}  inputProps={{ style: { textAlign: "center" } }} /> 
                </Grid>
                <Grid item style={{ height: "14.2%", display: "flex",         justifyContent: "center", alignItems: "center", }}>
                    <TextField required label="Cantidad máxima de unidades del producto a añadir" value={maxQuantity}  onChange={(e) => setMaxQuantity(e.target.value)} style={{ width: "95%" }}  inputProps={{ style: { textAlign: "center" } }} /> 
                </Grid>
                <Grid
                    item
                    style={{ height: "14.2%",  display: "flex",   justifyContent: "center",  alignItems: "center",}}>
                    <TextField 
                        label="Precio base" 
                        required
                        style={{ width: "45%" }}
                        inputProps={{ style: { textAlign: "center" } }} 
                        value={unitBasePrice}  
                        onChange={(e) => setUnitBasePrice(e.target.value)}
                    /> 
                    <TextField 
                        label="Porcentaje de disminución" 
                        required
                        style={{ width: "45%", marginLeft: "5%" }}
                        inputProps={{ style: { textAlign: "center" } }} 
                        value={increasePercent}  
                        onChange={(e) => setIncreasePercent(e.target.value)}
                    /> 
                </Grid>
                <Grid item style={{ height: "14.2%", display: "flex",         justifyContent: "center", alignItems: "center", }}>
                    <TextField required label="Intervalo" style={{ width: "80%" }}  value={interval}  onChange={(e) => setInterval(e.target.value)} inputProps={{ style: { textAlign: "center" } }} /> 
                </Grid>
                <Grid
                    item
                    style={{
                        height: '14.2%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                    <TextField
                        margin="normal"
                        required
                        style={{ width: '70%' }}
                        label="Métrica"
                        select
                        value={metric}
                        onChange={(e) => setMetric(Number(e.target.value))}
                        SelectProps={{
                            native: true,
                        }}
                        >
                            <option >
                            
                            </option>
                        {Object.values(metrics).map((metric) => (
                            <option key={metric.id} value={metric.id}>
                            {metric.name}
                            </option>
                        ))}
                    </TextField>

                    <IconButton color="primary"  onClick={handleOpen}  style={{ marginLeft: '10px' }}><AddBoxIcon />
                    </IconButton>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Añadir Métrica</DialogTitle>
                        <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Nombre"
                            required
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Descripción"
                            required
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Valor"
                            required
                            fullWidth
                            value={square_meters}
                            inputProps={{ style: { textAlign: "center" } }}
                            onChange={(e) => setSquare(e.target.value)}
                        />
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleCrearMetrica} color="primary">
                            Aceptar
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>

                <Grid
                    item
                    style={{  height: "14.2%", display: "flex", justifyContent: "center", alignItems: "center",}} >
                     <Button variant="contained"   color='error' style={{ width: "30%" }} onClick={HandleCancel}>
                       Cancelar
                    </Button>
                    <Button variant="contained"   color="primary"  style={{ width: "40%",marginLeft:'5%' }} onClick={HandleAddProduct}>
                        Añadir Producto
                    </Button>
                </Grid>

            <Grid item style={{ height: "14.2%", display: "flex",         justifyContent: "center", alignItems: "center", }}>
           
            </Grid>
        </Grid>

        <Grid item xs={5} container direction="column" spacing={2}>
            <Grid
                item
                style={{
                    height: "40%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                >
                <img
                    src={product.imageUrl} 
                    alt="Imagen del producto"
                    style={{
                    height: "200px",
                    objectFit: "contain", 
                    }}
                />
            </Grid>

            <Grid
                item
                style={{
                    height: "10%",
                    display: "flex",          
                    justifyContent: "center",  
                    alignItems: "center", 
                    fontWeight: 'bold',
                    fontSize: '20px', 
                    textAlign: 'center',
                }}
                >
          {product.name}
        </Grid>
            <Grid item style={{ height: "40%" }}>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell sx={{ padding: '8px' }}><strong>Ancho</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">20</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '8px' }}><strong>Largo</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">25</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '8px' }} ><strong>Alto</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">52</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '8px' }}><strong>Peso</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">70</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '8px' }}><strong>Precio (USD)</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">{product.price}</TableCell>

                </TableRow>
                {metric_value != 0 && (<TableRow>
                    <TableCell sx={{ padding: '8px' }}><strong>Metros cúbicos por paquete</strong></TableCell>
                    <TableCell sx={{ padding: '8px' }} align="center">{metric_value}</TableCell>
                </TableRow>)}
                </TableBody>
            </Table>
            </Grid>
        </Grid>
        </Grid>
    </Box>
    </Container>
  );
};

export default AddProductForm;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionEntity } from '../../../domain/entities/transaction/transaction_entity';

export interface TransactionState {
  transactions: TransactionEntity[];
}

const initialState = {
  transactions: [],
} as TransactionState;

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactions(state, action: PayloadAction<TransactionEntity[]>) {
      state.transactions = action.payload;
    },
    updateTransaction(state, action: PayloadAction<TransactionEntity>) {
      const index = state.transactions.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.transactions[index] = action.payload;
      }
      else {
        state.transactions.push(action.payload);
      }
    },
  },
});

export const { setTransactions, updateTransaction } = transactionSlice.actions;
export default transactionSlice.reducer;
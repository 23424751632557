import apiRepository from "../../../data/repository";
import LoginUserUsecase, { LoginParams } from "../../../domain/usecases/login_user_usecase";
import { setStatusUser, StatusUserEnum } from "../../redux/slices/user_slice";


export const LoginEvent = (params: LoginParams) => async (dispatch) => {
    const usecase = new LoginUserUsecase(apiRepository);
    dispatch(setStatusUser(StatusUserEnum.Loading));

    const result = await usecase.execute(params,);

    result.fold(
        () => {
            dispatch(setStatusUser(StatusUserEnum.Error));
        },
        () => {
            dispatch(setStatusUser(StatusUserEnum.Success));
        }
    );
}
import React, { Fragment,useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Button, Menu, MenuItem} from '@mui/material';
import { useAppDispatch } from '../../../../hooks/hooks';
import { CreateContainerEvent } from '../../../../events/admin/create_container_event';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const hoy = new Date();

// Crear fechas a 15 y 30 días desde hoy
const fecha15Dias = new Date(hoy);
fecha15Dias.setDate(fecha15Dias.getDate() + 15);

const fecha30Dias = new Date(hoy);
fecha30Dias.setDate(fecha30Dias.getDate() + 30);

// Establecer la hora a las 12:00:00 UTC
fecha15Dias.setUTCHours(12, 0, 0, 0);
fecha30Dias.setUTCHours(12, 0, 0, 0);

// Obtener las cadenas en el formato deseado
const listaFechas: string[] = [
  fecha15Dias.toISOString(), // Formato 'YYYY-MM-DDT12:00:00Z'
  fecha30Dias.toISOString()
];



const containerTypes = [
  // { id: 'A1', name: 'Tipo A1', capacity:5000,fixed_cost:3000,tax:2500, 3,[0.2,0.3,05]},
  // { id: 'A2', name: 'Tipo A2', capacity:5000,fixed_cost:5000,tax:2500, 3},

  { id: 'B1', name: 'Tipo B1', capacity:5000,fixed_cost:5000,tax:3000,payments:2,percentages:[0.3,0.7],due_dates:listaFechas,deadlines:listaFechas},
  { id: 'B2', name: 'Tipo B2', capacity:3000,fixed_cost:3000,tax:3000,payments:2,percentages:[0.2,0.8],due_dates:listaFechas,deadlines:listaFechas},
];

const ContainersNavBar: React.FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createAnchorEl, setCreateAnchorEl] = useState<null | HTMLElement>(null);
  const isCreateMenuOpen = Boolean(createAnchorEl); 

  const handleCreateMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setCreateAnchorEl(null); 
  };

  const handleTypeSelect = async (index: number) => {
    console.log(containerTypes[index]);
    const data = containerTypes[index];
    const container = await CreateContainerEvent(data.capacity,data.fixed_cost,data.tax,data.payments,data.percentages,data.due_dates,data.deadlines,dispatch);
    navigate(`/admin/container-clients/${container.id}`);
    handleMenuClose(); 
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ boxShadow: 'none' }}>
          <Toolbar variant="dense">
            <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
              {title || 'Contenedores'}
            </Typography>
            <Button variant="contained" onClick={handleCreateMenuOpen} startIcon={<AddIcon />}
                sx={{
                    backgroundColor: '#4caf50',
                    color: '#fff',
                    '&:hover': {
                    backgroundColor: '#388e3c',
                    },
                }}>
                    Crear Container
            </Button>
          </Toolbar>
        </AppBar>
        
        <Menu anchorEl={createAnchorEl} open={isCreateMenuOpen} onClose={handleMenuClose}>
          {containerTypes.map((type, index) => (
            <MenuItem key={type.id} onClick={() => handleTypeSelect(index)}>
              <Typography>{type.name}</Typography>
            </MenuItem>
          ))}
        </Menu>

      </Box>
    </Fragment>
  );
};

export default ContainersNavBar;

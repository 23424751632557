import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { InvestmentEntity } from "../entities";

export default class GetCompanyInvestmentsUsecase implements Usecase<InvestmentEntity> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<InvestmentEntity>> {
        const data = await this.repository.getCompanyInvestments();
        return data;
    }
}
import { Either, Usecase } from "../../core/index";
import { ApiRepositoryImpl } from "../../data/repository/api";
import { SupplierEntity } from '../entities/supplier/supplier_entity';

export default class GetSuppliersUsecase implements Usecase<SupplierEntity[]> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<SupplierEntity[]>> {
        const data = await this.repository.getSuppliers();
        return data;
    }
}

import React, { useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ClientCardProps {
    client: {
        id: string;
        rut: string;
        name: string;
        address: string;
        phone: string;
        email: string;
    };
}

const ClientLayout: React.FC<ClientCardProps> = ({ client }) => {
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    const handleMouseEnter = () => setHover(true);
    const handleMouseLeave = () => setHover(false);

    const handleNavigate = () => {
      console.log(client.id);
      navigate(`/admin/clients/${client.id}`);
    };

    return (
        <Grid container 
              sx={{ 
                height: '100%', 
                padding: '10px', 
                border: '1px solid #ccc',  
                transition: 'background-color 0.3s',
                backgroundColor: hover ? '#f0f8ff' : '#fff', 
                alignItems: 'center', 
                textAlign: 'center',
                cursor: 'pointer'
              }} 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
              onClick={handleNavigate}
        >
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{client.rut}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{client.name}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{client.address}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{client.phone}</Typography>
            </Grid>
            <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
            <Grid item xs={2} sx={{ padding: '10px' }}>
                <Typography>{client.email}</Typography>
            </Grid>
        </Grid>
    );
};

export default ClientLayout;

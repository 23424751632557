import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TransactionDetails from "./TransactionDetails";

interface ContainerDetailsProps {
    container: {
        id: string;
        proformas: Array<{ id: string; due_date: string; deadline: string; payments: Array<any> }>;
    };
}

const ContainerDetails: React.FC<ContainerDetailsProps> = ({ container }) => (
    <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell>Due Date</TableCell>
                <TableCell>Expiration Date</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {container.proformas.map((proforma) => (
                <TableRow key={proforma.id}>
                    <TableCell>{proforma.due_date}</TableCell>
                    <TableCell>{proforma.deadline}</TableCell>
                    <TableCell>
                        {proforma.payments.map((payment) => (
                            <Accordion key={payment.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Payment ID: {payment.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TransactionDetails transactions={payment.transactions} />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default ContainerDetails;

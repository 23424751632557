import React,{useState,useEffect} from 'react';
import { Box, Grid ,Typography, Table, TableBody, TableRow, TableCell} from '@mui/material';
import ProductDetails from './product_card';
import ContainerActiveLayout from './product_active_container_card';
import {GetProductFullDetailEvent} from '../../../events/products/get_product_ful_detail_event';
import { useAppDispatch } from '../../../hooks/hooks';


const ProductDetailPage = ({ product_id }) => {
  const [expandedContainerId, setExpandedContainerId] = useState(null);

  const [product, setProduct] = useState({name:"",imageUrl:''});
  const [activeContainers, setActiveContainers] = useState([]);
  const [pastImports,setPastImports] = useState([]);
  const dispatch = useAppDispatch();

  const handleToggleExpand = (id) => {
    setExpandedContainerId(prevId => (prevId === id ? null : id)); 
  };

  useEffect(() => {
    const fetchProductDetail = async () => {
      const result = await dispatch(GetProductFullDetailEvent(product_id));
      setProduct(result);
      console.log(result);
      const activeContainers = result.current_imports.map((importData, index) => ({
        id: index + 1,
        content: <ContainerActiveLayout 
                    importData={importData} 
                    isExpanded={expandedContainerId === index + 1} 
                    index={index + 1}
                    onToggleExpand={() => handleToggleExpand(index + 1)} 
                    name={product.name}
                    imageURL= {product.imageUrl}

                 />,
      })) || [];
      setActiveContainers(activeContainers);
      setPastImports(result.past_imports.map((importData, index) => ({
        id: index + 1,
        max_quantity: importData.total_quantity,
        created_at: importData.initial_date,
        finish_date: importData.finish_date
        
      })) || []);
    };
    if (product_id) {
      fetchProductDetail();
    }
  }, [dispatch, product_id, expandedContainerId]); 

  return (
    <Box sx={{   display: 'flex', justifyContent: 'center', alignItems: 'flex-start', backgroundColor: '#f0f0f0', padding: 4,  minHeight: '100vh' }}>
      <Grid container sx={{ width: '100%' }} spacing={3}>
        <Grid item xs={4}>
          <ProductDetails product={product} />
        </Grid>
        <Grid item xs={8}>
            <Typography variant="h5" sx={{ marginBottom: '8px', textAlign: 'center' }}>
                <strong>Importaciones Actuales</strong>
            </Typography>

              {activeContainers.map((container) => (
              <React.Fragment key={container.id}>
                {container.content}
              </React.Fragment>
              ))}
            {pastImports.length>0 && (<Typography variant="h5" sx={{ marginTop: '32px', marginBottom: '8px', textAlign: 'center' }}>
              <strong>Importaciones Anteriores</strong>
            </Typography>)
          }


            <Table>
                <TableBody>
                  {pastImports.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px' }}><strong>Unidades Compradas:</strong></TableCell>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px', textAlign: 'center' }}>
                        {data.max_quantity.toLocaleString('es-ES')}
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px' }}><strong>Fecha de Inicio:</strong></TableCell>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px', textAlign: 'center' }}>
                        {new Date(data.created_at).toLocaleDateString('es-ES')}
                      </TableCell>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px' }}><strong>Fecha de Término:</strong></TableCell>
                      <TableCell sx={{ borderBottom: '1px solid #000', padding: '2px 4px', textAlign: 'center' }}>
                        {new Date(data.finish_date).toLocaleDateString('es-ES')}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
            </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDetailPage;

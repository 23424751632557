import React, { useEffect } from "react";
import { Container, Grid, Box, Typography, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetCompaniesEvent } from "../../../events/company/get_companies_event";
import ClientLayout from "./Client_layout";
import ClientsNavBar from "./ClientsNavBar";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ClientsAdmin: React.FC = () => {
    const { companies, order } = useAppSelector((state) => state.company); // Añadimos el orden del estado
    const dispatch = useAppDispatch();

    const [sortConfig, setSortConfig] = React.useState<{ column: string; direction: 'asc' | 'desc' }>({ column: 'rut', direction: 'asc' });

    useEffect(() => {
        dispatch(GetCompaniesEvent());
    }, [dispatch]);

    // Ordenar las compañías por RUT según el estado de `order`
    const sortedCompanies = [...companies].sort((a, b) => {
        const { column, direction } = sortConfig;
        let aValue, bValue;

        if (column === 'rut') {
            aValue = a.rut;
            bValue = b.rut;
        } else if (column === 'name') {
            aValue = a.name;
            bValue = b.name;
        }

        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        return 0;
    });

    const toggleSortDirection = (column: string) => {
        setSortConfig((prevConfig) => ({
            column,
            direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    return (
        <Box sx={{ width: '100%' }}> 
          <ClientsNavBar/> 
          <Container sx={{ width: '100%', padding: 0 }}> 
              <Grid container sx={{ marginTop: '10px'}} spacing={1}>
                  {/* Table Header */}
                  <Grid item container xs={12} sx={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center', justifyContent: 'center' }}>
                      <Grid item xs={2} sx={{ backgroundColor: '#0A66A5', padding: '10px',  color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>RUT</Typography>
                          <IconButton onClick={() => toggleSortDirection('rut')} sx={{ color: 'white' }}>
                            {sortConfig.column === 'rut' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 20 }} /> : <ArrowDownwardIcon sx={{ fontSize: 20 }} />}
                          </IconButton>
                      </Grid>
                      <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                      <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>Nombre</Typography>
                          <IconButton onClick={() => toggleSortDirection('name')}>
                            {sortConfig.column === 'name' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 20 }}/> : <ArrowDownwardIcon sx={{ fontSize: 20 }}/>}
                          </IconButton>
                      </Grid>
                      <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                      <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>Dirección</Typography>
                      </Grid>
                      <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                      <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>Teléfono</Typography>
                      </Grid>
                      <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                      <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>Email</Typography>
                      </Grid>
                  </Grid>
                  {/* Table Body */}
                  <Grid item container xs={12} spacing={0} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  {sortedCompanies.map((client) => (
                      <Grid item xs={12} key={client.id}>
                          <ClientLayout client={client} />
                      </Grid>
                  ))}
                  </Grid>
              </Grid>
          </Container>
        </Box>
      );
    };

export default ClientsAdmin;

import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { SupplierEntity } from "../../../../domain/entities";

interface ProviderInfoProps {
  supplier: SupplierEntity;
}

// Función para verificar si el campo es válido y no está vacío
const displayValue = (value: string | number | undefined) => {
  return value !== undefined && value !== null && value.toString().trim() !== ""
    ? value
    : "No Aplica";
};

const ProviderInfo: React.FC<ProviderInfoProps> = ({ supplier }) => (
  <Table>
   <TableBody sx={{ maxHeight: 300, overflowY: "auto"}}>
   
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Nombre:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.name)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Nombre Contacto:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.contactName)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Correo:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.email)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Celular:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.phone)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          País:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.country)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Ciudad:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.city)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Dirección:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.address)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Moneda de Cuenta:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.accountCurrency)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Nombre del Banco:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.bankName)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Tipo de Cuenta:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.accountType)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Número de Cuenta:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.accountNumber)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Código SWIFT:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.swiftCode)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Tipo de Proveedor:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.supplierType)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          RUT:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {displayValue(supplier.rut)}
        </TableCell>
      </TableRow>
      
    </TableBody>
  </Table>
);

export default ProviderInfo;

import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetProformasEvent } from "../../events/proforma/get_proformas_event";
import { GetCompanyInvestmentsEvent } from "../../events/company/get_company_investments_event";
import ProformaCarousel from "../../components/user_dashboard/import_carousel_dashboard";
import PendingPayments from "../../components/user_dashboard/pending_payments";
import MultiTransaction from "../../components/user_dashboard/jumbo_transaction";
import ContainersTable from "../../components/user_dashboard/containers_table";
import ProformasTable from "../../components/user_dashboard/proformas_table";

const CustomerDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector((state) => state.proformas);
  const { companyInvestments } = useAppSelector(state => state.company_investments);

  useEffect(() => {
    dispatch(GetProformasEvent());
    dispatch(GetCompanyInvestmentsEvent());
  }, [dispatch]);

  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.current_step !== "Entregado"
  );
  const formatAmount = (amount) => {
    // Elimina puntos, convierte a número y luego aplica el formato con Intl.NumberFormat
    const numericAmount = Number(amount.toString().replace(/\./g, ""));
    return isNaN(numericAmount)
      ? amount
      : new Intl.NumberFormat("es-CL").format(numericAmount);
  };
  console.log("COMPANY INVESTMENT: ", companyInvestments)
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh", // Changed from height to minHeight
        position: "relative",
        paddingTop: "10px",
        paddingBottom: "60px", // Add padding to the bottom for footer spacing
      }}
    >
      {/* Título */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
          marginTop: "30px",
          color: "#0A66A5",
        }}
      >
        Importaciones Pendientes
      </Typography>
  
      {/* Componente de Carrusel */}
      <ProformaCarousel proformas={proformasFiltradas} />
  
      {/* Contenedor para los componentes MultiTransaction y PendingPayments */}
      <Box sx={{ width: '100%', marginTop: '30px', padding: '0 20px' }}>
        <Grid container spacing={2}>
          {/* Componente MultiTransaction a la izquierda */}
          <Grid item xs={12} md={6}>
            <MultiTransaction proformas={proformasFiltradas} />
          </Grid>
  
          {/* Componente PendingPayments a la derecha */}
          <Grid item xs={12} md={6}>
            <PendingPayments proformas={proformasFiltradas} />
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h5" sx={{ fontWeight: "600", marginBottom: "30px", marginTop: "30px", color: "#0A66A5" }}>
            Detalles de inversiones
        </Typography>
  
        <Box sx={{ width: '100%', marginTop: '30px', padding: '0 20px' }}>
  
        {companyInvestments.map((investment, index) => (
            <Box key={index} sx={{ width: '100%', marginBottom: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ContainersTable containers={investment.containers} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProformasTable proformas={investment.proformas} />
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" sx={{
                fontWeight: "600", 
                marginBottom: "30px", 
                marginTop: "30px", 
                color: "#0A66A5", 
                marginLeft: "20px"
            }}>
                Monto Total Invertido: ${formatAmount(investment.totalAmount)}
            </Typography>
            </Box>
        ))}
      </Box>
    </Box>
  );
  
};

export default CustomerDashboard;

import React, { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetProductsEvent } from "../../events/products/get_products_event";
import ProductCard from "../../components/products/principal_page/product_card";
import ProductFilter from "../../components/products/principal_page/product_filter";
import { StatusProductEnum } from "../../redux/slices/product_slice";
import { ProductEntity } from "../../../domain/entities";


const ProductsPage: React.FC = () => {
    const { products, status, filter } = useAppSelector((state) => state.product);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (status === StatusProductEnum.Initial) {
            dispatch(GetProductsEvent());
        }
    }, []);

    return (
        <Container>
            <Grid
                container
                sx={{ padding: '20px' }}
                spacing={3}
            >
                <Grid item xs={3}>
                    <ProductFilter />
                </Grid>
                <Grid item container xs={9} direction={'column'}>
                    <Typography
                        variant='h4'
                        sx={{
                            fontWeight: '600',
                            marginBottom: '30px',
                            color: '#0A66A5',
                        }}
                    >
                        Productos de importación
                    </Typography>
                    <Grid container spacing={4}>
                        {
                            products.map((product: ProductEntity) => {
                                if (product.price >= filter.range[0] &&
                                    product.price <= filter.range[1] &&
                                    (filter.categories.length === 0 || filter.categories.includes(product.category))
                                )
                                    return <Grid item key={product.id}>
                                        <ProductCard product={product} />
                                    </Grid>
                                else return null;
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductsPage;
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ContainerFullDetailEntity,ContainerMovementEntity} from '../../../domain/entities/container/index'
import { ProductEntity, ContainerProductEntity } from '../../../domain/entities';

export enum AdminContainersModeEnum {
    View = 'VIEW',
    Create = 'CREATE',
    ViewContainer = 'VIEWCONTAINER',
    Edit = 'EDIT',
    AddProduct = "ADDPRODUCT",
    SelectProduct = 'SELECTPRODUCT',
    UpdateEstate = 'UPDATESTATE',
    EstimateDate ='ESTIMATEDATE',
    NexState = 'NEXTSTATE'
  }
  
export interface StatusContainerInterface{
    status: string,
    description: string,
    name: string,
    order: number,
}

export enum StatusContainerEnum {
  INIT = "INIT",
  OC_CONTANDEM = "OCCONTANDEM",
  OC_PROVEEDOR = "OCPROVEEDOR",
  PAGO = "PAGO",
  FABRICANDO = "FABRICANDO",
  FABRICADO = "FABRICADO",
  PUERTO_CHINO = "PCHINO",
  TRANSITO_MARITIMO = "TMARITIMO",
  PUERTO_DESTINO = "PDESTINO",
  INTERNACION = "INTERNACION",
  TRANSITO_BODEGA = "TBODEGA",
  ENTREGADO = "ENTREGADO",
  PETICION_DISENO = "PETICIONDISENO",
  DISENO_CHINA = "DISENOCHINA",
  PROTOTIPO = "PROTOTIPO",
  ACEPTACION_DISENO = "ACEPTACIONDISENO"
}
// ENT = "ENT",
// ETB = "ETB",
// INT = "INT",
// EPD = "EPD",
// ETM = "ETM",
// EPC = "EPC",  
// FABO = "FABO",
// FABD = "FABD",
// PAG = "PAG",
// OCP = "OCP",
// OCC = "OCC",
// INI = "INI"



// Entregado // ENT
// En tránsito bodega ETB
// Internación // INT
// En puerto Destino // EPD
// En tránsito marítimo // ETM
// En puerto chino // EPC
// Fabricado // FABO
// Fabricando // FABD
// Pago // PAG
// Orden de compra proveedor // OCP
// Orden de compra Contandem // OCC
// Inicializado // INI


export const Status: StatusContainerInterface[] = [
  { status: StatusContainerEnum.INIT, description: 'Estado inicial', name: 'Inicializado', order: 1 },
  { status: StatusContainerEnum.OC_CONTANDEM, description: 'Orden de Compra Contandem', name: 'OC Contandem', order: 2 },
  { status: StatusContainerEnum.OC_PROVEEDOR, description: 'Orden de Compra Proveedor', name: 'OC Proveedor', order: 3 },
  { status: StatusContainerEnum.PAGO, description: 'Pago', name: 'Pago', order: 4 },
  { status: StatusContainerEnum.FABRICANDO, description: 'Fabricando', name: 'Fabricando', order: 5 },
  { status: StatusContainerEnum.FABRICADO, description: 'Fabricado', name: 'Fabricado', order: 6 },
  { status: StatusContainerEnum.PUERTO_CHINO, description: 'En puerto chino', name: 'Puerto chino', order: 7 },
  { status: StatusContainerEnum.TRANSITO_MARITIMO, description: 'En tránsito marítimo', name: 'Tránsito marítimo', order: 8 },
  { status: StatusContainerEnum.PUERTO_DESTINO, description: 'En Puerto Destino', name: 'Puerto Destino', order: 9 },
  { status: StatusContainerEnum.INTERNACION, description: 'En internación', name: 'Internación', order: 10 },
  { status: StatusContainerEnum.TRANSITO_BODEGA, description: 'Producto en tránsito a bodega', name: 'Tránsito Bodega', order: 11 },
  { status: StatusContainerEnum.ENTREGADO, description: 'Producto entregado', name: 'Entregado', order: 12 },
];
  //{ status: StatusContainerEnum.PETICION_DISENO, description: 'Petición de Diseño a Cliente', name: 'Petición Diseño Cliente', order: 13 },
  //{ status: StatusContainerEnum.DISENO_CHINA, description: 'Envío diseño a China', name: 'Diseño China', order: 14 },
  //{ status: StatusContainerEnum.PROTOTIPO, description: 'Prototipo de diseño', name: 'Prototipo', order: 15 },
  //{ status: StatusContainerEnum.ACEPTACION_DISENO, description: 'Diseño Aceptado', name: 'Aceptación Diseño', order: 16 }

// { status: StatusContainerEnum.INI, description: 'Estado inicial', name: 'Inicializado',order:1},
// { status: StatusContainerEnum.OCC, description: 'Orden de compra Contandem', name: 'OC Contandem',order:2},
// { status: StatusContainerEnum.OCP, description: 'Orden de compra proveedor', name: 'OC Proveedor',order:3},
// { status: StatusContainerEnum.PAG, description: 'Pago', name: 'Pago',order:4},
// { status: StatusContainerEnum.FABD, description: 'Fabricando', name: 'Fabricando',order:5},
// { status: StatusContainerEnum.FABO, description: 'Fabricado', name: 'Fabricado',order:6 },
// { status: StatusContainerEnum.EPC, description: 'En Puerto Chino', name: 'Puerto Chino',order:7},
// { status: StatusContainerEnum.ETM, description: 'En tránsito maritimo', name: 'Tránsito Maritimo',order:8},
// { status: StatusContainerEnum.EPD, description: 'En Puerto Destino', name: 'Puerto Destino',order:9 },
// { status: StatusContainerEnum.INT, description: 'En Internación', name: 'Internación',order:10 },
// { status: StatusContainerEnum.ETB, description: 'Producto en Tránsito a Bodega', name: 'Tránsito Bodega',order:11 },
// { status: StatusContainerEnum.ENT, description: 'Producto Entregado', name: 'Entregado',order:12 }




export interface AdminProductsState {
    mode: AdminContainersModeEnum;
    selectedContainer: ContainerFullDetailEntity | null;
    statusContainer: StatusContainerInterface | null;
    selectedProducto: ProductEntity | null;
    error: string | null;
    filter: ContainerFilterState;
    movement:ContainerMovementEntity|null;
}

export interface ContainerFilterState {
  statuses: number[]; 
}

const initialFilterState: ContainerFilterState = {
  statuses: [], 
};

const initialState = {
    selectedContainer: null,
    statusContainer: null,
    error: null,
    mode: AdminContainersModeEnum.View,
    filter: initialFilterState,
    movement: null,
} as AdminProductsState;


const admin_containerSlice = createSlice({
  name: 'admin_containers',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<AdminContainersModeEnum>) {
        state.mode = action.payload;
      },
    setSelectedContainer(state, action: PayloadAction<ContainerFullDetailEntity | null>) {
        state.selectedContainer = action.payload;
        if (action.payload && state.selectedContainer) {
          state.statusContainer = Status[state.selectedContainer.status-1];
          state.mode = AdminContainersModeEnum.ViewContainer;
        }
    },
    setUpdateDateMovement(state,action: PayloadAction<string>){
      if(state.movement){
        state.movement.estimatedDate = action.payload;
      }
    },
    setEditContainer(state){
       state.mode = AdminContainersModeEnum.Edit
    },
    setSelectedProduct(state,action: PayloadAction<ProductEntity|null>){
      state.selectedProducto = action.payload;
      if (action.payload && state.selectedContainer) {
        state.mode = AdminContainersModeEnum.AddProduct;
      }
    },
    setCancelAddProduct(state){
      state.selectedProducto = null;
      state.mode = AdminContainersModeEnum.ViewContainer;
    },
    addProductContainer(state, action:PayloadAction<ContainerProductEntity|null>){
      if (action.payload && state.selectedContainer){
        state.selectedContainer.containerProducts.push(action.payload)
      }
    },
    setContainerMovement(state,action:PayloadAction<ContainerMovementEntity>){
      state.movement = action.payload;
    },
    setAddMovement(state,action:PayloadAction<ContainerMovementEntity>){
      if (action.payload){ 
        state.selectedContainer.containerMovements.push(action.payload);
        state.movement = action.payload;
      }
    },
    reset(state) {
        state.selectedContainer = null;
        state.error = null;
        state.statusContainer = null;
        state.mode = AdminContainersModeEnum.View;
        state.selectedProducto = null;
        state.movement= null
    },
    setContainerFilter(state, action: PayloadAction<ContainerFilterState>) {
      state.filter = action.payload;
    },
  },
});

export const { setMode, setSelectedContainer,setUpdateDateMovement,reset,setEditContainer,setSelectedProduct,setCancelAddProduct,setAddMovement,addProductContainer,setContainerMovement,setContainerFilter} = admin_containerSlice.actions;
export default admin_containerSlice.reducer;

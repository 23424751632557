import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";

interface Proforma {
  duration: number | string;
}

interface ProformasTableProps {
  proformas: { [key: string]: Proforma };
}

const ProformasTable: React.FC<ProformasTableProps> = ({ proformas }) => {
    console.log("PROFORMAS FELIPE: ", proformas)
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography variant="h5" sx={{ color: "#0A66A5", marginBottom: 2, fontWeight: "bold" }}>
        Proformas
      </Typography>
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "400px",
        }}
      >
        <Grid container spacing={2}>

          {Object.entries(proformas).map(
            ([proformaId, { duration }], index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    padding: "15px",
                    borderRadius: "7px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 600 }}>
                    Proforma ID: {proformaId}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Duración:{" "}
                    {typeof duration === "number"
                      ? `${duration} días`
                      : duration}
                  </Typography>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProformasTable;

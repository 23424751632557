import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProviderInfo from "./provider_info";
import ContainerList from "./container_list";
import ContainerProductsModal from "./container_products_modal";
import PaymentModal from "./payment_modal";
import {
  SupplierContainerEntity,
  SupplierEntity,
} from "../../../../domain/entities";
import { CreateExternalPaymentEvent } from "../../../events/cfo/create_external_payment_event";
import { updateSupplierDebt } from "../../../redux/slices/supplier_slice";
import { useAppDispatch } from "../../../hooks/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

interface ProviderCardProps {
  supplier: SupplierEntity;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ supplier }) => {
  const [selectedContainer, setSelectedContainer] =
    useState<SupplierContainerEntity>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [totalDebt, setTotalDebt] = useState<number>(0); // Estado para la deuda acumulada
  const dispatch = useAppDispatch();

  // Calcular la cantidad total de contenedores y productos
  const totalContainers = supplier.containers?.length || 0;
  const totalProducts =
    supplier.containers?.reduce(
      (sum, container) => sum + (container.containerProducts?.length || 0),
      0
    ) || 0;

  useEffect(() => {
    // Inicializar deuda total de todos los contenedores
    const initialDebt =
      supplier.containers?.reduce(
        (sum, container) => sum + (container.debt || 0),
        0
      ) || 0;
    setTotalDebt(initialDebt);
  }, [supplier.containers]);

  const handleOpenDialog = (container: SupplierContainerEntity) => {
    setSelectedContainer(container);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedContainer(null);
  };

  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
    setPaymentAmount("");
  };
  
  const selectedContainerUpdated = useSelector((state: RootState) =>
    state.suppliers.suppliers
      .find((sup) => sup.id === supplier.id)
      ?.containers.find((container) => container.id === selectedContainer?.id)
  );

  const handlePayment = async () => {
    if (!paymentAmount || !selectedContainer || !supplier) {
      setError("Por favor ingrese un monto válido y seleccione un contenedor.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const result = await CreateExternalPaymentEvent(
        supplier.id,
        selectedContainer.id,
        parseFloat(paymentAmount),
        `Pago de ${paymentAmount} para el contenedor ${selectedContainer.id}`
      );

      dispatch(
        updateSupplierDebt({
          supplierId: supplier.id,
          containerId: selectedContainer.id,
          amount: parseFloat(paymentAmount),
        })
      );

      // Actualizar la deuda total
      setTotalDebt((prevDebt) => prevDebt - parseFloat(paymentAmount));

      setSuccessMessage(`Pago realizado con éxito: ${result.amount}`);

      setOpenDialog(false);
      setSelectedContainer(null);
      setPaymentAmount("");
      handleClosePaymentDialog();
    } catch (err) {
      setError(err.message || "Ocurrió un error durante el proceso de pago.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "4px",
        marginBottom: "25px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={1} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left" gutterBottom>
            {supplier.name || "Nombre Proveedor"}
          </Typography>
        </Grid>

        <Grid container item xs={6} spacing={1}>
          <ProviderInfo supplier={supplier} />
        </Grid>

        <Grid item xs={6}>
          <ContainerList
            containers={supplier.containers}
            onSelectContainer={handleOpenDialog}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" align="center" sx={{ marginTop: "15px" }}>
            Deuda Acumulada: ${totalDebt.toFixed(0)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ marginTop: "10px" }}>
            Total de Contenedores: {totalContainers}
          </Typography>
          <Typography variant="body1" align="center">
            Total de Productos: {totalProducts}
          </Typography>
        </Grid>

        <ContainerProductsModal
  container={selectedContainerUpdated}
  open={openDialog}
  onClose={handleCloseDialog}
  onOpenPaymentDialog={handleOpenPaymentDialog}
/>

        <PaymentModal
          container={selectedContainer}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          open={openPaymentDialog}
          onClose={handleClosePaymentDialog}
          onConfirmPayment={handlePayment}
        />

        {error && (
          <Grid item xs={12}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
        {successMessage && (
          <Grid item xs={12}>
            <Typography color="primary" align="center">
              {successMessage}
            </Typography>
          </Grid>
        )}
        {loading && (
          <Grid item xs={12}>
            <Typography align="center">Procesando pago...</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProviderCard;

import apiRepository from "../../../data/repository";
import CreateProductContainerUseCase from "../../../domain/usecases/create_product_container_usecase";
import { ContainerProductEntity } from '../../../domain/entities';
import {addProductContainer} from '../../redux/slices/admin_container_slice';
import { AdminContainersModeEnum,setMode } from "../../redux/slices/admin_container_slice";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const CreateProductContainerEvent = async (
    min_quantity: number,
    max_quantity: number,
    unit_base_price: number,
    increased_percent: number,
    interval: number,
    container: string,
    product: string,
    metric_unit: number,
    units_per_package: number,
    dispatch,
    data_product
): Promise<ContainerProductEntity> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new CreateProductContainerUseCase(apiRepository);

    const result = await usecase.execute({
        min_quantity: min_quantity,
        max_quantity: max_quantity,
        unit_base_price: unit_base_price,
        increased_percent: increased_percent,
        interval: interval,
        container: container,
        product: product,
        metric_unit: metric_unit,
        units_per_package: units_per_package
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const ContainerProductEntity = result.right;
    if (!ContainerProductEntity) {
        throw new Error("No se pudo crear el Product Container");
    }
    try {
        ContainerProductEntity.product=data_product;
        await dispatch(addProductContainer(ContainerProductEntity));
        await dispatch(setStatus(StatusLayoutEnum.Success));
        dispatch(setMode(AdminContainersModeEnum.ViewContainer));
      } catch (error) {
        dispatch(setStatus(StatusLayoutEnum.Error));
      }
    return ContainerProductEntity;
}
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ContainerFullDetailEntity } from '../../../domain/entities/container/index'
import { StatusContainerEnum } from './admin_container_slice';

export interface IContainerFilterEntity {
    status: StatusContainerEnum[];
}
export interface ContainerState {
    containers: ContainerFullDetailEntity[] | [];
    status: StatusDataContainerEnum;
    filter: IContainerFilterEntity;
}

export enum StatusDataContainerEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

const initialState = {
    containers: [],
    filter: {status: []},
    status: StatusDataContainerEnum.Initial,
} as ContainerState;

const containerSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setContainers(state, action: PayloadAction<ContainerFullDetailEntity[] | []>) {
            state.containers = action.payload
        },        
        setFilter(state, action: PayloadAction<IContainerFilterEntity>) {
            state.filter = action.payload
        },
        setStatusContainer(state, action: PayloadAction<StatusDataContainerEnum>) {
            state.status = action.payload
        },
        reset(state) {
            state.containers = []
            state.status = StatusDataContainerEnum.Initial
            state.filter = {status: []}
        }
    },
})

export const {
    setContainers,
    setStatusContainer,
    setFilter,
    reset
} = containerSlice.actions
export default containerSlice.reducer

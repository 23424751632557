import apiRepository from '../../../data/repository/index';
import { setStatus, StatusLayoutEnum } from '../../redux/slices/layout_slice';
import GetContainersPaymentsFullDetailUsecase from '../../../domain/usecases/get_container_full_detail_payments';


export const GetContainersPaymentsFullDetailEvent = () => async (dispatch) => {
  dispatch(setStatus(StatusLayoutEnum.Loading));
  const usecase = new GetContainersPaymentsFullDetailUsecase(apiRepository);

  const result = await usecase.execute();

  result.fold(
    (error) => {
      console.error('Error fetching suppliers:', error);
    },
    () => {
      dispatch(setStatus(StatusLayoutEnum.Success));
    }
  );
    return result.right;
};


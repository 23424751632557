import React, { useEffect } from "react";
import { Container, Box, Grid, TableContainer, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { GetPaymentPlantEvent } from "../../../../events/company/get_payment_plan_event";
import ClientTable from "./ClientPaymentTable";
import ClientsNavBar from "../ClientsNavBar";

const ClientsPaymentPlanView: React.FC = () => {
    const { companyPayments } = useAppSelector((state) => state.payment_plan);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(GetPaymentPlantEvent());
    }, [dispatch]);

    console.log(companyPayments);

    return (
        <Box>
            <Typography variant="h5" color="inherit" component="div" sx={{ flexGrow: 1, color: "#0A66A5", fontWeight: 'bold' }}>
                Clientes
            </Typography>
            <Container>
                <Grid container sx={{ padding: '10px' }} spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6" color="inherit" component="div" sx={{ color: "#0A66A5" }}>
                                                Nombre Cliente
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" color="inherit" component="div" sx={{ color: "#0A66A5" }}>
                                                Compañia
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" color="inherit" component="div" sx={{ color: "#0A66A5" }}>
                                                Contenedores activos
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" color="inherit" component="div" sx={{ color: "#0A66A5" }}>
                                                Próximo Cobro
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {companyPayments.map((company) => (
                                        <ClientTable key={company.id} company={company} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ClientsPaymentPlanView;

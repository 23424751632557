import React, { FC, useState ,useEffect} from 'react';
import { Box, Typography, Grid, TextField, Button, Paper, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import {  useAppSelector ,useAppDispatch} from '../../hooks/hooks';
import {PatchCompanyEvent} from '../../events/user/patch_company_event';


const EmpresaProfile: FC = () => {
    const { user } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const [empresaInfo, setEmpresaInfo] = useState({
        nombre: "",
        email: "",
        celular: "",
        direccion: "",
        rut: "",
        owner: "",
    });
    
    useEffect(() => {
        if (user && user.company) {
            setEmpresaInfo({
                nombre: user.company.name,
                email: user.company.email,
                celular: user.company.phone,
                direccion: user.company.address,
                rut: user.company.rut,
                owner: user.company.owner,
            });
        }
    }, [user]);

    const navigate = useNavigate();
    const handleInputChange = (field: string, value: string) => {
        setEmpresaInfo(prevState => ({ ...prevState, [field]: value }));
    };
    const saveChanges = async () => {
        const params = { name: empresaInfo.nombre,  address: empresaInfo.direccion, phone:empresaInfo.celular, email:empresaInfo.email};
        await dispatch(PatchCompanyEvent(params));
    };
    const handleBack = () => {
        navigate('/profile');
    };

    return (
        <Box sx={{ padding: 4, maxWidth: '600px', margin: '0 auto' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 3 }}>
                        <Typography variant="h6">Información de la Empresa</Typography>
                        <Divider sx={{ marginY: 2 }} />
                        <TextField
                            label="Nombre de la Empresa"
                            value={empresaInfo.nombre}
                            onChange={(e) => handleInputChange('nombre', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                            
                        />
                        <TextField
                            label="RUT ej: 77.111.222-4"
                            value={empresaInfo.rut}
                            onChange={(e) => handleInputChange('rut', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            label="Dueño"
                            value={empresaInfo.owner}
                            onChange={(e) => handleInputChange('owner', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            label="Correo Electrónico"
                            value={empresaInfo.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Celular"
                            value={empresaInfo.celular}
                            onChange={(e) => handleInputChange('celular', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Dirección"
                            value={empresaInfo.direccion}
                            onChange={(e) => handleInputChange('direccion', e.target.value)}
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                        />
                        <Box sx={{ display: 'flex', mb: 2 }}>
                            <Button 
                                variant="outlined" 
                                color="secondary" 
                                onClick={handleBack} 
                                sx={{ flex: 1, marginRight: 1 }}
                            >
                                Volver
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={saveChanges} 
                                sx={{ flex: 1, marginLeft: 1 }}
                            >
                                Actualizar Cambios
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmpresaProfile;

import React, { useEffect, useState } from "react";
import { Container, Grid, Box, Typography, IconButton, Pagination } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import FilterMenu from "./FilterMenu";
import { GetAllContainersEvent } from "../../../../events/container/get_all_containers_event";
import { AdminContainersModeEnum, setMode } from "../../../../redux/slices/admin_container_slice";
import { ContainerAdminEntity } from '../../../../../domain/entities';
import ContainersNavBar from "./ContainersNavBar";
import ContainerLayout from './container_layout';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { StatusEntity } from "../../../../../domain/entities";

const ContainersAdmin: React.FC = () => {
  const { containers } = useAppSelector((state) => state.simple_container);
  const { mode, filter } = useAppSelector((state) => state.admin_container);
  const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(null);
  const isMenuFilterOpen = Boolean(anchorFilterEl);
  const dispatch = useAppDispatch();

  const [sortConfig, setSortConfig] = useState<{ column: string; direction: 'asc' | 'desc' }>({ column: 'id', direction: 'asc' });
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 

  const handleMenuFilterOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(anchorFilterEl ? null : event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorFilterEl(null);
  };

  const getStatus = (container: ContainerAdminEntity) => {
      const firstIncompleteMovement = container.containerMovements.find(movement => !movement.is_completed);
      return firstIncompleteMovement ? firstIncompleteMovement.status : container.containerMovements[container.containerMovements.length - 1].status;
  };

  useEffect(() => {
    dispatch(GetAllContainersEvent());
    dispatch(setMode(AdminContainersModeEnum.View))
  }, []);
  
console.log(containers);
  const sortedContainers = [...containers].sort((a, b) => {
    const { column, direction } = sortConfig;
    let aValue, bValue;

    if (column === 'id') {
      aValue = a.id;
      bValue = b.id;
    } else if (column === 'created') {
      aValue = new Date(a.createdAt).getTime();
      bValue = new Date(b.createdAt).getTime();
    } else if (column === 'modified') {
      aValue = new Date(a.updatedAt).getTime();
      bValue = new Date(b.updatedAt).getTime();
    } 
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    return 0;
  });

  const filteredContainers = filter.statuses.length === 0
    ? sortedContainers 
    : sortedContainers.filter((container) => {
        const statusNumber = getStatus(container).id; 
        return filter.statuses.includes(statusNumber);
    });

  // Calculate the current containers to display based on pagination
  const totalPages = Math.ceil(filteredContainers.length / itemsPerPage);
  const displayedContainers = filteredContainers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const toggleSortDirection = (column: string) => {
    setSortConfig((prevConfig) => ({
      column,
      direction: prevConfig.column === column && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <Box sx={{ width: '100%' }}> 
      <ContainersNavBar title="Contenedores"/> 
      <Container sx={{ width: '100%', padding: 0 }}> 
          {mode === AdminContainersModeEnum.View && (
          <Grid container sx={{ marginTop: '10px'}} spacing={1}>
              {/* Table Header */}
              <Grid item container xs={12} sx={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center', justifyContent: 'center' }}>
                  <Grid item xs={2} sx={{ backgroundColor: '#0A66A5', padding: '10px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>ID Contenedor</Typography>
                      <IconButton onClick={() => toggleSortDirection('id')} sx={{ color: 'white' }}>
                        {sortConfig.column === 'id' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 20 }} /> : <ArrowDownwardIcon sx={{ fontSize: 20 }} />}
                      </IconButton>
                  </Grid>
                  <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                  <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>Creado en</Typography>
                      <IconButton onClick={() => toggleSortDirection('created')}>
                        {sortConfig.column === 'created' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 20 }}/> : <ArrowDownwardIcon sx={{ fontSize: 20 }}/>}
                      </IconButton>
                  </Grid>
                  <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                  <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>Última Modificación</Typography>
                      <IconButton onClick={() => toggleSortDirection('modified')}>
                        {sortConfig.column === 'modified' && sortConfig.direction === 'asc' ? <ArrowUpwardIcon sx={{ fontSize: 20 }}/> : <ArrowDownwardIcon sx={{ fontSize: 20 }}/>}
                      </IconButton>
                  </Grid>
                  <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                  <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>Estado</Typography>
                      <FilterMenu 
                        anchorEl={anchorFilterEl} 
                        isMenuOpen={isMenuFilterOpen} 
                        handleMenuOpen={handleMenuFilterOpen} 
                        handleMenuClose={handleMenuClose} />
                  </Grid>
                  <Box sx={{ width: '2px', backgroundColor: '#ccc', height: '40px', margin: 'auto' }} />
                  <Grid item xs={2} sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>Productos</Typography>
                  </Grid>
              </Grid>
              {/* Table Body */}
              <Grid item container xs={12} spacing={0} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  {displayedContainers.map((container) => (
                      <Grid item xs={12} key={container.id}>
                          <ContainerLayout container_id={container.id} status={getStatus(container)} />
                      </Grid>
                  ))}
              </Grid>
              {/* Pagination Controls */}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Pagination 
                  count={totalPages} 
                  page={currentPage} 
                  onChange={(event, page) => setCurrentPage(page)} 
                  color="primary" 
                />
              </Grid>
          </Grid>
          )}
      </Container>
    </Box>
  );
};

export default ContainersAdmin;

import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { UserEntity } from '../entities';

export default class PutProfileUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: {email: string,  first_name:string, last_name:string}): Promise<Either<UserEntity>> {
        return this.repository.putProfile(
            params.email,
            params.first_name,
            params.last_name
        );
    }

};
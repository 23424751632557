import apiRepository from "../../../data/repository";
import { TransactionEntity } from '../../../domain/entities';
import PutTransactionUseCase from "../../../domain/usecases/put_transaction_usecase";

export const PutTransactionEvent = async (
    id: string,
    payment_id: string,
    status: number
): Promise<TransactionEntity> => {
    const usecase = new PutTransactionUseCase(apiRepository);

    const result = await usecase.execute({
        id: id,
        payment_id: payment_id,
        status: status
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const transactionEntity = result.right;
    if (!transactionEntity) {
        throw new Error("No se pudo");
    }
    
    return transactionEntity;

}

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetProformasEvent } from "../../events/proforma/get_proformas_event";
import { GetSuppliersEvent } from "../../events/admin/get_suppliers_event";
import { Container, Typography, Divider } from "@mui/material";
import ImportCard from "../../components/user_history/import_card";

const HistorialCompras: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector((state) => state.proformas);
  //const { suppliers } = useAppSelector((state) => state.suppliers);

  useEffect(() => {
    dispatch(GetProformasEvent());
    dispatch(GetSuppliersEvent());
  }, []);

  // Función para calcular el amountPaid sumando las transactions
  const calculateAmountPaid = (proforma) => {
    return proforma.payments.reduce((sumPayments, payment) => {
      const totalTransactionsAmount = payment.transactions.reduce(
        (sumTransactions, transaction) => sumTransactions + transaction.amount,
        0
      );
      return sumPayments + totalTransactionsAmount;
    }, 0);
  };

  // Función para formatear la fecha
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  // Filtrar proformas en proceso y finalizadas
  const inProcessProformas = proformas.filter(
    (proforma) => proforma.current_step !== "Entregado"
  );
  const completedProformas = proformas.filter(
    (proforma) => proforma.current_step === "Entregado"
  );

  return (
    <Container sx={{ padding: "20px" }}>
      <Typography variant="h4" sx={{ fontWeight: "600", marginBottom: "30px", color: "#0A66A5" }}>
        Historial de compras
      </Typography>

      {/* Sección de importaciones en proceso */}
      <Typography variant="h5" sx={{ fontWeight: "550", color: "#0A66A5", marginBottom: "15px" }}>
        Importaciones en Proceso
      </Typography>
      {inProcessProformas.map((proforma, index) => (
        <ImportCard
          key={index}
          id={proforma.id}
          title={proforma.productName}
          image={proforma.productImageUrl}
          materials={proforma.productMaterials}
          medidas={proforma.productSize}
          peso={proforma.productWeight}
          status={proforma.current_step || "Desconocido"}
          nextStep={`Próximo paso: ${proforma.next_step || "Ninguno"}`}
          progressSteps={proforma.progressSteps || []}
          paymentProgress={
            Math.min((calculateAmountPaid(proforma) / proforma.totalAmount) * 100, 100) || 0
          }
          amountPaid={calculateAmountPaid(proforma) || 0}
          totalAmount={proforma.totalAmount || 0}
          debt={proforma.totalAmount - calculateAmountPaid(proforma) || 0}
          dueDate={formatDate(proforma.proforma_deadline) || ""}
        />
      ))}

      <Divider sx={{ my: 4 }} />

      {/* Sección de importaciones finalizadas */}
      <Typography variant="h5" sx={{ fontWeight: "500", color: "#0A66A5", marginBottom: "15px" }}>
        Importaciones Finalizadas
      </Typography>
      {completedProformas.map((proforma, index) => (
        <ImportCard
          key={index}
          id={proforma.id}
          title={proforma.productName}
          image={proforma.productImageUrl}
          materials={proforma.productMaterials}
          medidas={proforma.productSize}
          peso={proforma.productWeight}
          status={proforma.current_step || "Desconocido"}
          nextStep={`Próximo paso: ${proforma.next_step || "Ninguno"}`}
          progressSteps={proforma.progressSteps || []}
          paymentProgress={
            Math.min((calculateAmountPaid(proforma) / proforma.totalAmount) * 100, 100) || 0
          }
          amountPaid={calculateAmountPaid(proforma) || 0}
          totalAmount={proforma.totalAmount || 0}
          debt={proforma.totalAmount - calculateAmountPaid(proforma) || 0}
          dueDate={formatDate(proforma.proforma_deadline) || ""}
        />
      ))}
    </Container>
  );
};

export default HistorialCompras;

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

interface TransactionDetailsProps {
    transactions: Array<{
        id: string;
        amount: number;
        status: number;
        created_at: string;
    }>;
}

const TransactionDetails: React.FC<TransactionDetailsProps> = ({ transactions }) => (
    <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                    <TableCell>{transaction.id}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.status}</TableCell>
                    <TableCell>{transaction.created_at}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default TransactionDetails;

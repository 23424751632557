import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { CompanyEntity } from '../entities';

export default class PatchCompanyUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: {name: string,  address:string, phone:string, email:string}): Promise<Either<CompanyEntity>> {
        return this.repository.patchCompany(
            params.name,
            params.address,
            params.phone,
            params.email
        );
    }

};
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ContainerAdminEntity, ContainerAdminInfoEntity, ContainerClientInfoEntity } from '../../../domain/entities/container/index'
import { StatusContainerEnum } from './admin_container_slice';
import { set } from 'date-fns';

export interface IContainerFilterEntity {
    status: StatusContainerEnum[];
}
export interface AdminContainerState {
    containers: ContainerAdminEntity[] | [];
    single_container: { [key: string]: ContainerAdminInfoEntity }| null;
    container_client_info: ContainerClientInfoEntity | null;
    status: StatusDataContainerEnum;
    filter: IContainerFilterEntity;
}

export enum StatusDataContainerEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

const initialState = {
    containers: [],
    single_container: {},
    container_client_info: null,
    filter: {status: []},
    status: StatusDataContainerEnum.Initial,
} as AdminContainerState;

const containerSlice = createSlice({
    name: 'containers',
    initialState,
    reducers: {
        setContainers(state, action: PayloadAction<ContainerAdminEntity[] | []>) {
            state.containers = action.payload
        },        
        setSingleContainer(state, action: PayloadAction<{ id: string; container: ContainerAdminInfoEntity }> | null) {
          if (action.payload) {
            const { id, container } = action.payload;
            state.single_container[id] = container; 
          }
        },
        setContainerClient(state, action: PayloadAction<ContainerClientInfoEntity>) {
            state.container_client_info = action.payload
        },
        setFilter(state, action: PayloadAction<IContainerFilterEntity>) {
            state.filter = action.payload
        },
        setStatusContainer(state, action: PayloadAction<StatusDataContainerEnum>) {
            state.status = action.payload
        },
        reset(state) {
            state.containers = []
            state.single_container = {}
            state.status = StatusDataContainerEnum.Initial
            state.filter = {status: []}
        }
    },
})

export const {
    setContainers,
    setSingleContainer,
    setStatusContainer,
    setContainerClient,
    setFilter,
    reset
} = containerSlice.actions
export default containerSlice.reducer

import React, { Fragment } from 'react';
import { Box, Typography, Menu, MenuItem, Checkbox, Grid, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { StatusContainerEnum, setContainerFilter } from '../../../../redux/slices/admin_container_slice';

interface FilterMenuProps {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
}

const FilterMenu: React.FC<FilterMenuProps> = ({ anchorEl, isMenuOpen, handleMenuOpen, handleMenuClose }) => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.admin_container);

  const StatusToNumberMap: Record<StatusContainerEnum, number> = {
    [StatusContainerEnum.INIT]: 1,
    [StatusContainerEnum.OC_CONTANDEM]: 2,
    [StatusContainerEnum.OC_PROVEEDOR]: 3,
    [StatusContainerEnum.PAGO]: 4,
    [StatusContainerEnum.FABRICANDO]: 5,
    [StatusContainerEnum.FABRICADO]: 6,
    [StatusContainerEnum.PUERTO_CHINO]: 7,
    [StatusContainerEnum.TRANSITO_MARITIMO]: 8,
    [StatusContainerEnum.PUERTO_DESTINO]: 9,
    [StatusContainerEnum.INTERNACION]: 10,
    [StatusContainerEnum.TRANSITO_BODEGA]: 11,
    [StatusContainerEnum.ENTREGADO]: 12,
    [StatusContainerEnum.PETICION_DISENO]: 13,
    [StatusContainerEnum.DISENO_CHINA]: 14,
    [StatusContainerEnum.PROTOTIPO]: 15,
    [StatusContainerEnum.ACEPTACION_DISENO]: 16
  };


  const handleFilterChange = (status: StatusContainerEnum, checked: boolean) => {
    const statusNumber = StatusToNumberMap[status];
    const updatedStatuses = checked
      ? [...filter.statuses, statusNumber]
      : filter.statuses.filter((s) => s !== statusNumber);
    dispatch(setContainerFilter({ statuses: updatedStatuses }));
  };

  return (
    <Fragment>
      <IconButton onClick={handleMenuOpen}>
        <FilterAltIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem>
          <Typography variant="h6">Estados</Typography>
        </MenuItem>
        <MenuItem>
          <Grid container direction="column">
            {Object.values(StatusContainerEnum).slice(0, 9).map((status) => (
              <Grid key={status}>
                <Checkbox
                  checked={filter.statuses.includes(StatusToNumberMap[status])}
                  onChange={(_, checked) => handleFilterChange(status, checked)}
                />
                {status}
              </Grid>
            ))}
          </Grid>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default FilterMenu;

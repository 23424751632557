import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupplierEntity } from "../../../domain/entities/supplier/supplier_entity";

export interface SupplierState {
  suppliers: SupplierEntity[];
}

const initialState: SupplierState = {
  suppliers: [],
};

const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setSuppliers(state, action: PayloadAction<SupplierEntity[]>) {
      state.suppliers = action.payload;
    },
    updateSupplierDebt(
      state,
      action: PayloadAction<{
        supplierId: string;
        containerId: string;
        amount: number;
      }>
    ) {
      const { supplierId, containerId, amount } = action.payload;
      const supplier = state.suppliers.find((sup) => sup.id === supplierId);
      if (supplier) {
        const container = supplier.containers.find(
          (cont) => cont.id === containerId
        );
        if (container) {
          container.debt = Math.max(0, (container.debt || 0) - amount); // Ajusta la deuda del contenedor
        }
      }
    },
  },
});

export const { setSuppliers, updateSupplierDebt } = supplierSlice.actions;
export default supplierSlice.reducer;

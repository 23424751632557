import React from "react";
import { TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContainerDetails from "./ContainerDetails";

interface ClientTableProps {
    company: any;
}

const getEarliestDueDateAfterToday = (containers: any[]) => {
    const dueDates: number[] = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0); 

    containers.forEach((container) => {
        container.proformas.forEach((proforma) => {
            proforma.payments.forEach((payment) => {
                if (payment.dueDate) {
                    const [date, time] = payment.dueDate.split(" a las ");
                    const formattedDate = new Date(`${date}T${time}:00`).getTime();
                    if (formattedDate > today.getTime()) {
                        dueDates.push(formattedDate);
                    }
                }
            });
        });
    });
    return dueDates.length > 0 ? new Date(Math.min(...dueDates)) : null;
};

const ClientTable: React.FC<ClientTableProps> = ({ company }) => {
    const earliestDueDate = getEarliestDueDateAfterToday(company.containers);

    return (
        <TableRow>
            <TableCell colSpan={3}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <TableCell style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <AccountCircleIcon/>
                                {company.owner}
                            </TableCell>
                            <TableCell>{company.name}</TableCell>
                            <TableCell>N/A</TableCell>
                            <TableCell>{earliestDueDate ? earliestDueDate.toLocaleDateString() : "No hay próximo cobro"}</TableCell>
                    </AccordionSummary>
                    <AccordionDetails>
                        {company.containers.map((container: any) => (
                            <Accordion key={container.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>ID Contenedor: {container.id}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ContainerDetails container={container} />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </TableCell>
        </TableRow>
    );
};

export default ClientTable;

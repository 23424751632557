import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { ContainerMovementEntity } from '../entities';
import {IPutContainerMovement} from '../../data/datasource/api';


export default class PutContainerMovementUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: IPutContainerMovement): Promise<Either<ContainerMovementEntity>> {
        return this.repository.putContainerMovement(
            params.id,
            params.estimated_date,
            params.real_date,
            params.step,
            params.container,
            params.status,
        );
    }

};
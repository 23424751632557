import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CompanyEntity, UserEntity } from '../../../domain/entities'

export enum StatusUserEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}
export interface UserState {
    user: UserEntity | null;
    status: StatusUserEnum;
}

const initialState = {
    user: null,
    status: StatusUserEnum.Initial
} as UserState;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserEntity | null>) {
            if (action.payload === null) {

                state.user = null
                state.status = StatusUserEnum.Initial
                return
            }
            state.user = action.payload
        },
        setStatusUser(state, action: PayloadAction<StatusUserEnum>) {
            state.status = action.payload
        },
        setCompany(state, action: PayloadAction<CompanyEntity>) {
            if (state.user) {
                state.user = {
                    ...state.user,
                    company: action.payload
                };
                console.log("Company updated:", state.user);
            }
        }
    },
})

export const { setUser, setStatusUser,setCompany } = userSlice.actions
export default userSlice.reducer

import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { SupplierEntity } from "../../../../domain/entities";

interface ProviderInfoProps {
  supplier: SupplierEntity;
}

const ProviderInfo: React.FC<ProviderInfoProps> = ({ supplier }) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Nombre Contacto:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.contactName || "No disponible"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Correo:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.email || "No disponible"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Celular:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.phone || "No disponible"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          País:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.country || "No disponible"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Ciudad:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.city || "No disponible"}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="left" style={{ fontWeight: "bold", width: "40%" }}>
          Dirección:
        </TableCell>
        <TableCell align="left" style={{ width: "60%" }}>
          {supplier.address || "No disponible"}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default ProviderInfo;

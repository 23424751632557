import { Either} from "../../core";

import { ApiNetworkType } from "../../core/api_network";
import { ApiRepository } from "../../domain/repository/api";
import { ApiDataSource } from "../datasource/api";

//import { ProductEntity, ContainerEntity, UserEntity, ContainerFullDetailEntity, ProformaEntity, TransactionEntity, CompanyEntity, CompanyPaymentsEntity, ClarificationRequestEntity, ContainerProductEntity} from "../../domain/entities";

import { ProductEntity, ContainerEntity, UserEntity, ContainerFullDetailEntity, ProformaEntity, TransactionEntity,  CompanyEntity, CompanyPaymentsEntity ,ClarificationRequestEntity,ContainerProductEntity,MetricUnitEntity,
        PaymentPlanEntity,
        ContainerMovementEntity,
        ContainerAdminEntity,
        ContainerAdminInfoEntity,
        ContainerClientInfoEntity,
        SupplierEntity,
        FullProductEntity,
        ContainerPaymentsFullDetailEntity,
        ExternalPaymentEntity,
        InvestmentEntity
} from "../../domain/entities";


export class ApiRepositoryImpl implements ApiRepository {
    datasource: ApiDataSource;
    api: ApiNetworkType;

    constructor(datasource: ApiDataSource, api: ApiNetworkType) {
        this.datasource = datasource;
        this.api = api;
    }

    getContainers = async (): Promise<Either<ContainerEntity[]>> => {
        const result = await this.api.call<ContainerEntity[]>(this.datasource.getContainers);
        return result;
    }
    getContainersFullDetail = async (): Promise<Either<ContainerFullDetailEntity[]>> => {
        const result = await this.api.call<ContainerFullDetailEntity[]>(
            this.datasource.getContainersFullDetail,
        );
        return result;
    }
    getAllContainers = async (): Promise<Either<ContainerAdminEntity[]>> => {
      const result = await this.api.call<ContainerAdminEntity[]>(this.datasource.getAllContainers);
      return result;
    }
    getSingleContainerInfo = async (id: string): Promise<Either<ContainerAdminInfoEntity>> => {
        const result = await this.api.call<ContainerAdminInfoEntity>(
          ( ) => this.datasource.getSingleContainerInfo({
              id}
          ),
        ); return result;
    }
    getContainerClientInfo = async (id: string): Promise<Either<ContainerClientInfoEntity>> => {
        const result = await this.api.call<ContainerClientInfoEntity>(
          () => this.datasource.getContainerClientInfo({
            id}
          ),
        );
        return result
    }
    
    createContainer = async (capacity:number,fixed_cost: number,tax: number, payments:number, percentages:number[], due_dates: string[],deadlines: string[]): Promise<Either<ContainerEntity>> =>{
        const result = await this.api.call<ContainerEntity>(
            ( ) => this.datasource.createContainer({
                capacity,fixed_cost,tax,payments,percentages,due_dates,deadlines }
          ),
        ); return result;
    }
    putContainer = async (id:string,capacity:number,fixed_cost: number,tax: number): Promise<Either<ContainerFullDetailEntity>> =>{
            const result = await this.api.call<ContainerFullDetailEntity>(
                ( ) => this.datasource.putContainer({
                    id,capacity,fixed_cost,tax}
              ),
            ); return result;

    }
    
    createProductContainer = async ( min_quantity: number, max_quantity: number, unit_base_price: number, increased_percent: number, interval: number, container: string, product: string,  metric_unit: number, units_per_package: number): Promise<Either<ContainerProductEntity>> =>{
        const result = await this.api.call<ContainerProductEntity>(
            ( ) => this.datasource.createProductContainer({ min_quantity, max_quantity, unit_base_price, increased_percent, interval, container, product,  metric_unit, units_per_package
                }
          ),
        ); return result;
    }


    getProducts = async (): Promise<Either<ProductEntity[]>> => {
        const result = await this.api.call<ProductEntity[]>(this.datasource.getProducts);
        return result;
    }

    getProductFullDetail = async (product_id: string ):Promise<Either<FullProductEntity>> => {
        const result = await this.api.call<FullProductEntity>( () => this.datasource.getProductFullDetail({product_id}) );
        return result;
    }

    createProduct = async (id: string, name: string, price: number, category: number, description: string, width: number, length: number, depth: number, weight: number, materials: string, image_url: string, supplier: string): Promise<Either<ProductEntity>> =>{
        const result = await this.api.call<ProductEntity>(
            ( ) => this.datasource.createProduct({
                id, name, price, category, description, width, length, depth, weight, materials, image_url, supplier}
          ),
        ); return result;
    }
    putProduct = async (id: string, name: string, price: number, category: number, description: string, width: number, length: number, depth: number, weight: number, materials: string, image_url: string, supplier: string): Promise<Either<ProductEntity>> =>{
        const result = await this.api.call<ProductEntity> (
            ( ) =>this.datasource.putProduct({id, name, price, category, description, width, length, depth, weight, materials, image_url, supplier}),); 
            return result;
        }
    deleteProduct = async ( product_id:string): Promise<Either<void>> =>{
        const result = await this.api.call<void> (
            ( ) =>this.datasource.deleteProduct({product_id}),); 
            return result;
        }
    
    loginUser = async (email: string, password: string): Promise<Either<string>> => {
        const result = await this.api.call<string>(
            () => this.datasource.loginUser(
                { email: email, password: password }
            ),
        );
        return result;
    }
      
    putPassword = async (old_password: string,  new_password:string, confirm_password:string):Promise<Either<void>> =>{
        const result = await this.api.call<void>(
            () => this.datasource.putPassword(
                { old_password, new_password,confirm_password}
            ),
        );
        return result; 
    }

    putProfile = async(email: string,  first_name:string, last_name:string):Promise<Either<UserEntity>> =>{
        const result = await this.api.call<UserEntity>(
            () => this.datasource.putProfile(
                { email, first_name,last_name}
            ),
        );
        return result; 
    }

    patchCompany = async (name: string,  address:string, phone:string, email:string): Promise<Either<CompanyEntity>> => {
        const result = await this.api.call<CompanyEntity>(
            () => this.datasource.patchCompany(
                { name, address,phone,email}
            ),
        );
        return result; 
    }


    getProfile = async (): Promise<Either<UserEntity>> => {
        const result = await this.api.call<UserEntity>(
            this.datasource.getProfile
        );
        return result;
    }

    registerUser = async (user_data: { first_name: string, last_name: string, email: string }, company_data: { phone: string, business_rut: string, business_name: string, business_category: string }): Promise<Either<UserEntity>> => {
        const result = await this.api.call<UserEntity>(
            () => this.datasource.registerUser(
                { user_data, company_data }
            ),
        );
        return result;
    }

    getProformas = async (): Promise<Either<ProformaEntity[]>> => {
        const result = await this.api.call<ProformaEntity[]>(this.datasource.getProformas);
        return result;
    }

    getSuppliers = async (): Promise<Either<SupplierEntity[]>> => {
        const result = await this.api.call<SupplierEntity[]>(this.datasource.getSuppliers);
        return result;
    }

    createTransaction = async (id: string, file: Blob, payment_id: string, amount: number): Promise<Either<TransactionEntity>> => {
        const result = await this.api.call<TransactionEntity>(
            () => this.datasource.createTransaction(
                { id, file, payment_id, amount }
            ),
        );
        return result;
    }

    putTransaction = async (id: string, payment_id: string, status: number): Promise<Either<TransactionEntity>> => {
        const result = await this.api.call<TransactionEntity>(
            () => this.datasource.putTransaction(
                { id, payment_id, status }
            ),
        );
        return result;
    }


    createProforma = async (id: string, product_container_id: string, quantity: number, price: number, dispatch: boolean, address: string): Promise<Either<ProformaEntity>> => {
        const result = await this.api.call<ProformaEntity>(
            () => this.datasource.createProforma(
                { id, product_container_id, quantity, price, dispatch, address }
            ),
        );
        return result;
    }

    acceptUser = async (user_id: string): Promise<Either<void>> => {
        const result = await this.api.call<void>(
            () => this.datasource.acceptUser(
                { user_id }
            ),
        );
        return result;
    }

    getCompanies = async (): Promise<Either<CompanyEntity[]>> => {
        const result = await this.api.call<CompanyEntity[]>(this.datasource.getCompanies);
        return result;
    }

    getCompanyPayments = async (companyId: string): Promise<Either<CompanyPaymentsEntity>> => {
        const result = await this.api.call<CompanyPaymentsEntity>(() => this.datasource.getCompanyPayments(companyId));
        return result;
      };
    
    getCompanyInvestments = async (): Promise<Either<InvestmentEntity>> => {
        const result = await this.api.call<InvestmentEntity>(this.datasource.getCompanyInvestments.bind(this.datasource));
        return result;
    };
    
      
    getPaymentPlan = async (): Promise<Either<PaymentPlanEntity[]>> => {
        const result = await this.api.call<PaymentPlanEntity[]>(() => this.datasource.getPaymentPlan());
        return result;
      };

    sendClarificationRequest = async (
         id: string, proformaId: string, clientName: string, message: string, email: string, selectedTransaction: string
    ): Promise<Either<ClarificationRequestEntity>> => {
        console.log("HOLA")
        const result = await this.api.call<ClarificationRequestEntity>(
            () => this.datasource.sendClarificationRequest({id, proformaId, clientName, message, email, selectedTransaction})
        );
        
        return result;
    };

    createExternalPayment = async (
        id: string, supplierId: string, containerId: string, amount: number, description: string,
   ): Promise<Either<ExternalPaymentEntity>> => {
       console.log("HOLA")
       const result = await this.api.call<ExternalPaymentEntity>(
           () => this.datasource.createExternalPayment({id, supplierId, containerId, amount, description})
       );
       
       return result;
   };

    getMetrics = async (): Promise<Either<MetricUnitEntity[]>> => {
        const result = await this.api.call<MetricUnitEntity[]>(this.datasource.getMetrics);
        return result;
      }
    
    createMetric = async (name:string, description:string,square_meters:number): Promise<Either<MetricUnitEntity>> =>{
        const result = await this.api.call<MetricUnitEntity>(
            ( ) => this.datasource.createMetric({
           name,description,square_meters})); 
        return result;
      }

      createContainerMovement = async (estimated_date: string, real_date: string, step: number, container: string,status:number):Promise<Either<ContainerMovementEntity>> =>{
        const result = await this.api.call<ContainerMovementEntity>(
            ()=>this.datasource.createContainerMovement({
                estimated_date,real_date,step,container,status
            }))
            return result;
      }

      putContainerMovement = async (id:number,estimated_date: string, real_date: string, step: number, container: string,status:number):Promise<Either<ContainerMovementEntity>> =>{
        const result = await this.api.call<ContainerMovementEntity>(
            ()=>this.datasource.putContainerMovement({
                id,estimated_date,real_date,step,container,status
            }))
            return result;
      }

      getContainerFullDetailPayments = async():Promise<Either<ContainerPaymentsFullDetailEntity[]>> => {
        const result = await this.api.call<ContainerPaymentsFullDetailEntity[]>(this.datasource.getContainerFullDetailPayments);
        return result;
      }
}

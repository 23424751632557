import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { ContainerFullDetailEntity } from '../entities';
import {IPutContainer} from '../../data/datasource/api';


export default class PutContainerUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: IPutContainer): Promise<Either<ContainerFullDetailEntity>> {
        return this.repository.putContainer(
            params.id,
            params.capacity,
            params.fixed_cost,
            params.tax,
        );
    }

};
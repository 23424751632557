import React from "react";
import RegisterForm from "../../components/home/register_form";

const RegisterPage: React.FC = () => {
    return (
        <div>
            <RegisterForm />
        </div>
    );
}

export default RegisterPage;
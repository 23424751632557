import React, { useState,useEffect } from 'react';
import { Box, Grid, Typography, Button, IconButton, TableContainer, TableRow, TableHead, Table, TableCell, TableBody,TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
const createPricingTable = (importData) => {
    const {
      interval,
      maxQuantity,
      minQuantity,
      unitBasePrice,
      increasedPercent,
    } = importData;
  
    const pricingData = [];
    let currentMin = minQuantity;
    let index = 0;
  
    while (currentMin < maxQuantity) {
      const currentMax = Math.min(currentMin + interval, maxQuantity);
      const discount = increasedPercent * index;
      const price = unitBasePrice * (1 - discount);
  
      pricingData.push({
        range: `${Math.round(currentMin).toLocaleString('es-ES')} - ${Math.round(currentMax).toLocaleString('es-ES')}`,
        price: price.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      });
  
      currentMin += interval;
      index++;
    }
  
    return pricingData;
  };

const DataCard = ({importData}) => {
  console.log(importData);
  const container_product = importData.containerProducts[0];
  const pricingData= createPricingTable(container_product);
  const [quantity, setQuantity] = useState(100);
  const [selectedContent, setSelectedContent] = useState(0); 
  const [totalPrice,setTotalPrice] = useState(100*container_product.unitBasePrice)

useEffect(() => {
    setTotalPrice(getPriceForQuantity()*quantity)
}, [quantity]);

  const navigate = useNavigate();
    
  const handleLeftClick = () => {
    setSelectedContent(0); // Cambia a contenido izquierdo
  };

  const getPriceForQuantity = () => {
    for (let i = 0; i < pricingData.length; i++) {
      const [min, max] = pricingData[i].range.split(' - ').map(num => parseInt(num.replace('.', ''), 10));
      if (quantity >= min && quantity <= max) {
        return parseFloat(pricingData[i].price.replace(',', '.'));
      }
    }
    return container_product.unitBasePrice;
  };

  const AddCart = () =>{
    navigate('/cart', { state: { container: importData, totalPrice: totalPrice, units: quantity } });
  }
  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    }
}
  
    const calcularValorIntervalo = () => {
    const carga_total = (container_product.maxQuantity - container_product.quantity) / container_product.maxQuantity;
    let intervalo;
    if (carga_total <= 0) {
      intervalo = 20;
    } else {
      intervalo = Math.round(20 - carga_total * 20);
    }
  
    return intervalo;
  };

  const handleRightClick = () => {
    setSelectedContent(1); // Cambia a contenido derecho
  };
  return (
    <Box sx={{ height: '50hv', width: '98%', padding: '5px', backgroundColor: '#f5f5f5', border: '2px solid #0A66A5',  borderRadius: '10px',alignItems:"center" }}>
      <Grid container sx={{ height: '100%',maxHeight:'50hv' }}>
        <Grid container item sx={{ height: '25%', alignItems: 'flex-end', marginBottom:'15px' }}>
            {/* Imagen */}
            <Grid item xs={2.5} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <img
                src={`https://assetscontandem.s3.us-east-1.amazonaws.com/porcentajes/${calcularValorIntervalo()}.png`}
                alt="Capacidad Usada"
                style={{ width: 'auto', height: '10vh', objectFit: 'contain' }}
                />
            </Grid>


            {/* Porcentaje */}
            <Grid item xs={1.5} sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: '35px', color: '#0A66A5' }}>
                <strong>{Math.round(( (container_product.quantity)/ container_product.maxQuantity) * 100)}%</strong>
                </Typography>
            </Grid>

            {/* Texto Descripción */}
            <Grid item xs={3.5} sx={{  height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography sx={{ fontSize: '20px', color: '#0A66A5', lineHeight: 1.3 }}>
                <strong>Del contenedor vendido</strong>
                </Typography>
            </Grid>

            {/* Texto al final */}
            <Grid item xs={4.5} sx={{ display: 'flex', height: '100%', padding: '8px' }}>
                <Typography sx={{ fontSize: '16px' }}>
                / Quedan { Math.round((container_product.maxQuantity - container_product.quantity)/container_product.unitsPerPackage)} de { Math.round(container_product.maxQuantity/container_product.unitsPerPackage)} cajas
                </Typography>
            </Grid>
        </Grid>
        <Grid container item sx={{ height: '15%', backgroundColor: '#dce775', alignItems: 'center' }}>
            <Grid item xs={6} 
                sx={{ backgroundColor: selectedContent === 0 ? '#c2c2c2' : '#dedede', display: 'flex', 
                    justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={handleLeftClick}>
            <Typography variant="h6" >Precios</Typography>
            </Grid>
            <Grid item xs={6} 
                sx={{ backgroundColor: selectedContent === 1 ? '#c2c2c2' : '#dedede', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    cursor: 'pointer' }} onClick={handleRightClick} >
            <Typography variant="h6" >Importación</Typography>
            </Grid>
        </Grid>
        <Grid container item sx={{ height: '60%', justifyContent: 'center', alignItems:'center'}}>
            <Grid item xs={7.4} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {selectedContent === 0 ? (
                    <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={12} sx={{ height: '15%', display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ color: '#0A66A5',marginLeft:'10px'}}><strong>Tabla de precios:</strong></Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ height: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <TableContainer sx={{ maxHeight: '100%',width:'90%'}}>
                                <Table sx={{ borderCollapse: 'separate',  borderSpacing: '5px 0px',  width: '100%'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Desde-Hasta</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Precio (IVA inc)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pricingData.map((row, index) => (
                                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{row.range}</TableCell>
                                                <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{row.price}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container sx={{ height: '100%' }}>
                        <Grid item xs={12} sx={{ height: '25%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6"  sx={{ color: '#0A66A5',marginLeft:'5px' }} ><strong>Cantidad</strong></Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ height: '25%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="body1" sx={{ marginLeft:'5px' }}>{quantity} paquetes - {quantity*container_product.unitsPerPackage} unidades</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '2px solid #0A66A5',
                                    borderRadius: '8px',
                                    padding: '3px',
                                    marginLeft: 'auto'
                                }}
                            >
                                <IconButton onClick={decreaseQuantity}>
                                    <RemoveIcon sx={{ fontSize: '15px' }}/>
                                </IconButton>
                                 <TextField
                                     value={quantity}
                                     onChange={handleQuantityChange}
                                     sx={{
                                       width: '55px',
                                       '& input': {
                                         textAlign: 'center', // Centra el texto
                                       },
                                     }}
                                     inputProps={{ min: 0 }}
                                     InputProps={{
                                       disableUnderline: true, // Quita la línea debajo del número
                                     }}
                                     variant="standard"
                                   />
                                <IconButton onClick={increaseQuantity}>
                                    <AddIcon  sx={{ fontSize: '15px' }}/>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ height: '25%', display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6" sx={{ color: '#0A66A5',marginLeft:'5px' }}><strong>Pagos</strong></Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item  xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center',  paddingLeft: '10px' }}>         
                            {importData.containerPayments.map((payment, index) => (
                                    <Typography variant="body1" key={index}>
                                        {Math.round(payment.percentage * 100)}% {(totalPrice * payment.percentage).toLocaleString('es-ES')}

                                    </Typography>
                                ))}
                            </Grid>
                            <Grid item xs={6}   sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant="h6">
                                    <span style={{ color: '#0A66A5' }}>Total:</span> {totalPrice.toLocaleString('es-ES')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {selectedContent === 0 ? (
                <Grid item xs={4.6} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="outlined" sx={{ width: '90%' }}>
                        SIMULAR IMPORTACIÓN
                    </Button>
                </Grid>):(
            <Grid item xs={4.6} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', paddingBottom: '16px' }}>
                <Button variant="outlined" sx={{ width: '90%', alignSelf: 'center', marginTop:'50%' }} onClick={AddCart}>
                    AGREGAR AL CARRO
                </Button>
            </Grid>
            )}
        </Grid>
        </Grid>
    </Box>
  );
};
export default DataCard;

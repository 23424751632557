import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DivisaInterface {
    divisa: string;
    value: number;
}

export enum DivisasEnum {
    USD = 'USD',
    CLP = 'CLP',
}

const divisas: DivisaInterface[] = [
    { divisa: DivisasEnum.USD, value: 1 },
    { divisa: DivisasEnum.CLP, value: 900 },
];

const initialState = {
    divisa: divisas[0], 
};

const DivisaSlice = createSlice({
    name: 'divisa',
    initialState,
    reducers: {
        setDivisa: (state, action: PayloadAction<DivisasEnum>) => {
            const newDivisa = divisas.find(d => d.divisa === action.payload);
            if (newDivisa) {
                state.divisa = newDivisa;
            }
        },
        updateExchangeRate: (state, action: PayloadAction<number>) => {
            state.divisa.value = action.payload; 
        },
        reset(state) {
          state.divisa = initialState.divisa;
      }

    },
});

export const { setDivisa, updateExchangeRate } = DivisaSlice.actions;
export default DivisaSlice.reducer;

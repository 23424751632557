import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';

export default class DeleteProductUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(product_id: string): Promise<Either<void>> {
        return this.repository.deleteProduct(
            product_id
        );
    }

};
import { ContainerStatusEnum, MetricUnitEntity } from './container_entity';
import { StatusEntity, mapJsonToStatusEntity } from './status';
import { ProductEntity, mapJsonToProductEntity } from '../product/product_entity';

export interface ContainerFullDetailEntity {
    id: string; // Corresponde a models.CharField
    capacity: number; // Corresponde a models.FloatField
    fixedCost: number; // Corresponde a models.FloatField
    tax: number; // Corresponde a models.FloatField
    status: ContainerStatusEnum; // Corresponde a models.IntegerField con choices
    metricUnitId: number; // Corresponde a models.ForeignKey
    units_sold: number; // Corresponde a models.FloatField
    units_total: number; // Corresponde a models.FloatField
    createdAt: string; // Corresponde a models.DateTimeField(auto_now_add=True)
    updatedAt: string; // Corresponde a models.DateTimeField(auto_now=True)
    containerProducts: ContainerProductEntity[];
    containerMovements: ContainerMovementEntity[];
    containerPayments: ContainerPaymentEntity[];
    used_capacity:number;
    // id: string;
    // quantity: number;
    // min_quantity: number;
    // max_quantity: number;
    // unit_base_price: number;
    // increased_percent: number;
    // interval: number;
    // units_per_package: number;
    // unit_purchase_price:number;
    // payments: CurrentImportPaymentEntity[];
}

export function mapJsonToContainerFullDetailEntity(json): ContainerFullDetailEntity {
    return {
        id: json.id,
        capacity: json.capacity,
        fixedCost: json.fixed_cost,
        tax: json.tax,
        status: json.status,
        metricUnitId: json.metric_unit,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        units_sold: json.units_sold,
        units_total: json.units_total,
        used_capacity: json.used_capacity,
        containerProducts: json.container_products.map(mapJsonToContainerProductEntity),
        containerMovements: json.container_movements.map(mapJsonToContainerMovementEntity),
        containerPayments: json.container_payments.map(mapJsonToContainerPaymentEntity),
    } as ContainerFullDetailEntity;
}

export interface ContainerMovementEntity {
    id: number;
    status: StatusEntity; // Debe definirse una interfaz Status correspondiente
    estimatedDate: string; // Utilizamos string para las fechas en formato ISO
    realDate: string; // Utilizamos string para la duración en formato ISO 8601
    step: number;
    createdAt: string; // Utilizamos string para las fechas en formato ISO
    updatedAt: string; // Utilizamos string para las fechas en formato ISO

    // "real_date": "2024-03-01T12:00:00Z",
    // "step": 1,
    // "created_at": "2024-10-05T21:50:47.356995Z",
    // "updated_at": "2024-10-05T21:50:47.357003Z",
    // "container": "container-002",

}
export interface ContainerPaymentEntity {
    id: number;
    status: StatusEntity; // Debe definirse una interfaz Status correspondiente
    dueDate: string; // Utilizamos string para las fechas en formato ISO
    deadline: string; // Utilizamos string para la duración en formato ISO 8601
    percentage: number;
    createdAt: string; // Utilizamos string para las fechas en formato ISO
    updatedAt: string; // Utilizamos string para las fechas en formato ISO
}

export interface ContainerProductEntity {
    id: string;
    product: ProductEntity; // Debe definirse una interfaz Product correspondiente
    quantity: number;
    minQuantity: number;
    maxQuantity: number;
    unitBasePrice: number;
    unitsPerPackage: number;
    increasedPercent: number;
    interval: number;
    metricUnit: MetricUnitEntity; // Debe definirse una interfaz MetricUnit correspondiente
    createdAt: string; // Utilizamos string para las fechas en formato ISO
    updatedAt: string; // Utilizamos string para las fechas en formato ISO
}

export function mapJsonToContainerProductEntity(json): ContainerProductEntity {
    return {
        id: json.id,
        product: mapJsonToProductEntity(json.product),
        quantity: json.quantity,
        minQuantity: json.min_quantity,
        maxQuantity: json.max_quantity,
        unitBasePrice: json.unit_base_price,
        increasedPercent: json.increased_percent,
        interval: json.interval,
        unitsPerPackage: json.units_per_package,
        metricUnit: json.metric_unit,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as ContainerProductEntity;
}

export function mapJsonToContainerMovementEntity(json): ContainerMovementEntity {
    return {
        id: json.id,
        status: mapJsonToStatusEntity(json.status),
        estimatedDate: json.estimated_date,
        realDate: json.real_date,
        step: json.step,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as ContainerMovementEntity;
}

export function mapJsonToContainerPaymentEntity(json): ContainerPaymentEntity {
    return {
        id: json.id,
        status: mapJsonToStatusEntity(json.status),
        dueDate: json.due_date,
        deadline: json.deadline,
        percentage: json.percentage,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
    } as ContainerPaymentEntity;
}

import React from "react";
import {  Box } from "@mui/material";
import { AdminContainersModeEnum } from "../../../../redux/slices/admin_container_slice";
import { useAppSelector } from "../../../../hooks/hooks";
import AddProductForm from './AddProduct';
import ImportacionCard from "./ImportationCard";
import SelectProductList from "./SelectProductList";

const AdminContainer: React.FC = () => {
    const {mode,selectedContainer,selectedProducto} = useAppSelector((state) => state.admin_container);

    return (
        <Box>
                {(mode === AdminContainersModeEnum.ViewContainer || mode === AdminContainersModeEnum.Edit) &&(
                    <Box sx={{ padding: '10px' }} alignContent="center">
                         <ImportacionCard container={selectedContainer} /> 
                    </Box>
                )}
                {mode === AdminContainersModeEnum.SelectProduct && (
                    <SelectProductList/>
                )}
                {mode === AdminContainersModeEnum.AddProduct && (

                    <AddProductForm product={selectedProducto}/>
                )}
        </Box>
    
    );
};

export default AdminContainer;

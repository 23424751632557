import { Either } from "../../core/either";
import { ApiRepository } from "../repository/api";
import { ExternalPaymentEntity } from "../entities/external_payments/external_payment_entity";

interface SendExternalPaymentParams {
  id: string;
  supplierId: string;
  containerId: string;
  amount: number;
  description: string;
}

export default class SendExternalPaymentUseCase {
  private repository: ApiRepository;

  constructor(repository: ApiRepository) {
    this.repository = repository;
  }

  async execute(
    params: SendExternalPaymentParams
  ): Promise<Either<ExternalPaymentEntity>> {
    return this.repository.createExternalPayment(
      params.id,
      params.supplierId,
      params.containerId,
      params.amount,
      params.description
    );
  }
}

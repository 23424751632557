import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Box,Grid,Typography, FormControl, InputAdornment, OutlinedInput} from '@mui/material/';
import Stack from '@mui/material/Stack';
import SideMenu from '../../../components/cfo/layout/SideMenu';
import ContainerLayout from '../../../components/cfo/containers/container_layout';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import {GetContainersPaymentsFullDetailEvent} from '../../../events/admin/get_containers_payments_full_detail';
import {  useAppDispatch} from '../../../hooks/hooks';

const CFOContainersPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [containers,setContainers] = React.useState([]);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
      const fetchData = async () => {
        const temp = await dispatch(GetContainersPaymentsFullDetailEvent());
        setContainers(temp);
    };

    fetchData();
}, []);

  const filteredContainers = containers?.filter(container =>
    container.id.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];
  
  return (
    <>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            backgroundColor:'#f0f0f0'
          }}
        >
      <Grid container spacing={2} sx={{backgroundColor:'#f0f0f0'}}>
        <Grid item xs={8} container alignItems="center">
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0A66A5', padding: '10px' }}>
            <strong>Contenedores</strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: { xs: '100%', md: '30ch' }, padding: '10px' }} variant="outlined">
            <OutlinedInput
              size="small"
              id="search"
              placeholder="Buscar contenedor"
              sx={{ flexGrow: 1 }}
              startAdornment={
                <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                  <SearchRoundedIcon fontSize="small" />
                </InputAdornment>
              }
              inputProps={{
                'aria-label': 'search',
              }}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 5 },
              backgroundColor:'#f5f5f5',
              padding:'8px'
            }}
          >
            <Grid container style={{  width: '95%' }}>
              <Grid item xs={12} style={{ height: '50px' , borderBottom: '1px solid #ccc',display: 'flex', alignItems: 'center' }}>
                <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={2.2}  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0A66A5'}} >
                        Contenedor
                      </Typography>
                  </Grid>
                  <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1"sx={{ fontWeight: 'bold', color: '#0A66A5'}} >
                      Abonos recibidos
                    </Typography>
                  </Grid>
                  <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1"sx={{ fontWeight: 'bold', color: '#0A66A5'}} >
                        Pagos a China
                    </Typography>
                  </Grid>
                  <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1"sx={{ fontWeight: 'bold', color: '#0A66A5'}} >
                      Pagos internación
                    </Typography>
                  </Grid>
                  <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', color:'#0A66A5'}} >
                      Saldo
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{  width: '95%' }}>
              <Grid container spacing={1}>
                {filteredContainers.map((container) => (
                  <ContainerLayout contenedor={container}/>
                ))}
                {filteredContainers.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="body1">No se encontraron resultados.</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default CFOContainersPage;


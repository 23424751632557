import React, { useEffect } from "react";
import { Container, Grid, Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetProductsEvent } from "../../../events/products/get_products_event";
import { AdminProductsModeEnum } from "../../../redux/slices/admin_products_slice";

import ProductForm from './ProductForm';
import { ProductEntity } from "../../../../domain/entities";
import ProductsNavBar from "./ProductsNavBar";
import AdminProductCard from "./ProductCard";

const ProductsAdmin: React.FC = () => {
    const { products, filter } = useAppSelector((state) => state.product);
    const {mode} = useAppSelector((state) => state.admin_products);
    const dispatch = useAppDispatch();

    
    useEffect(() => {
        if (mode === AdminProductsModeEnum.View) {
            dispatch(GetProductsEvent());
        }
    }, [mode]);

    return (
        <Box>
        <ProductsNavBar /> 
        <Container>
            
        {mode === AdminProductsModeEnum.View && <Grid
                container
                sx={{ padding: '10px' }}
                spacing={3}
                alignContent="center"
            >
                <Grid item container xs={12} direction={'column'}>
                    <Grid container spacing={4}>
                        {
                            products.map((product: ProductEntity) => {
                                if (product.price >= filter.range[0] &&
                                    product.price <= filter.range[1] &&
                                    (filter.categories.length === 0 || filter.categories.includes(product.category))
                                )
                                    return (
                                        <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                                            < AdminProductCard product={product}/>
                                        </Grid>
                                    );
                                else return null;
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>}
        {(mode === AdminProductsModeEnum.Create || mode === AdminProductsModeEnum.Edit) && <Box sx={{
                    display: 'flex',
                    justifyContent: 'center', 
                    alignItems: 'center',     
                    height: '80vh',          
                }}><ProductForm/></Box>}
        </Container>
        
        
        </Box>
    
    );
};

export default ProductsAdmin;

import React,{useState} from 'react';
import {Grid, Box, TextField, InputAdornment, MenuItem, Select, FormControl, InputLabel, Button} from '@mui/material';
import { useNavigate } from "react-router-dom";

const countries = [
    { name: 'Argentina', code: '+54' },
    { name: 'Chile', code: '+569' },
    { name: 'China', code: '+86' },
    { name: 'España', code: '+34' },
    { name: 'Estados Unidos', code: '+1' }
  ];

const TypesAccoundClp = [
    { name: 'Cuenta Corriente'},
    { name: 'Cuenta Vista' },
    { name: 'Cuenta RUT' },
    { name: 'Cuenta de Ahorro' },
    { name: 'Cuenta Pyme o Empresa' },
    { name: 'Cuenta Bipersonal o Conjunta'}
  ];

const AccountTypes = [
    {name: 'CLP'},
    {name: 'USD'}
]
const ProviderForm = () => {
    const [countryCode, setCountryCode] = useState('+569');
    const [accountType, setAccountType] = useState('CLP')
    const [providerName,setProviderName] = useState('');
    const [contactName,setContactName] = useState('');
    const [phoneNumber,setPhoneNumber] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactCity,setContactCity] = useState('')
    const [contactAddress,setContactAddres]=useState('');
    const [accoundName,setAccoundName] = useState('');
    const [accountBank,setAccountBank] = useState('');
    const [accoundRutClp,setAccoundRutClp] = useState('');
    const [accoundTypeClp,setAccoundTypeClp] = useState('');
    const [accoundNumber,setAccoundNumber] = useState('');
    const [accoundSwiftUSD,setAccoundSwiftUSD] = useState('');
    const [accoundBankAddressUSD,setAccoundBankAddressUSD] = useState('');
    const [emailAccound,setEmailAccound] = useState('');

    const navigate = useNavigate();

    const handleCountryChange = (event) => {
        setCountryCode(event.target.value);
      };

    const handleAccoundTypeCLpChange = (event) => {
        setAccoundTypeClp(event.target.value);
      };
    const handleAccoundTypeChange = (event) =>{
        setAccountType(event.target.value)
    }

    const handleAccept = () =>{

    }

    const handleCancel = () =>{
        navigate(`/admin/products`);
    }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '75vh' }}>
    <Box sx={{ width: '80%', height: '100%' , display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Grid container spacing={2}>
            {/* Columna 1: 50% width */}
            <Grid item xs={6}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={3} sx={{  display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '80%', display: 'flex', justifyContent: 'center'}}>
                        <TextField label="Nombre del Proveedor" required variant="standard" fullWidth
                            value={providerName} autoComplete="off" onChange={(e) => setProviderName(e.target.value)} />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: '80%' }}>
                                <TextField label="Nombre del Contacto" required variant="standard" fullWidth
                                    value={contactName} autoComplete="off" onChange={(e) => setContactName(e.target.value)} />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                        <FormControl fullWidth variant="standard" sx={{ width:'80%'}}>
                            <InputLabel>País</InputLabel>
                            <Select value={countryCode} onChange={handleCountryChange} >
                                {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.name}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12} container>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ width: '80%' }}>
                        <TextField label="Número de Teléfono" required variant="standard" fullWidth
                            value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} autoComplete="off" InputProps={{ startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>
                            }}
                        />
                    </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                    <Box sx={{ width: '80%' }}>
                        <TextField label="Correo Electrónico" type="email"  required variant="standard" fullWidth
                            value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} autoComplete="off"/>
                        </Box>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid item xs={12} container>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ width: '80%' }}>
                            <TextField label="Ciudad" required variant="standard" fullWidth
                                value={contactCity} autoComplete="off" onChange={(e) => setContactCity(e.target.value)} />
                        </Box>

                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                        <Box sx={{ width: '80%' }}>
                            <TextField label="Dirección" required variant="standard" fullWidth
                                value={contactAddress} autoComplete="off" onChange={(e) => setContactAddres(e.target.value)} />
                        </Box>
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={6}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={2} sx={{  display: 'flex', justifyContent: 'center' }}>
                    <FormControl variant="standard" sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                    <InputLabel>Tipo de Cuenta Bancaria</InputLabel>
                    <Select value={accountType} onChange={handleAccoundTypeChange}>
                        {AccountTypes.map((type) => (
                        <MenuItem key={type.name} value={type.name}>
                            {type.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} sx={{  display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '80%',display: 'flex', justifyContent: 'center' }}>
                        <TextField label="Nombre del Contacto" required variant="standard" fullWidth  
                                value={accoundName}  autoComplete="off" onChange={(e) => setAccoundName(e.target.value)}/>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: '80%' }}>
                                <TextField label="Banco" required variant="standard" fullWidth
                                    value={accountBank}  autoComplete="off" onChange={(e) => setAccountBank(e.target.value)}/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                            {accountType==='CLP'? (
                                <FormControl fullWidth variant="standard" sx={{ width:'80%'}}>
                                    <InputLabel>Tipo de Cuenta</InputLabel>
                                    <Select value={accoundTypeClp} onChange={handleAccoundTypeCLpChange} >
                                        {TypesAccoundClp.map((type) => (
                                        <MenuItem key={type.name} value={type.name}>
                                            {type.name}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ):(
                                <Box sx={{ width: '80%' }}>
                                    <TextField label="SWIFT Code" required variant="standard" fullWidth value={accoundSwiftUSD}
                                        autoComplete="off"
                                        onChange={(e) => setAccoundSwiftUSD(e.target.value)}
                                        />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: '80%' }}>
                                <TextField label="Número de Cuenta Bancaria"   required  variant="standard" fullWidth value={accoundNumber} autoComplete="off" onChange={(e) => setAccoundNumber(e.target.value)}/>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                            {accountType==='CLP'? (
                                <Box sx={{ width: '80%' }}>
                                    <TextField label="RUT (Ej: 12.345.678-9)" required variant="standard" fullWidth value={accoundRutClp}
                                        autoComplete="off"
                                        onChange={(e) => setAccoundRutClp(e.target.value)}
                                        />
                                </Box>
                            ):(
                                <Box sx={{ width: '80%' }}>
                                    <TextField label="Dirección del banco"   required  variant="standard" fullWidth value={accoundBankAddressUSD} autoComplete="off" onChange={(e) => setAccoundBankAddressUSD(e.target.value)}/>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} sx={{  display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '80%', display: 'flex', justifyContent: 'center'}}>
                            <TextField label="Correo Electrónico" type="email"  required  variant="standard"
                                fullWidth value={emailAccound} autoComplete="off" onChange={(e) => setEmailAccound(e.target.value)}
                            />
                    </Box>
                </Grid>
            </Grid>
            </Grid>
            <Grid container item sx={{ height: '8%', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', marginRight: '15%' }}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleAccept}>
                    Aceptar
                    </Button>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" color="secondary" fullWidth onClick={handleCancel}>
                    Cancelar
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Box>
    </Box>

  );
};

export default ProviderForm;

import apiRepository from "../../../data/repository";
import GetPaymentPlantUsecase from "../../../domain/usecases/get_payment_plan_usecase";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { setPaymentPlans } from "../../redux/slices/company_payment_plan_slice";

export const GetPaymentPlantEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetPaymentPlantUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            dispatch(setPaymentPlans(data));
        }
    );
}

import GetAllContainersUsecase from "../../../domain/usecases/get_all_containers_usecase";
import { setContainers } from "../../redux/slices/admin_simple_container_slice";
import apiRepository from "../../../data/repository";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const GetAllContainersEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetAllContainersUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => null,
        (data) => {
            dispatch(setContainers(data));
            dispatch(setStatus(StatusLayoutEnum.Success));
        }
    );
}
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { SupplierContainerEntity } from "../../../../domain/entities";

interface PaymentModalProps {
  container: SupplierContainerEntity;
  paymentAmount: string;
  setPaymentAmount: (value: string) => void;
  open: boolean;
  onClose: () => void;
  onConfirmPayment: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  container,
  paymentAmount,
  setPaymentAmount,
  open,
  onClose,
  onConfirmPayment,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Realizar Pago para Contenedor {container?.id}</DialogTitle>
    <DialogContent>
      <TextField
        label="Monto de Pago"
        type="number"
        value={paymentAmount}
        onChange={(e) => setPaymentAmount(e.target.value)}
        fullWidth
        margin="normal"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button onClick={onConfirmPayment} color="primary">
        Confirmar Pago
      </Button>
    </DialogActions>
  </Dialog>
);

export default PaymentModal;

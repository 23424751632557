import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {MetricUnitEntity} from '../../../domain/entities/container';

export enum StatusMetricEnum {
    Initial = 'INITIAL',
    Loading = 'LOADING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export interface MetricState {
    metrics: MetricUnitEntity[];
    status: StatusMetricEnum;
}

const initialState = {
    metrics: [],
    status: StatusMetricEnum.Initial
} as MetricState;

const metricSlice = createSlice({
    name: 'metrics',
    initialState,
    reducers: {
        setMetrics(state, action: PayloadAction<MetricUnitEntity[] | []>) {
            state.metrics = action.payload
        },
        addMetric(state, action: PayloadAction<MetricUnitEntity>) {
            state.metrics.push(action.payload);
          },
        setStatuMetric(state, action: PayloadAction<StatusMetricEnum>) {
            state.status = action.payload
        }, 
        reset(state) {
            state.metrics = []
            state.status = StatusMetricEnum.Initial
        }
    },
})

export const { setMetrics, addMetric, setStatuMetric, reset } = metricSlice.actions
export default metricSlice.reducer

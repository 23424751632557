import React, { Fragment } from "react";
import OperationSection from "../components/home/operation_section";
import LandingSection from "../components/home/landing_section";
import CarouselSection from '../components/home/carousel';
import RegisterHome from '../components/home/register_landing_form';

const HomePage: React.FC = () => {

    return (
        <Fragment>
            <LandingSection />
            <OperationSection />
            <CarouselSection />
            <RegisterHome />
        </Fragment>
    );
};

export default HomePage;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvestmentEntity } from '../../../domain/entities';

export interface CompanyInvestmentsState {
  companyInvestments: InvestmentEntity[];
}

const initialState = {
  companyInvestments: [],
} as CompanyInvestmentsState;

const companyInvestmentsSlice = createSlice({
  name: 'company_investments',
  initialState,
  reducers: {
    setCompanyInvestments(state, action: PayloadAction<InvestmentEntity[]>) {
      state.companyInvestments = action.payload;
    },
  },
});

export const { setCompanyInvestments } = companyInvestmentsSlice.actions;
export default companyInvestmentsSlice.reducer;

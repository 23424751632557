import { StatusLayoutEnum, setMessage, setStatus } from "../../redux/slices/layout_slice";
import apiRepository from "../../../data/repository";
import PutPasswordUseCase from "../../../domain/usecases/put_password_usecase";

export const PutPasswordEvent = (params: {old_password: string,  new_password:string, confirm_password:string}) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new PutPasswordUseCase(apiRepository);

    const result = await usecase.execute(params);

    result.fold(
        (error) => {
            dispatch(setMessage(error.message));
            dispatch(setStatus(StatusLayoutEnum.Error));
        },
        () => {
            dispatch(setStatus(StatusLayoutEnum.Success));
        }
    );
}
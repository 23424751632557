import React, { useEffect } from "react";
import LoginForm from "../../components/home/login_form";

const LoginPage: React.FC = () => {
  useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      console.log("reloading");
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Espera 1 segundo antes de recargar.
    }
  }, []);

  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default LoginPage;

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Header from '../../../components/admin/layout/Header';
import SideMenu from '../../../components/admin/layout/SideMenu';
import { useParams } from 'react-router-dom';
import AdminContainer from '../../../components/admin/Importations/ContainerPage/Container';

const ContainerDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  
  console.log(id);
  return (
    <>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column', 
          }}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <AdminContainer/>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ContainerDetailPage;


import React , { useState,useEffect } from 'react';
import { Grid, Box,Container,Typography ,TextField,Button ,Dialog,Table, TableBody, TableCell, DialogContent, TableRow,Stack, DialogTitle,DialogActions } from '@mui/material';
import StatusProgressBar from './StatusProgressBar';
import { ContainerFullDetailEntity } from '../../../../../domain/entities';
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { Status } from '../../../../redux/slices/admin_container_slice';
import {PaymentsPercentage,ProductLabel} from './ImportationCardConten';
import { useNavigate } from 'react-router-dom';
import RedoIcon from '@mui/icons-material/Redo';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { AdminContainersModeEnum ,setMode,setSelectedContainer,setEditContainer,setAddMovement,setContainerMovement} from "../../../../redux/slices/admin_container_slice";
import CancelIcon from '@mui/icons-material/Cancel';
import { CreateContainerMovementEvent } from '../../../../events/admin/create_container_movement_event';
import { PutContainerMovementEvent} from '../../../../events/admin/put_container_movement_event';
import { format, toZonedTime } from 'date-fns-tz';
import {PutContainerEvent} from '../../../../events/admin/put_container_event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  container: ContainerFullDetailEntity;
}

const ImportacionCard: React.FC<Props> = ({container}) => {
  const timeZone = 'America/Santiago';
  const [estimateDate, setStimatedDate] = useState('');
  const [error, setError] = useState('');
  const {mode,movement} = useAppSelector((state) => state.admin_container);
  const [edit,setEdit] = useState(false);
  const [view,setView] = useState(false);
  const [tax, setTax] = useState(container.tax);
  const [fixedCost, setFixedCost] = useState(container.fixedCost);
  const [capacity,setCapacity] = useState(container.capacity);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTax(container.tax);
    setFixedCost(container.fixedCost);
    setCapacity(container.capacity);
  }, [container]);

  const convertToChileanTime = (date) => {
    const zonedDate = toZonedTime(date, timeZone);
    return format(zonedDate, 'yyyy-MM-dd', { timeZone });
  };

  useEffect(() => {
    container.containerMovements.map((movement)=>{
      const infinityDate = new Date(2899, 11, 31);
      const realDate = new Date(movement.realDate);
      if(realDate > infinityDate|| movement.step===1){
        const startDateChile = new Date(movement.createdAt);
        const endDate = new Date(movement.estimatedDate).toISOString().split('T')[0];
        setStartDate(convertToChileanTime(startDateChile));
        setEndDate(endDate);
        setStimatedDate(endDate);
        dispatch(setContainerMovement(movement));
        return;
      } 
    });
  }, [movement]);

  const handleTaxChange = (e) => {
    const value = e.target.value; 
    setTax(value);
  };

  const handleDateChange = (e) => {
    const selectedValue = e.target.value;
    const date = new Date(selectedValue);
    setStimatedDate(convertToChileanTime(date))
  };


  const handleCancelNextMovement =()=>{
    setView(false);
  }
  const handleCapacityChange = (e) => {
    const value = e.target.value;
    setCapacity(value);
  };

  const handleFixedCostChange = (e) => {
    const value = e.target.value;
    setFixedCost(value);
  };

  const handleVolver = () => {
    navigate(`/admin/container-clients/${container.id}`);
  };

  useEffect(() => {
    if (mode===AdminContainersModeEnum.Edit){
      setEdit(true);
    } else if (mode === AdminContainersModeEnum.ViewContainer) {
      setEdit(false);
    } else if (mode === AdminContainersModeEnum.AddProduct){
      setEdit(false);
    }
  }, [mode]);

  const handleEdit = () => {
    dispatch(setEditContainer());
  };
  const handleView = () => {
    dispatch(setMode(AdminContainersModeEnum.ViewContainer));
  };

  const handleSelectProduct = () => {
    dispatch(setSelectedContainer(container));
    dispatch(setMode(AdminContainersModeEnum.SelectProduct))
  }

  const handleChange = async ()=>{
      const taxNumber = parseFloat(String(tax).replace(',', '.'));
      const fixedCostNumber = parseFloat(String(fixedCost).replace(',', '.'));
      const capacityNumber = parseFloat(String(capacity).replace(',', '.'));
      const date = new Date(estimateDate); 
      
      const isTaxValid = !isNaN(taxNumber) && taxNumber > 0;
      const isFixedCostValid = !isNaN(fixedCostNumber) && fixedCostNumber > 0;
      const isCapacityValid = !isNaN(capacityNumber) && capacityNumber > 0;
      const start_date = new Date(startDate);
      if (isTaxValid && isFixedCostValid && isCapacityValid && date >=  start_date) {
        const actualmovement = await PutContainerMovementEvent(movement.id,estimateDate,movement.realDate,movement.status.id,container.id,movement.step);
        await PutContainerEvent(container.id,capacityNumber,fixedCost,tax,dispatch);
        dispatch(setContainerMovement(actualmovement));
        dispatch(setMode(AdminContainersModeEnum.ViewContainer));
        alert('Valores actualizados exitosamente');
      } else {
        alert('Valores no válidos. Por favor verifica los campos.');
      }
  }
  const handleOpenEstimateDate = ()=>{
    setView(true)
  }

  const handleNextMovement = async ()=>{
    const date = new Date(estimateDate); 
    if (!isNaN(date.getTime())) {
      const createdAtDate = new Date(startDate); 
      if (date >= createdAtDate) {
        const nowDate = new Date(); 
        nowDate.setHours(nowDate.getHours() - 5); 
        const nowISO = nowDate.toISOString();
        const infinityDate = new Date(2999, 11, 31, 17, 0).toISOString().slice(0, 19) + 'Z';
        await PutContainerMovementEvent(movement.id,movement.estimatedDate,nowISO,movement.status.id,container.id,movement.status.id);
        const new_movement = await CreateContainerMovementEvent(estimateDate,infinityDate,movement.step+1,container.id,movement.status.id+1,dispatch);
        dispatch(setAddMovement(new_movement));
        dispatch(setContainerMovement(new_movement));
        setStimatedDate('');
        setView(false);
      } else {
        alert('Fecha ingresada no Valida');
      }
    } else {
      alert('Fecha ingresada no Valida');
    }
  }
  return (    
    <Container>
      <Box sx={{  display: 'flex', justifyContent: 'center',  alignItems: 'center', height: '85vh'
      }}>
        <Grid container spacing={2} sx={{ height: '100%', width:'100%',
          border: '2px solid', boxShadow: '0px 4px 8px',
          padding: '7px',
          borderRadius: "4px"}}>
      
      {/* Barra de estados */}
      <Grid item xs={12} style={{ height: '12%'}}>
        {movement&&(<Box display="flex" justifyContent="center" alignItems="center">
          <StatusProgressBar activo={movement.step-1} />
        </Box>)}
      </Grid>
      
      {/* Datos del contenedor */}
      <Grid item xs={12} style={{ height: '80%' }}>
        <Grid container spacing={2} style={{ height: '100%' }}>
          {/* Datos de capacidad, costos etc y acciones*/}
          <Grid item xs={7.7} style={{ height: '100%' }}>
            <Grid container spacing={2} style={{ height: '100%' }}>
              {/* Fila de 60% */}
              <Grid item xs={12} style={{ height: '70%' }}>
                <Grid container spacing={2} style={{ height: '100%' }}>
                  <Grid item xs={7.6} style={{ height: '100%'}}>
                    <Grid item xs={12} style={{ height: '10%'}}>
                      <Box display="flex" justifyContent="center" alignItems="flex-start" style={{ height: '100%' }}>
                      <strong>{Status[container.status].description}</strong>
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={{ height: '90%', }}>
                      <Table>
                        <TableBody>    
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Impuesto</strong></TableCell>
                              { edit?(
                              <TableCell sx={{ padding: '5px',alignContent:'center' }} align="center">
                                <TextField
                                  value={tax}
                                  size="small"
                                  variant="outlined"
                                  label="Impuesto"
                                  onChange={handleTaxChange}
                                  style={{ fontSize: '0.8rem',padding: '0px',marginLeft: '15%'}}
                                  InputProps={{
                                    style: { padding: '0px', textAlign: 'center',height:'29px',fontSize: '0.8rem',width:'100%'},
                                    disableUnderline: true
                                  }}
                                />
                              </TableCell>):(
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(tax)}</TableCell>
                              )}
                            </TableRow>
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Costo Fijo</strong></TableCell>
                              { edit?(
                              <TableCell sx={{ padding: '5px',alignContent:'center' }} align="center">
                                <TextField
                                  value={fixedCost}
                                  size="small"
                                  variant="outlined"
                                  label="Costo Fijo"
                                  onChange={handleFixedCostChange}
                                  style={{ fontSize: '0.8rem',padding: '0px',marginLeft: '15%'}}
                                  InputProps={{
                                    style: { padding: '0px', textAlign: 'center',height:'29px',fontSize: '0.8rem',width:'100%'},
                                    disableUnderline: true
                                  }}
                                />
                              </TableCell>):(
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(fixedCost)}</TableCell>
                              )}
                            </TableRow>
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Capacidad</strong></TableCell>
                              { edit?(
                              <TableCell sx={{ padding: '5px',alignContent:'center' }} align="center">
                                <TextField
                                  value={capacity}
                                  size="small"
                                  variant="outlined"
                                  label="Capacidad"
                                  onChange={handleCapacityChange}
                                  style={{ fontSize: '0.8rem',padding: '0px',marginLeft: '15%'}}
                                  InputProps={{
                                    style: { padding: '0px', textAlign: 'center',height:'29px',fontSize: '0.8rem',width:'100%'},
                                    disableUnderline: true
                                  }}
                                />
                              </TableCell>):(
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(capacity)}</TableCell>
                              )}
                            </TableRow>
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Capacidad Usada:</strong></TableCell>
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(container.used_capacity)}%</TableCell>
                            </TableRow>
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Cantidad de Paquetes</strong></TableCell>
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(container.units_sold)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ height: '40px' }}>
                              <TableCell sx={{ padding: '8px' }}><strong>Unidades Totales</strong></TableCell>
                                <TableCell sx={{ padding: '4px' }} align="center">{new Intl.NumberFormat('es-CL').format(container.units_total)}</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                  <Grid item xs={4.4} style={{ height: '100%'}}>
                      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%', color: 'white' }}>
                      <Box display="flex" justifyContent="center" alignItems="center" style={{ padding:'4px', height: '60%', width:'80%' }}>
                        <Stack spacing={2} alignItems="center">
                          <Stack spacing={0.5} alignItems="center">
                            <Typography variant='body2' style={{ fontSize: '1rem' ,color: 'black'}}>Fecha de Inicio</Typography>
                            <TextField type="date" value={startDate} disabled={true} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined" size="small" sx={{ input: { color: 'black' ,textAlign: 'center'}, '& .MuiOutlinedInput-root': { borderColor: 'white' } }}/>
                          </Stack>
                          <Stack spacing={0.5} alignItems="center">
                            <Typography variant='body2' style={{ fontSize: '1rem',color: 'black' }}>Término de etapa</Typography>
                            {!edit?(<TextField type="date" disabled={true} value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined"  size="small" sx={{ input: { color: 'black',textAlign: 'center'  }, '& .MuiOutlinedInput-root': { borderColor: 'white' } }}
                            />):
                            (<TextField
                              id="date"
                              label="Fecha estimada"
                              type="date"
                              fullWidth
                              value={estimateDate}
                              onChange={handleDateChange}
                              
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={Boolean(error)} // Muestra el error si existe
                              helperText={error} // Muestra el mensaje de error
                            />)}
                          </Stack>
                        </Stack>
                      </Box>
                      </Box>
                  </Grid>
                </Grid>
              </Grid>
              {/* Fila de 20% */}
              <Grid item xs={12} style={{ height: '15%'}}>
               {/* <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '5px'
                  }}
                >
                  <PaymentsPercentage progress={20} />
                </Box> */}
              </Grid>
              {/* Fila de 20% */}
              <Grid item xs={12} style={{ height: '15%' }}>
              { edit?(<Grid container direction="row" justifyContent="center" alignItems="center"
                    style={{ height: '80%', padding: '0 16px', width:'100%' }} // Para separación con los bordes
                  >
                    
                     <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleView}
                      startIcon={<CancelIcon />}
                      style={{ width: '30%' }}
                    >
                      Cancelar
                    </Button>

                     <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleChange}
                      startIcon={<BeenhereIcon />}
                      style={{ width: '30%' ,marginLeft:'20%'}}
                    >
                      Guardar
                    </Button>
                
                </Grid>):(

                <Grid container direction="row" justifyContent="space-between" alignItems="center"
                    style={{ height: '90%', padding: '0 16px' }} // Para separación con los bordes
                  >
                    {/* Editar*/}
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleVolver}
                      startIcon={<ArrowBackIcon />}
                      style={{ width: '20%' }}
                    >
                      Volver
                    </Button>

                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleEdit}
                      startIcon={<EditIcon />}
                      style={{ width: '20%' }}
                    >
                      Editar
                    </Button>
                    {movement?.step !== 12 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<RedoIcon />}
                          style={{ width: '27%' }}
                          onClick={handleOpenEstimateDate}
                        >
                          Actualizar Estado
                        </Button>
                      )}
                       {movement?.step <= 5 && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<AddIcon />}
                            style={{ width: '27%' }}
                            onClick={handleSelectProduct}
                          >
                            Agregar Producto
                          </Button>
                    )}
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>

          {/* Sección de Productos */}
          <Grid item xs={4.3} style={{ height: '100%'}}>
            <Grid container direction="column" style={{ height: '100%' }}>
              {/* Titulo*/}
              <Grid  item
                style={{
                  height: '15%',
                  display: 'flex',          
                  justifyContent: 'center',    
                  alignItems: 'center',         
                }}
              >
                <strong>Productos</strong>
              </Grid>
              {/* Listado de Productos */}
              <Grid item style={{ height: '70%', overflowY: 'auto',  border: '1px solid black', borderRadius: '4px', padding: '10px'}}>
                {container.containerProducts.map((product) => (
                  <Box display="flex" alignItems="center" sx={{padding:'5px'}}>
                  <ProductLabel key={product.id} container_product={product} />
                  </Box>
                ))}
              </Grid>
              
              {/* Resumen de productos*/}
              {/* <Grid item style={{ height: '15%'}}>
                Resumen:
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
        </Grid>
         <Dialog open={view} onClose={handleCancelNextMovement}>
              <DialogTitle>Fecha estimada Próxima etapa</DialogTitle>
              <DialogContent
               sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center', 
                minHeight: '100px', 
              }}>

              <TextField
                id="date"
                label="Selecciona la fecha"
                type="date"
                fullWidth
                value={estimateDate}
                onChange={handleDateChange}
                
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(error)} // Muestra el error si existe
                helperText={error} // Muestra el mensaje de error
              /> 
              </DialogContent>
              <DialogActions>
              <Button onClick={handleCancelNextMovement} color="primary">
                  Cancelar
              </Button>
              <Button onClick={handleNextMovement} color="primary">
                  Aceptar
              </Button>
              </DialogActions>
          </Dialog>
      </Box>
    </Container>
  );
};

export default ImportacionCard;

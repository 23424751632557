import { Either } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { ContainerAdminInfoEntity } from "../entities";
import {IGetContainer} from '../../data/datasource/api';

export default class GetContainerAdminInfoUsecase{
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(params: IGetContainer): Promise<Either<ContainerAdminInfoEntity>> {
        const data = await this.repository.getSingleContainerInfo(params.id);

        return data;
    }
};
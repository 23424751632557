import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyEntity } from '../../../domain/entities/company/company_entity';

export enum OrderEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export interface CompanyState {
  companies: CompanyEntity[];
  order: OrderEnum;  // Agregamos el estado del orden
}

const initialState = {
  companies: [],
  order: OrderEnum.ASC,  // Iniciamos con el orden ascendente por defecto
} as CompanyState;

// Función para ordenar compañías por RUT (se ignora el dígito verificador)
export const sortCompaniesByRut = (companies: CompanyEntity[], order: OrderEnum) => {
  return companies.slice().sort((a, b) => {
    const rutA = parseInt(a.rut.split('-')[0].replace(/\./g, ''), 10);
    const rutB = parseInt(b.rut.split('-')[0].replace(/\./g, ''), 10);

    if (order === OrderEnum.ASC) {
      return rutA - rutB;
    } else {
      return rutB - rutA;
    }
  });
};


const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanies(state, action: PayloadAction<CompanyEntity[]>) {
      // Guardamos las compañías y aplicamos el orden actual
      state.companies = sortCompaniesByRut(action.payload, state.order);
    },
    setCompanyOrder(state, action: PayloadAction<OrderEnum>) {
      // Actualizamos el orden y reordenamos las compañías
      state.order = action.payload;
      state.companies = sortCompaniesByRut(state.companies, action.payload);
    }
  },
});

export const { setCompanies, setCompanyOrder } = companySlice.actions;
export default companySlice.reducer;

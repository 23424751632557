import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';


export default class PutPasswordUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: {old_password: string,  new_password:string, confirm_password:string}): Promise<Either<void>> {
        return this.repository.putPassword(
            params.old_password,
            params.new_password,
            params.confirm_password
        );
    }

};
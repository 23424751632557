import { setMetrics} from '../../redux/slices/metric_slice';
import apiRepository from "../../../data/repository";
import GetMetricsUseCase from '../../../domain/usecases/get_metrics_usecase';
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const GetMetricsEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetMetricsUseCase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));;
            dispatch(setMetrics(data));
        }
    );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyPaymentsEntity } from '../../../domain/entities/company_payments/company_payments_entity';

export interface CompanyPaymentsState {
  companyPayments: CompanyPaymentsEntity[];
}

const initialState = {
  companyPayments: [],
} as CompanyPaymentsState;

const companyPaymentsSlice = createSlice({
  name: 'company_payments',
  initialState,
  reducers: {
    setCompanyPayments(state, action: PayloadAction<CompanyPaymentsEntity[]>) {
      state.companyPayments = action.payload;
    },
  },
});

export const { setCompanyPayments } = companyPaymentsSlice.actions;
export default companyPaymentsSlice.reducer;



import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Grid, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { StatusUserEnum } from '../../redux/slices/user_slice';
import { LoginEvent } from '../../events/user/login_event';
import AppImages from '../../../theme/app_images';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { status } = useAppSelector(state => state.user);

  useEffect(() => {
    if (status === StatusUserEnum.Success) {
      navigate('/');
    } else if (status === StatusUserEnum.Error) {
      enqueueSnackbar('Login error', { variant: 'error', autoHideDuration: 2000 });
    }
    //eslint-disable-next-line
  }, [status]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(LoginEvent({ email, password }));
  };

  return (
    <Grid container>
      {/* Imagen */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100%',
            backgroundImage: `url(${AppImages.loginImage})`,
            backgroundSize: 'cover',
            // backgroundPosition: 'center',
            position: 'relative'
          }}
        >
        </Box>
      </Grid>

      {/* Formulario */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: 3
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            Iniciar sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {status === StatusUserEnum.Loading && <CircularProgress size={24} />}
            {status !== StatusUserEnum.Loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="submit"
                  style={{
                    width: '100%',
                    maxWidth: '150px',
                    borderRadius: '10px',
                    height: '40px',
                    backgroundColor: '#0A66A5',
                    color: 'white',
                    fontSize: '16px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Ingresar
                </Button>
              </Box>
            )}
            {status === StatusUserEnum.Error && <Typography color="error">Error al iniciar sesión</Typography>}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginForm;

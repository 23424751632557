import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  
  LinearProgress,
 
} from "@mui/material";
import { useParams } from "react-router-dom";

import { GetProformasEvent } from "../../events/proforma/get_proformas_event";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { ProformaEntity } from "../../../domain/entities";
import PaymentCard from "../../components/user_history/payment_card";
import ClarificationRequest from "../../components/user_history/clarification_request";

const EstadoDeCuenta: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { proformas }: { proformas: ProformaEntity[] } = useAppSelector(
    (state) => state.proformas
  );

  useEffect(() => {
    dispatch(GetProformasEvent());
  }, []);

  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.id === id && proforma.payments
  );

  const totalAmountPaid = proformasFiltradas[0]?.payments.reduce(
    (sumPayments, payment) => {
      const totalTransactionsAmount = payment.transactions.reduce(
        (sumTransactions, transaction) => {
          return sumTransactions + transaction.amount;
        },
        0
      );
      return sumPayments + totalTransactionsAmount;
    },
    0
  );

  return (
    <Container>
      <Typography
  variant="h4"
  component="h1"
  gutterBottom
  sx={{ mt: 4, fontWeight: "bold", color: "#0A66A5" }}
>
  Estado de cuenta
</Typography>

      {proformasFiltradas.length === 0 ? (
        <Typography variant="body1">No se encontraron proformas.</Typography>
      ) : (
        proformasFiltradas.map((proforma) => (
          <Box
            key={proforma.id}
            sx={{ display: "flex", flexDirection: "row", mb: 2 }}
          >
            <Box
              sx={{
                flex: 1,
                backgroundColor: "#f5f5f5", // Fondo gris claro
                padding: 2,
                borderRadius: "16px",
                height: "700px",
              }}
            >
              <img
                src={proforma.productImageUrl}
                alt="Producto"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "16px",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ color: "#0A66A5", mt: 1, fontWeight: "bold" }}
              >
                Paño amarillo multiuso
              </Typography>
              <Typography
                variant="body2"
                component="div"
                sx={{ color: "#0D74C0", mb: 2 }}
              >
                {`${proforma.productSize || "40 x 40 "} centimetros`} |{" "}
                {`${proforma.productWeight || "40"} gramos`}
                <br />
                {proforma.productMaterials || "85% polyester | 15% Polímero"}
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", color: "#0A66A5" }}
                >
                  Total transferido
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ color: "#0D74C0" }}
                >
                  ${totalAmountPaid.toLocaleString("es-CL")}{" "}
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{ color: "text.secondary" }}
                  >
                    / ${proforma.totalAmount.toLocaleString("es-CL")}
                  </Typography>
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(totalAmountPaid / proforma.totalAmount) * 100}
                  sx={{ mt: 1 }}
                />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mt: 1, textAlign: "center" }}
                >
                  {((totalAmountPaid / proforma.totalAmount) * 100).toFixed(0)}%
                  abonado
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flex: 2, pl: 15 }}>
              {proforma.payments &&
                proforma.payments.map((payment) => (
                  <PaymentCard key={payment.id} payment={payment} />
                ))}
              <ClarificationRequest proforma={proforma} />
            </Box>
          </Box>
        ))
      )}
    </Container>
  );
};

export default EstadoDeCuenta;

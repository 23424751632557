import React from 'react';
import { Modal, Typography, Box, Paper, Divider, LinearProgress, Grid, Button } from '@mui/material';
import { CompanyPaymentsEntity } from '../../../../domain/entities';
import PaymentDetailsModal from './PaymentDetailsModal';

interface CompanyPaymentsProps {
  payments: CompanyPaymentsEntity[];
  handleTransactionStatus: (transactionId: string, paymentId: string, status: number) => Promise<void>;
}

const CompanyPayments: React.FC<CompanyPaymentsProps> = ({ payments, handleTransactionStatus }) => {
  console.log("Payments: ", payments); // Verificas todos los pagos disponibles


  const [openModal, setOpenModal] = React.useState(false);
  const [currentProforma, setCurrentProforma] = React.useState(null);

  const handleOpenModal = (proforma) => {
    setCurrentProforma(proforma);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getStatusLabel = (status: number) => {
    switch (status) {
      case 1:
        return 'Aceptada';
      case 2:
        return 'Rechazada';
      default:
        return 'Pendiente';
    }
  };

  

  return (
    <Box sx={{ marginTop: '40px', textAlign: 'left', width: '100%' }}>
      {payments.map((payment) => {
        // Calcular el porcentaje pagado
        const paidPercentage = (payment.amount_paid / payment.total_amount) * 100;

        return (
          <Box key={payment.id} sx={{ marginBottom: '20px' }}>
            
            <Paper elevation={3} sx={{ padding: '20px', borderRadius: '8px', maxWidth: '800px', margin: 'auto' }}>
              <Typography variant="h5" sx={{ marginBottom: '20px', textAlign: 'center' }}>
                Pagos de la Compañía
              </Typography>
              <Typography variant="body1">Total a pagar: ${payment.total_amount}</Typography>
              <Typography variant="body1">Pagado: ${Math.trunc(payment.amount_paid)}</Typography>
              <Typography variant="body1">Deuda: ${Math.trunc(payment.debt)}</Typography>

              {/* Barra de progreso del porcentaje pagado */}
              <Box sx={{ width: '100%', maxWidth: '400px', margin: 'auto', marginTop: '16px' }}>
                <LinearProgress variant="determinate" value={paidPercentage} />
              </Box>

              {/* Texto centrado debajo de la barra de progreso */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Porcentaje Pagado: {paidPercentage.toFixed(2)}%
                </Typography>
              </Box>

              {/* Divider para separar la sección de proformas */}
              <Divider sx={{ marginY: '15px' }} />

              {/* Mostrar proformas asociadas en una grilla */}
              <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '15px' }}>
                Proformas:
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                {payment.proformas.map((proforma, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} onClick={() => handleOpenModal(proforma)}>
                    <Paper 
                      sx={{ 
                        padding: '10px', 
                        borderRadius: '8px', 
                        boxShadow: 1,
                        display: 'flex', // Asegura que el contenido se ajuste bien
                        flexDirection: 'column', // Organiza el contenido en columnas
                        height: '100%', // Hace que todos los cards sean del mismo tamaño
                        cursor: 'pointer', // Cambia el cursor para indicar que es clickeable
                      }}>
                      {/* Imagen del producto */}
                      {proforma.product_image_url && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                          <img
                            src={proforma.product_image_url}
                            alt={proforma.product_name}
                            style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'contain' }} // Ajusta el tamaño de la imagen
                          />
                        </Box>
                      )}
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{proforma.product_name}</Typography>
                      <Typography variant="body2">Cantidad: {proforma.quantity}</Typography>
                      <Typography variant="body2">Precio unitario: {proforma.price}</Typography>
                      <Typography variant="body2">Total a pagar: ${proforma.total_amount}</Typography>
                      <Typography variant="body2">Pagado: ${Math.trunc(proforma.amount_paid)}</Typography>
                      <Typography variant="body2">Deuda: ${Math.trunc(proforma.debt)}</Typography>
                      <Typography variant="body2">
                          Fecha límite: {new Date(proforma.proforma_deadline).toLocaleString('es-ES', { 
                          year: 'numeric', 
                          month: '2-digit', 
                          day: '2-digit', 
                          hour: '2-digit', 
                          minute: '2-digit' 
                        })}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Box>
        );
      })}
      {currentProforma && (
        <PaymentDetailsModal
          open={openModal}
          handleClose={handleCloseModal}
          proforma={currentProforma}
          handleTransactionStatus={handleTransactionStatus}
          getStatusLabel={getStatusLabel}
        />
      )}
    </Box>
  );
};

export default CompanyPayments;

import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { UserEntity } from "../entities/index";

export default class GetProfileUsecase implements Usecase<UserEntity> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<UserEntity>> {
        const data = await this.repository.getProfile();
        console.log(data);
        return data;
    }
};
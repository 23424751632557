import { Either } from "../../core/either";
import { ApiRepository } from "../repository/api";
import { ClarificationRequestEntity } from "../entities";

interface SendClarificationParams {
  id: string;
  proformaId: string;
  clientName: string;
  message: string;
  email: string;
  selectedTransaction: string;
}

export default class SendClarificationUseCase {
  private repository: ApiRepository;

  constructor(repository: ApiRepository) {
    this.repository = repository;
  }

  async execute(params: SendClarificationParams): Promise<Either<ClarificationRequestEntity>> {
    return this.repository.sendClarificationRequest(
      params.id,
      params.proformaId,
      params.clientName,
      params.message,
      params.email,
      params.selectedTransaction,
    );
  }
}


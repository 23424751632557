import React, { Fragment, } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';

import {
    Card,
    Checkbox,
    Divider,
    Grid,
    Slider,
    Typography
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SetProductFilterEvent } from '../../../events/filter/set_product_filter_event';
import { CategoryProductEnum } from '../../../../domain/entities';

const mapCategory = (category: CategoryProductEnum) => {
    switch (category) {
        case CategoryProductEnum.BATHROOM:
            return 'Baño';
        case CategoryProductEnum.CLOTHING:
            return 'Vestuario';
        case CategoryProductEnum.CONTRUCTION:
            return 'Construcción';
        case CategoryProductEnum.ELECTRONIC:
            return 'Electrónica';
        case CategoryProductEnum.FURNITURE:
            return 'Muebles';
        case CategoryProductEnum.KITCHEN:
            return 'Cocina';
        case CategoryProductEnum.OTHER:
            return 'Otros';
    }
}

const ProductFilter: React.FC = () => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.product);

    return (
        <Fragment>
            <Card elevation={5} sx={{
                margin: '10px',
                padding: '20px',
                borderRadius: '30px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant='h6'>
                            Filtros
                        </Typography>
                    </Grid>
                    <Grid item xs={2}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignContent: 'center',
                        }}
                    >
                        <FilterAltIcon color={'disabled'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Categorías
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} direction='column'>
                        {
                            Object.values(CategoryProductEnum).map((category) => (
                                <Grid key={category}>
                                    <Checkbox
                                        checked={filter.categories.includes(category)}
                                        onChange={(_, value) => {
                                            const categories = value ? [...filter.categories, category] :
                                                filter.categories.filter((c) => c !== category);
                                            dispatch(
                                                SetProductFilterEvent(
                                                    { ...filter, categories },
                                                ));

                                        }}
                                    />
                                    {mapCategory(category)}
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            Precio
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Slider
                            getAriaLabel={() => 'Temperature range'}
                            value={filter.range}
                            onChange={(_e, value) => dispatch(
                                SetProductFilterEvent(
                                    { ...filter, range: value as number[] },
                                ))}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `$${value}`}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Fragment >
    );
};

export default ProductFilter;
import React, { useState,useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Box, TextField, Button, IconButton,InputAdornment} from '@mui/material';
import { CategoryProductEnum } from '../../../../domain/entities';
import { CreateProductEvent } from '../../../events/admin/create_product_event';
import { PutProductEvent} from '../../../events/admin/put_product_event';
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { AdminProductsModeEnum,reset} from "../../../redux/slices/admin_products_slice";
import { addProduct, updateProduct } from '../../../redux/slices/product_slice';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import { useNavigate } from "react-router-dom";

const Proveedores = [
  { Id: "SUP001", nombre: 'Supplier 1' },
  { Id: "SUP002", nombre: 'Supplier 2' }
];

const ProductForm: React.FC = () => {
  const {mode,selectedProduct} = useAppSelector((state) => state.admin_products);
  const [id, setId] = useState('');
  const [isImageValid, setIsImageValid] = useState(true);
  const [name, setName] = useState('');
  const [price, setPrice] = useState(1);
  const [category, setCategory] = useState(CategoryProductEnum.BATHROOM);
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(''); 
  const [materials, setMaterials] = useState('');
  const [width,setWidth] = useState(1);
  const [length,setLength] = useState(1);
  const [depth,setDepth] = useState(1);
  const [weight,setWeight] = useState(1);
  const [selectedProveedor, setSelectedProveedor] = useState(Proveedores[0].Id);

  // const navigate = useNavigate();
  
  const handleProveedorChange = (event) => {
    setSelectedProveedor(event.target.value); 
  };

  // const handleCrearProvider = () => {
  //   navigate(`/admin/provider/form`);
  // };

  useEffect(() => {
      if (mode===AdminProductsModeEnum.Edit&&selectedProduct){
        setId(selectedProduct.id);
        setName(selectedProduct.name);
        setCategory(selectedProduct.category);
        setPrice(selectedProduct.price);
        setDescription(selectedProduct.description);
        setImageUrl(selectedProduct.imageUrl);
        setMaterials(selectedProduct.materials);
        setWidth(selectedProduct.width);
        setLength(selectedProduct.length);
        setDepth(selectedProduct.depth);
        setWeight(selectedProduct.weight);
        setSelectedProveedor(selectedProduct.supplier);

      } else if (mode === AdminProductsModeEnum.Create) {
        setId(uuidv4());
      }
  }, []);

  const dispatch = useAppDispatch();

  const handleImageError = () => {
    setIsImageValid(false); 
  };
  const handleImageLoad = () => {
    setIsImageValid(true); 
  }; 
  const handleImageUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
    setIsImageValid(true);
  };
  const handleVolver = () => {
    dispatch(reset());
  };
  const handleSubmitCrear = async (e: React.FormEvent) => {
    e.preventDefault();
    if (price<=0||width<=0||length<=0||depth<=0||weight<=0) {
        alert("Datos no Validos");
        return;
      }
    const product = await CreateProductEvent(id, name, price,category,description,width, length, depth, weight,materials,imageUrl,selectedProveedor);
    dispatch(addProduct(product));
    handleVolver()
  };

  const handleSubmitEditar = async (e: React.FormEvent) => {
    e.preventDefault();

    if (price<=0||width<=0||length<=0||depth<=0||weight<=0) {
      alert("Datos no Validos");
      return;
    }

    const product = await PutProductEvent(id, name, price,category,description,width, length, depth, weight,materials,imageUrl,selectedProveedor);
    dispatch(updateProduct(product));
    handleVolver()
  };

  return (
    <Box sx={{ width: '80%', height: '80vh' }}>
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '15%' ,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '80%' }}>
            <TextField
              label="Nombre"
              required
              variant="standard"
              fullWidth
              value={name}
              autoComplete="off"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
      </Grid>

      <Grid container item xs={12} sx={{ height: '85%' }}>
        <Grid item xs={8} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{ width: '90%', height: '100%'}}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item   spacing={2} sx={{ height: '15%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  label="Materiales"
                  variant="outlined"
                  required
                  fullWidth
                  value={materials}
                  onChange={(e) => setMaterials(e.target.value)}
                  autoComplete="off"
                />
                <TextField label="Peso"  variant="outlined"  required  fullWidth  type="number"
                  value={weight} InputProps={{
                    endAdornment: <InputAdornment position="end">gr</InputAdornment>,
                    inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                  }}
                  onChange={(e) => setWeight(Number(e.target.value))}
                  autoComplete="off"
                  sx={{ marginLeft: '16px', 'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                      display: 'none' }, 'input[type=number]': { MozAppearance: 'textfield'},
                  }}
                />

              </Grid>
              <Grid item spacing={2} sx={{ height: '15%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <TextField label="Ancho"  variant="outlined"  required  fullWidth  type="number"
                  value={width} InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                  }}
                  onChange={(e) => setWidth(Number(e.target.value))}
                  autoComplete="off"
                  sx={{ 'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                      display: 'none' }, 'input[type=number]': { MozAppearance: 'textfield'},
                  }}
                />
                <TextField label="Largo"  variant="outlined"  required  fullWidth  type="number"
                  value={length} InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                  }}
                  onChange={(e) => setLength(Number(e.target.value))}
                  autoComplete="off"
                  sx={{ marginLeft: '16px', 'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                      display: 'none' }, 'input[type=number]': { MozAppearance: 'textfield'},
                  }}
                />
                <TextField label="Alto"  variant="outlined"  required  fullWidth  type="number"
                  value={depth} InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                  }}
                  onChange={(e) => setDepth(Number(e.target.value))}
                  autoComplete="off"
                  sx={{ marginLeft: '16px', 'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                      display: 'none' }, 'input[type=number]': { MozAppearance: 'textfield'},
                  }}
                />
              </Grid>
              <Grid item sx={{ height: '20%'}}>
              <Grid item xs={12} sx={{ height: '20%'}}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                  <Grid item xs={8} sx={{ height: '100%' }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Categoría"
                      value={category}
                      onChange={(e) => setCategory(e.target.value as CategoryProductEnum)}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Object.values(CategoryProductEnum).map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={4} sx={{ height: '100%' }}>
                    <TextField  margin="normal" label="Precio (USD)"  variant="outlined"  required  fullWidth  type="number"
                      value={price} InputProps={{
                        inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
                      }}
                      onChange={(e) => setPrice(Number(e.target.value))}
                      autoComplete="off"
                      sx={{  'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                          display: 'none' }, 'input[type=number]': { MozAppearance: 'textfield'},
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              </Grid>
              <Grid item sx={{ height: '50%'}}>
                <TextField
                  label="Descripción"
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  autoComplete="off"
                  value={description}
                  rows={7}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={4} sx={{ height: '100%' }}>
          <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid item sx={{ height: '50%'}}>
              <Box sx={{alignContent:'center',display: 'flex',alignItems:'center',height: '100%',Width: '100%'}}>
              <img
                  src={isImageValid && imageUrl ? imageUrl : 'https://cdn.icon-icons.com/icons2/3001/PNG/512/default_filetype_file_empty_document_icon_187718.png'}
                  onError={handleImageError}
                  onLoad={handleImageLoad}
                  alt="Cargada"
                  style={{ maxWidth: '100%', maxHeight: '100%',marginLeft:'10%', objectFit: 'contain', borderRadius: '8px', padding: '10px' }}
                />
              </Box>
            </Grid>
            <Grid item sx={{ height: '15%'}}>
              <TextField
                label="URL de Imagen"
                variant="outlined"
                required
                fullWidth
                autoComplete="off"
                value={imageUrl}
                onChange={handleImageUrlChange}
              />
            </Grid>
            <Grid item sx={{ height: '15%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <TextField
                        margin="normal"
                        required
                        style={{ width: '100%' }}
                        label="Proveedor"
                        select
                        value={selectedProveedor}
                        onChange={handleProveedorChange}
                        SelectProps={{
                            native: true,
                        }}
                        >
                            {Proveedores.map((proveedor) => (

                    <option key={proveedor.Id} value={proveedor.Id}>
                    {proveedor.nombre}
                    </option>
                  ))}

                </TextField>
                </Box>
                {/* <IconButton color="primary"  onClick={handleCrearProvider}  style={{ marginLeft: '10px' }}><AddBoxIcon />
                </IconButton> */}
            </Grid>
            <Grid item sx={{ height: '10%'}}>
              <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={6}>
                  { mode===AdminProductsModeEnum.Create && <Button variant="contained" onClick={handleSubmitCrear} fullWidth>
                    Crear
                  </Button>}
                  { mode===AdminProductsModeEnum.Edit && <Button variant="contained" onClick={handleSubmitEditar} fullWidth>
                    Guardar
                  </Button>}
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={handleVolver}fullWidth>
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
  );
};

export default ProductForm;

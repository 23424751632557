// ProviderTable.tsx
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { SupplierEntity } from "../../../../domain/entities";

interface ProviderTableProps {
  suppliers: SupplierEntity[];
  onSelectSupplier: (supplier: SupplierEntity) => void;
}

const ProviderTable: React.FC<ProviderTableProps> = ({
  suppliers,
  onSelectSupplier,
}) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  // Estado para el ordenamiento
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof SupplierEntity | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Cambia el ordenamiento de una columna
  const handleRequestSort = (property: keyof SupplierEntity) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Aplica el ordenamiento a los proveedores
  const sortedSuppliers = [...suppliers].sort((a, b) => {
    if (orderBy) {
      if (a[orderBy] < b[orderBy]) return order === "asc" ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Calcula el índice de inicio y fin de los proveedores en la página actual
  const paginatedSuppliers = sortedSuppliers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleRequestSort("name")}
              >
                Nombre
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              <TableSortLabel
                active={orderBy === "contactName"}
                direction={orderBy === "contactName" ? order : "asc"}
                onClick={() => handleRequestSort("contactName")}
              >
                Contacto
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              <TableSortLabel
                active={orderBy === "phone"}
                direction={orderBy === "phone" ? order : "asc"}
                onClick={() => handleRequestSort("phone")}
              >
                Teléfono
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>
              <TableSortLabel
                active={orderBy === "country"}
                direction={orderBy === "country" ? order : "asc"}
                onClick={() => handleRequestSort("country")}
              >
                País
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {paginatedSuppliers.map((supplier) => (
            <TableRow
              key={supplier.id}
              hover
              onClick={() => onSelectSupplier(supplier)}
              style={{ cursor: "pointer" }}
            >
              <TableCell>{supplier.name}</TableCell>
              <TableCell>{supplier.contactName}</TableCell>
              <TableCell>{supplier.phone}</TableCell>
              <TableCell>{supplier.country}</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={suppliers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ProviderTable;

import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { SupplierContainerEntity } from "../../../../domain/entities";

interface ContainerListProps {
  containers: SupplierContainerEntity[];
  onSelectContainer: (container: SupplierContainerEntity) => void;
}

const ContainerList: React.FC<ContainerListProps> = ({ containers, onSelectContainer }) => (
  <>
    <Typography variant="h6" align="center">
      Containers
    </Typography>
    <List
      style={{ maxHeight: 300, overflowY: "auto" }}
      aria-labelledby="container-list-title"  // Mejora de accesibilidad
    >
      {containers.map((container, index) => (
        <ListItem
          button
          key={index}
          onClick={() => onSelectContainer(container)}
          sx={{ paddingBottom: "10px" }}
          aria-label={`Seleccionar contenedor con id ${container.id}`}  // Agregar accesibilidad para los lectores de pantalla
        >
          <ListItemText
            primary={`Contenedor id: ${container.id || "Sin ID"}`}
            secondary={`Estado: ${container.status || "No disponible"}`}
          />
        </ListItem>
      ))}
    </List>
  </>
);

export default ContainerList;

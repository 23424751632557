import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";

interface Container {
  total_amount: number;
}

interface ContainersTableProps {
  containers: Record<string, Container>;
}

const ContainersTable: React.FC<ContainersTableProps> = ({ containers }) => {
    const formatAmount = (amount: number) =>
        new Intl.NumberFormat("es-CL").format(Math.trunc(amount));
  return (
    <Box
    //   sx={{
    //     padding: 2,
    //     margin: "20px 40px 20px 30px",
    //     bgcolor: "background.paper",
    //     boxShadow: 3,
    //     borderRadius: "8px",
    //     width: "100%",
    //     maxWidth: "75%",
    //     overflowY: "auto",
    //     maxHeight: "400px",
    //   }}
    sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px",
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography variant="h5" sx={{ color: "#0A66A5", marginBottom: 2, fontWeight: "bold" }}>
        Contenedores
      </Typography>
      <Box
        sx={{
            width: "100%",
            overflowY: "auto",
            maxHeight: "400px",
          }}
      >
      <Grid container spacing={2}>
        {Object.entries(containers).map(
          ([containerId, { total_amount }], index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  padding: "15px",
                  borderRadius: "7px",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 600 }}>
                  Contenedor ID: {containerId}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Monto total: ${formatAmount(total_amount)}
                </Typography>
              </Card>
            </Grid>
          )
        )}
      </Grid>
      </Box>
    </Box>
  );
};

export default ContainersTable;

import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { ContainerAdminEntity } from "../entities";

export default class GetAllContainersUsecase implements Usecase<ContainerAdminEntity[]> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<ContainerAdminEntity[]>> {
        const data = await this.repository.getAllContainers();

        return data;
    }
};
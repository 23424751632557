import GetContainerInfo from "../../../domain/usecases/get_container_admin_info_usecase";
import { setSingleContainer } from "../../redux/slices/admin_simple_container_slice";
import apiRepository from "../../../data/repository";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const GetContainerAdminInfoEvent = (id: string) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetContainerInfo(apiRepository);

    const result = await usecase.execute({id: id});

    result.fold(
        () => null,
        (data) => {
            dispatch(setSingleContainer({ id, container: data }));
        }
    );
}
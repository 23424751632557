import React from "react";
import ProductDetail from "../../components/products/detail_page/main_page";
import { Container } from "@mui/material";
import { useParams } from 'react-router-dom';

const ProductDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    return (
        <Container>
            <ProductDetail product_id={id}/>
        </Container>
    );
};

export default ProductDetailPage;
import React, { Fragment, useState, ChangeEvent } from "react";
import { PaymentEntity } from "../../../domain/entities";
import {
  Card,
  Typography,
  Grid,
  Button,
  TextField,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import { isAfter, isBefore } from "date-fns"; // Asegúrate de importar isAfter y isBefore para manejar fechas
import TransactionList from "./transaction_list"; // Importamos el nuevo componente
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { useAppDispatch } from "../../hooks/hooks";
import { CreateTransactionEvent } from "../../events/proforma/create_transaction_event";
import { addTransactionToPayment } from "../../redux/slices/proforma_slice";

interface PaymentCardProps {
  payment: PaymentEntity;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ payment }) => {
  const dispatch = useAppDispatch();
  //const [isSuccess, setIsSuccess] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState<number | string>(
    ""
  ); // Nuevo estado para el monto de la transacción

  const getStatusText = (status: number) => {
    switch (status) {
      case 0:
        return "Pendiente";
      case 1:
        return "Aprobado";
      case 2:
        return "Rechazado";
      case 3:
        return "Failed";
      default:
        return "Unknown";
    }
  };

  // Calculamos la suma de las transacciones
  const totalTransactionAmount = payment.transactions.reduce(
    (total, transaction) => total + transaction.amount,
    0
  );

  const [file, setFile] = useState<Blob | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Favor seleccione un archivo en formato PDF valido.");
    }
  };
  const handleGenerateOrder = () => {
    if (!file) {
      alert("Favor seleccione un archivo.");
      return;
    }

    dispatch(setStatus(StatusLayoutEnum.Loading));

    CreateTransactionEvent(file, payment.id, Number(transactionAmount))
      .then((transaction) => {
        console.log("Transaction generated successfully ", transaction);

        // Despacha la acción para actualizar el estado global
        dispatch(
          addTransactionToPayment({ paymentId: payment.id, transaction })
        );

        // Limpiar el formulario después de la transacción exitosa
        setTransactionAmount(""); // Limpia el monto de la transacción
        setFile(null); // Limpia el archivo seleccionado
        // Opcional: limpiar el input de archivo manualmente si es necesario
        const fileInput = document.getElementById(
          `file-input-${payment.id}`
        ) as HTMLInputElement;
        if (fileInput) {
          fileInput.value = ""; // Limpia el valor del input de archivo
        }
      })
      .finally(() => {
        dispatch(setStatus(StatusLayoutEnum.Success));
      });
  };

  // Verificar las condiciones para mostrar la bandera de estado del pago
  const currentDate = new Date();
  const cleanDate = (dateString) => {
    // Extraemos solo la fecha, eliminando el texto adicional.
    const [date] = dateString.split(" a las");
    return new Date(date.trim()); // Convertimos la fecha en un objeto Date.
  };

  const isBetweenDueAndDeadline =
    isBefore(currentDate, cleanDate(payment.deadline)) &&
    isAfter(currentDate, cleanDate(payment.due_date));

  const isPastDeadline = isAfter(currentDate, cleanDate(payment.deadline));
  const formatAmount = (amount) => {
    // Elimina puntos, convierte a número y luego aplica el formato con Intl.NumberFormat
    const numericAmount = Number(amount.toString().replace(/\./g, ""));
    return isNaN(numericAmount)
      ? amount
      : new Intl.NumberFormat("es-CL").format(numericAmount);
  };
  return (
    <Fragment>
      <Card
        elevation={3}
        sx={{
          padding: "16px",
          marginBottom: "10px",
          borderRadius: "8px",
          position: "relative", // Necesario para posicionar el porcentaje
        }}
      >
        {/* Mostrar el porcentaje del pago en la esquina superior derecha */}
        <Box sx={{ position: "absolute", top: "16px", right: "16px" }}>
          <Typography variant="body2" sx={{ color: "GrayText" }}>
            {Math.round(payment.percentage * 100)}%
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 600 }}>
          ID de Pago: {payment.id}{" "}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          Monto: $ {formatAmount(payment.amount.toFixed(0))} USD
        </Typography>
        <Box sx={{ mb: 1 }} />{" "}
        <Typography variant="body2">
          Monto Neto del Plan de Pago: ${" "}
          {formatAmount(
            (payment.amount - payment.overpayment_amount).toFixed(0)
          )}{" "}
          USD
        </Typography>
        <Typography variant="body2">
          Total Pagado: $ {formatAmount(totalTransactionAmount)} USD
        </Typography>
        <Typography variant="body2" sx={{ color: "GrayText" }}>
          Status: {getStatusText(payment.status)}
        </Typography>
        {/*  */}
        {/* Mostrar bandera según las condiciones */}
        <Box sx={{ marginTop: "16px" }}>
          {payment.amount > totalTransactionAmount &&
            isBetweenDueAndDeadline && (
              <Chip label="Pendiente de Pago" color="warning" />
            )}
          {payment.amount > totalTransactionAmount && isPastDeadline && (
            <Chip label="Pago Expirado" color="error" />
          )}
          {payment.amount <= totalTransactionAmount && (
            <Chip label="Pago Completado" color="success" />
          )}
        </Box>
        {/* Aquí agregamos la lista de transacciones */}
        <TransactionList payment={payment} />
        {/* Añadimos un divisor con espacio */}
        <Divider sx={{ marginY: 4 }} />
        {/* Sección separada para agregar nuevas transacciones */}
        {(totalTransactionAmount >= payment.amount ||
          totalTransactionAmount <= payment.amount) && (
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#0A66A5", fontWeight: 570 }}>
              Agregar Nueva Transacción
            </Typography>
            <Box sx={{ mb: 1 }} />{" "}
            <Grid container spacing={2}>
              {/* Campo para ingresar el monto de la transacción */}
              <Grid item xs={12}>
                <TextField
                  label="Monto de la Transacción"
                  type="number"
                  fullWidth
                  value={transactionAmount}
                  onChange={(e) => setTransactionAmount(e.target.value)}
                  variant="outlined"
                />
              </Grid>

              {/* Formulario para subir comprobante */}
              <Grid item container justifyContent="end" spacing={2}>
                <Grid item>
                  <form>
                    <input
                      accept="application/pdf"
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        {file ? "Cambiar archivo" : "Seleccionar archivo"}
                      </Button>
                    </label>
                  </form>
                </Grid>

                <Grid item>
                  <Button
                    disabled={!file || !transactionAmount}
                    variant="contained"
                    color="success"
                    onClick={handleGenerateOrder}
                  >
                    Hacer Abono
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Card>
    </Fragment>
  );
};

export default PaymentCard;

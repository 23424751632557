import { Either } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { FullProductEntity } from "../entities";

export default class GetProductFullDetailUsecase{
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }
    async execute(product_id:string): Promise<Either<FullProductEntity>> {
        const data = await this.repository.getProductFullDetail(product_id);

        return data;
    }
};
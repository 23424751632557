import React from 'react';
import { Box, Button, Typography, Container } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 
import AppImages from '../../../theme/app_images';

const Background = styled(Box)({
  width: '100vw',
  height: '90vh',
  position: 'relative',
  paddingY: 0,
  marginTop: 0,
});

const Content = styled(Container)({
  textAlign: 'left',
  position: 'relative',
  top: '123px',
  zIndex: 2,
});

const StyledButton = styled(Button)({
  display: 'flex',
  width: '40%',
  height: '50px',
  padding: '16.5px 0 17.5px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px', 
  background: '#0A66A5',
  color: '#fff',
  marginTop: '50px',
  textTransform: 'none', 
  fontSize: '30px',
  fontWeight: 500, 
});

const LargeText = styled(Typography)({
  fontSize: '48px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '58.09px',
  textAlign: 'left',
});

const SmallText = styled(Typography)({
  color: '#000',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
});

const ImageContainer = styled(Box)({
  position: 'absolute',
  top: '0', 
  right: '0',
  bottom: '0',
  left: '0',
  background: `url(${AppImages.banner}) no-repeat center center`, 
  backgroundSize: 'cover', 
  zIndex: 1,
});

const LandingSection = () => {
  const navigate = useNavigate(); 

  const handleJoinClick = () => {
    navigate('/register');
  };

  return (
    <Background>
      <Content maxWidth="lg">
        <LargeText>Somos la fuerza de compra</LargeText>
        <LargeText>que necesitan las pymes</LargeText>
        <SmallText>
          Únete hoy a la red más grande de comercios minoristas
        </SmallText>
        <SmallText> para la importación en Chile. Tu pyme importa.</SmallText>
        <StyledButton onClick={handleJoinClick}>Únete</StyledButton>
      </Content>
      <ImageContainer />
    </Background>
  );
};

export default LandingSection;


import React, { useState } from 'react';
import { Grid, Button,  TableContainer, TableRow, TableHead, Table, TableCell, TableBody, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';


const ContainerLayout = ({contenedor}) => {
  console.log(contenedor);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [amount, setAmount] = useState('');
  const [showTables, setShowTables] = useState(false);

  const handleToggle = () => {
    setShowTables((prev) => !prev);
  };

  const today = new Date().toISOString().split('T')[0];
  const [date, setDate] = useState(today);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleFormSubmit = () => {
    console.log('Nuevo Pago:', { selectedCategory, amount, date });
    setSelectedCategory('');
    setAmount('');
    setDate(today);
    handleDialogClose();
  };
  
  function formatDate(dateString) {
    const formattedString = dateString.replace(" a las ", "T");
    const date = new Date(formattedString);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric'};
      return new Intl.DateTimeFormat('es-ES', options).format(date);
  }

  function formatDateISO(dateString) {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric'};
    return new Intl.DateTimeFormat('es-ES', options).format(date);
  }

  const utilidad = contenedor.received_amount-contenedor.customs_amount-contenedor.china_amount;
  const estados = ["Pendiente","Listo","Rechazado",];
  return (
    <Grid container style={{  width: '100%' }}>
      {/* Fila superior */}
      <Grid item xs={12} style={{ height: '50px' , borderBottom: '1px solid #ccc' }}>
        <Grid container>
          <Grid item xs={1}>
            <Button 
              onClick={handleToggle} 
              startIcon={showTables ? <ExpandLessIcon style={{ fontSize: "30px" }} /> : <ExpandMoreIcon style={{ fontSize: "30px" }} />}
              sx={{ minHeight: '35px', padding: '0' }} 
            />
          </Grid>
          <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center'}}>
              <Typography variant="body1" sx={{ fontWeight: 'bold'}} >
                {contenedor.id}
              </Typography>
          </Grid>
          <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant="body1">
               ${contenedor.received_amount.toLocaleString('es-ES')}
            </Typography>
          </Grid>
          <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant="body1" >
              ${contenedor.china_amount.toLocaleString('es-ES')}
            </Typography>
          </Grid>
          <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant="body1" >
              ${contenedor.customs_amount.toLocaleString('es-ES')}
            </Typography>
          </Grid>
          <Grid item xs={2.2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant="body1"sx={{  color: utilidad < 0 ? 'red' : '#0A66A5'}} >
              {utilidad < 0 ? `-$${Math.abs(utilidad).toLocaleString('es-ES')}` : `$${utilidad.toLocaleString('es-ES')}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
          {showTables && (
            <>
              <Grid item xs={12}>
                <Grid container item sx={{ marginBottom:'10px'}}>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '10px' }}>
                  <Typography variant="body2"sx={{ fontWeight: 'bold', color: '#0A66A5',padding:'5px',marginLeft:'15px'}} >
                      Abonos
                    </Typography>
                    
                    <TableContainer sx={{ maxHeight: '100%', width: '90%' }}>
                      <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 0px', width: '100%' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Cliente</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Fecha</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Monto</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Estado</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {contenedor.container_payments.map((container_payment) => (
                                container_payment.payments.map((payment, index) => (
                                  payment.transactions.map((transaction, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{payment.company}</TableCell>
                                  <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{formatDate(payment.due_date)}</TableCell>
                                  <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{transaction.amount.toLocaleString('es-ES')}</TableCell>
                                  <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none', color: transaction.status === 2 ? 'red' : transaction.status === 0 ? '#e7bb00' : transaction.status === 1 ? 'green' : 'inherit' }}>
                                    <strong>{estados[transaction.status]}</strong>
                                  </TableCell>
                                </TableRow>
                                  ))
                                ))
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={5.8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="body2"sx={{ fontWeight: 'bold', color: '#0A66A5',padding:'5px',marginLeft:'15px'}} >
                      Pagos
                    </Typography>
                      <Grid container item sx={{ alignItems: 'center' }}>
                        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <TableContainer sx={{ maxHeight: '100%', width: '100%' }}>
                            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0px 0px', width: '100%' }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Categoría</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Fecha</TableCell>
                                  <TableCell sx={{ fontWeight: 'bold', padding: '2px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>Monto</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {contenedor.external_payments.map((external_payment) => (
                                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{external_payment.supplier}</TableCell>
                                    <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{formatDateISO(external_payment.created_at)}</TableCell>
                                    <TableCell sx={{ padding: '1px', textAlign: 'center', backgroundColor: '#f0f0f0', border: 'none' }}>{external_payment.amount.toLocaleString('es-ES')}</TableCell>
                                  </TableRow>
                                ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        {/* <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}> 
                          <Button variant="outlined" startIcon={<AddIcon />} onClick={handleDialogOpen}>
                            Agregar
                          </Button>
                        </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
             </Grid>
            </>
          )}

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Agregar Nuevo Pago</DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <TextField
                  select
                  label="Categoría"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  fullWidth
                  margin="dense"
                  sx={{ width: '80%' }}
                >
                  <MenuItem value="Internación">Internación</MenuItem>
                  <MenuItem value="Traslado">Traslado</MenuItem>
                  <MenuItem value="Logístico">Logístico</MenuItem>
                  <MenuItem value="Otros">Otro</MenuItem>
                </TextField>
                
                <TextField
                  label="Monto"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  fullWidth
                  margin="dense"
                  sx={{ width: '80%' }}
                />
                
                <TextField
                  label="Fecha"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  fullWidth
                  margin="dense"
                  inputProps={{ max: today }}
                  sx={{ width: '80%' }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancelar</Button>
              <Button onClick={handleFormSubmit}>Agregar</Button>
            </DialogActions>
          </Dialog>

      </Grid>
  );
};

export default ContainerLayout;

// src/components/ContainerList.tsx
import React, { Fragment, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { Checkbox, Grid, Slider, Typography, Menu, MenuItem } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SetProductFilterEvent } from '../../../events/filter/set_product_filter_event';
import { CategoryProductEnum } from '../../../../domain/entities';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { AdminProductsModeEnum } from "../../../redux/slices/admin_products_slice";
import { setMode } from '../../../redux/slices/admin_products_slice';

const mapCategory = (category: CategoryProductEnum) => {
    switch (category) {
        case CategoryProductEnum.BATHROOM:
            return 'Baño';
        case CategoryProductEnum.CLOTHING:
            return 'Vestuario';
        case CategoryProductEnum.CONTRUCTION:
            return 'Construcción';
        case CategoryProductEnum.ELECTRONIC:
            return 'Electrónica';
        case CategoryProductEnum.FURNITURE:
            return 'Muebles';
        case CategoryProductEnum.KITCHEN:
            return 'Cocina';
        case CategoryProductEnum.OTHER:
            return 'Otros';
    }
}

const ProducsNavBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.product);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleAddProduct = () =>{
        dispatch(setMode(AdminProductsModeEnum.Create))
    }

    return (
        <Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ boxShadow: 'none' }}>
                    <Toolbar variant="dense">
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleMenuOpen}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                            Productos
                        </Typography>
                        <Button variant="contained" onClick={handleAddProduct} startIcon={<AddIcon />}
                        sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#388e3c',
                            },
                          }}>
                            Añadir Producto
                        </Button>
                    </Toolbar>
                </AppBar>
                <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                >
                    <MenuItem>
                        <Typography variant='h6'>Categorías</Typography>
                    </MenuItem>
                    <MenuItem>
                        <Grid container direction='column'>
                            {Object.values(CategoryProductEnum).map((category) => (
                                <Grid key={category}>
                                    <Checkbox
                                        checked={filter.categories.includes(category)}
                                        onChange={(_, value) => {
                                            const categories = value
                                                ? [...filter.categories, category]
                                                : filter.categories.filter((c) => c !== category);
                                            dispatch(SetProductFilterEvent({ ...filter, categories }));
                                        }}
                                    />
                                    {mapCategory(category)}
                                </Grid>
                            ))}
                        </Grid>
                    </MenuItem>
                    <MenuItem>
                        <Typography variant='h6'>Precio</Typography>
                    </MenuItem>
                    <MenuItem>
                        <Slider
                            getAriaLabel={() => 'Rango de precio'}
                            value={filter.range}
                            onChange={(_e, value) => dispatch(
                                SetProductFilterEvent(
                                    { ...filter, range: value as number[] }
                                )
                            )}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `$${value}`}
                            min={0}
                            max={1000}
                        />
                    </MenuItem>
                </Menu>
            </Box>
        </Fragment>
    );
};

export default ProducsNavBar;

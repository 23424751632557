import { StatusEntity } from "../container/status";

export interface ContainerMovementEntityJson {
  id: string;
  status: StatusEntity;
  is_completed: boolean;
  created_at: string;
  updated_at: string;
}

export interface ContainerMovementEntity {
  id: string;
  status: StatusEntity;
  is_completed: boolean;
  createdAt: string;
  updatedAt: string;
}



export function mapJsonToContainerMovementEntity(json: ContainerMovementEntityJson): ContainerMovementEntity {
  return {
    id: json.id,
    status: json.status,
    is_completed: json.is_completed,
    createdAt: json.created_at,
    updatedAt: json.updated_at,
  };
}

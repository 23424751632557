import { StatusLayoutEnum, setMessage, setStatus } from "../../redux/slices/layout_slice";
import apiRepository from "../../../data/repository";
import { setUser } from "../../redux/slices/user_slice";
import PutProfileUseCase from "../../../domain/usecases/put_profile_usecase";

export const PutProfileEvent = (params: {email: string,  first_name:string, last_name:string}) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new PutProfileUseCase(apiRepository);

    const result = await usecase.execute(params);

    result.fold(
        (error) => {
            dispatch(setMessage(error.message));
            dispatch(setStatus(StatusLayoutEnum.Error));
        },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            dispatch(setUser(data));
        }
    );
}
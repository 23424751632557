import React, { useEffect } from 'react';
import { GetContainersEvent } from '../../events/container/get_containers_event';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Container, Grid, Typography } from '@mui/material';
import { ContainerFullDetailEntity } from '../../../domain/entities';
import ContainerCard from '../../components/user_containers/containers_card';


const ContainersPage: React.FC = () => {
    const { containers } = useAppSelector(state => state.container);
    const dispatch = useAppDispatch();

    useEffect(() => {

        dispatch(GetContainersEvent());
    }, []);

    return (
        <Container sx={{ padding: '20px' }}>
            <Grid container direction={'column'}>
                <Typography
                    variant='h4'
                    sx={{
                        fontWeight: '600',
                        marginBottom: '30px',
                    }}
                >
                    Importaciones en curso
                </Typography>
                <Grid container spacing={2}>
                    {
                        containers.map((container: ContainerFullDetailEntity) => {
                            if (container.containerProducts.length === 0) return null;

                            return <Grid item xs={12} key={container.id}>
                                <ContainerCard container={container} />
                            </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </Container>
    );
};

export default ContainersPage;

import apiRepository from "../../../data/repository";
import PutProductUseCase from "../../../domain/usecases/put_product_usercase";
import { ProductEntity } from '../../../domain/entities';
import { CategoryProductEnum } from '../../../domain/entities';

function pasar_categoria_a_numero(category: CategoryProductEnum): number {
    const categoryValues = Object.values(CategoryProductEnum);
    const categoryIndex = Object.values(CategoryProductEnum).indexOf(category);

    if (categoryIndex === -1) {
        if (categoryIndex === -1) {
            return categoryValues.indexOf(CategoryProductEnum.OTHER);
        }
    }
    return categoryIndex;
}

export const PutProductEvent = async (
    id: string,
    name: string,
    price: number,
    category: CategoryProductEnum,
    description: string,
    width:number,
    length:number,
    depth:number,
    weight: number,
    materials: string,
    image_url: string,
    supplier: string,
): Promise<ProductEntity> => {
    const usecase = new PutProductUseCase(apiRepository);

    const result = await usecase.execute({
        id: id,
        name: name,
        price: price,
        category: pasar_categoria_a_numero(category),
        description: description,
        width: width,
        length: length,
        depth: depth,
        weight: weight,
        materials: materials,
        image_url: image_url,
        supplier:supplier
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const productEntity = result.right;
    if (!productEntity) {
        throw new Error("No se pudo crear el producto");
    }

    return productEntity;
}
import apiRepository from "../../../data/repository";
import CreateMetricUseCase from "../../../domain/usecases/create_metric_usecase";
import { MetricUnitEntity } from '../../../domain/entities';
import {addMetric} from '../../redux/slices/metric_slice';
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";

export const CreateMetricEvent = async (
    name: string,
    description: string,
    square_meters: number,
    dispatch,
): Promise<MetricUnitEntity> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new CreateMetricUseCase(apiRepository);
    const result = await usecase.execute({
        name: name,
        description: description,
        square_meters: square_meters
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const productEntity = result.right;
    if (!productEntity) {
        throw new Error("No se pudo crear la métrica");
    }
    dispatch(addMetric(productEntity));
    dispatch(setStatus(StatusLayoutEnum.Success));;
    return productEntity;
}
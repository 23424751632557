import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { CompanyEntity } from "../entities";

export default class GetCompaniesUsecase implements Usecase<CompanyEntity[]> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(): Promise<Either<CompanyEntity[]>> {
        const data = await this.repository.getCompanies();
        return data;
    }
};
import { TransactionEntityJSON } from '../transaction/transaction_entity';
import { PaymentEntityJson } from '../payment/payment_entity';

interface FullPaymentEntityJson extends PaymentEntityJson {
  transactions: PaymentPlanTransactionEntityJSON[];
  percentage: number;
}

interface PaymentPlanTransactionEntityJSON extends TransactionEntityJSON {
  additional_payments: Record<string, any>;
}

interface ProformaEntityJson {
  id: string;
  product_name: string;
  product_size: string;
  product_weight: string;
  product_materials: string;
  product_image_url: string;
  quantity: number;
  price: number;
  dispatch: boolean;
  address: string;
  total_amount: number;
  payments: FullPaymentEntityJson[];
  amount_paid: number;
  debt: number;
  payment_progress: number;
  current_step: string;
  next_step: string;
  proforma_deadline: string;
}

interface ContainerPaymentPlanEntityJson {
  id: string;
  proformas: ProformaEntityJson[];
  container_movements: Array<{
    id: number;
    status: number;
    is_completed: boolean;
    estimated_date: string;
    real_date: string;
    step: number;
  }>;
}

interface PaymentPlanEntityJson {
  id: string;
  owner: string;
  name: string;
  containers: ContainerPaymentPlanEntityJson[];
}

// Mapped Entities
export interface PaymentPlanTransactionEntity {
  id: string;
  amount: number;
  status: number;
  file: string | null;
  additional_payments: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export interface PaymentPlanPaymentEntity {
  id: string;
  transactions: PaymentPlanTransactionEntity[];
  percentage: number;
  dueDate: string;
  deadline: string;
  overpaymentAmount: number;
  overpaymentTransactions: PaymentPlanTransactionEntity[];
  amount: number;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export interface PaymentPlanProformaEntity {
  id: string;
  productName: string;
  productSize: string;
  productWeight: string;
  productMaterials: string;
  productImageUrl: string;
  quantity: number;
  price: number;
  dispatch: boolean;
  address: string;
  totalAmount: number;
  payments: PaymentPlanPaymentEntity[];
  amountPaid: number;
  debt: number;
  paymentProgress: number;
  currentStep: string;
  nextStep: string;
  proformaDeadline: string;
}

export interface PaymentPlanContainerEntity {
  id: string;
  proformas: PaymentPlanProformaEntity[];
  containerMovements: Array<{
    id: number;
    status: number;
    isCompleted: boolean;
    estimatedDate: string;
    realDate: string;
    step: number;
  }>;
}

export interface PaymentPlanEntity {
  id: string;
  owner: string;
  name: string;
  containers: PaymentPlanContainerEntity[];
}

export function mapJsonToPaymentPlanEntity(json: PaymentPlanEntityJson): PaymentPlanEntity {
  return {
    id: json.id,
    owner: json.owner,
    name: json.name,
    containers: json.containers.map((container) => ({
      id: container.id,
      proformas: container.proformas.map((proforma) => ({
        id: proforma.id,
        productName: proforma.product_name,
        productSize: proforma.product_size,
        productWeight: proforma.product_weight,
        productMaterials: proforma.product_materials,
        productImageUrl: proforma.product_image_url,
        quantity: proforma.quantity,
        price: proforma.price,
        dispatch: proforma.dispatch,
        address: proforma.address,
        totalAmount: proforma.total_amount,
        payments: proforma.payments.map((payment) => ({
          id: payment.id,
          transactions: payment.transactions.map((transaction) => ({
            id: transaction.id,
            amount: transaction.amount,
            status: transaction.status,
            file: transaction.file || null,
            additional_payments: transaction.additional_payments || {},
            createdAt: transaction.created_at,
            updatedAt: transaction.updated_at,
          })),
          percentage: payment.percentage,
          dueDate: payment.due_date,
          deadline: payment.deadline,
          overpaymentAmount: payment.overpayment_amount,
          overpaymentTransactions: payment.overpayment_transactions.map((transaction) => ({
            id: transaction.id,
            amount: transaction.amount,
            status: transaction.status,
            file: transaction.file || null,
            additional_payments: transaction.additional_payments || {},
            createdAt: transaction.created_at,
            updatedAt: transaction.updated_at,
          })),
          amount: payment.amount,
          status: payment.status,
          createdAt: payment.created_at,
          updatedAt: payment.updated_at,
        })),
        amountPaid: proforma.amount_paid,
        debt: proforma.debt,
        paymentProgress: proforma.payment_progress,
        currentStep: proforma.current_step,
        nextStep: proforma.next_step,
        proformaDeadline: proforma.proforma_deadline,
      })),
      containerMovements: container.container_movements.map((movement) => ({
        id: movement.id,
        status: movement.status,
        isCompleted: movement.is_completed,
        estimatedDate: movement.estimated_date,
        realDate: movement.real_date,
        step: movement.step,
      })),
    })),
  };
}

import { Either, Usecase } from "../../core/index";

import { ApiRepositoryImpl } from "../../data/repository/api";
import { CompanyPaymentsEntity } from "../entities";

export interface GetCompanyPaymentsParams {
    company_id: string;
}

export default class GetCompanyPaymentsUsecase implements Usecase<CompanyPaymentsEntity, GetCompanyPaymentsParams> {
    repository: ApiRepositoryImpl;

    constructor(repository: ApiRepositoryImpl) {
        this.repository = repository;
    }

    async execute(params: GetCompanyPaymentsParams): Promise<Either<CompanyPaymentsEntity>> {
        const data = await this.repository.getCompanyPayments(params.company_id);
        return data;
    }
}

import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { MetricUnitEntity } from '../entities';
import { ICreateMetric } from '../../data/datasource/api';

export default class CreateMetricUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: ICreateMetric): Promise<Either<MetricUnitEntity>> {
        return this.repository.createMetric(
            params.name,params.description,params.square_meters
        );
    }

};



export interface InvestmentEntityJson {
    id: string;
    name: string;
    total_amount: number;
    containers: any;
    proformas: any;
}

export interface InvestmentEntity {
    id: string;
    name: string;
    totalAmount: number;
    containers: any;
    proformas: any;
}

export function mapJsonToInvestmentEntity(json: InvestmentEntityJson): InvestmentEntity {
    return {
        id: json.id,
        name: json.name,
        totalAmount: json.total_amount,
        containers: json.containers,
        proformas: json.proformas,
    } as InvestmentEntity;
}
import { v4 as uuidv4 } from "uuid";
import apiRepository from "../../../data/repository";
import { ExternalPaymentEntity } from '../../../domain/entities/external_payments/external_payment_entity';
import SendExternalPaymentUseCase from "../../../domain/usecases/create_external_payment_usecase";

export const CreateExternalPaymentEvent = async (
  supplierId: string,
  containerId: string,
  amount: number,
  description: string,
  
): Promise<ExternalPaymentEntity> => {
  const usecase = new SendExternalPaymentUseCase(apiRepository);

  const result = await usecase.execute({
    id: uuidv4(),
    supplierId: supplierId,
    containerId: containerId,
    amount: amount,
    description: description,
  });

  // Verificar si es un error (isLeft)
  if (result.isLeft()) {
    throw new Error(result.left?.message || "Error desconocido");
  }

  // Verificar si right es nulo antes de retornarlo
  if (!result.right) {
    throw new Error("Error: La solicitud no devolvió un resultado válido.");
  }

  return result.right;
};

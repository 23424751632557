export interface ClarificationRequestEntity {
  id: string;
  proformaId: string;
  clientName: string;
  message: string;
  email: string;
  selectedTransaction: string;
}

export interface ClarificationRequestEntityJSON {
  id: string;
  proformaId: string;
  clientName: string;
  message: string;
  email: string;
  selectedTransaction: string;

}


export function mapJsonToClarificationRequestEntity(json): ClarificationRequestEntity {
  return {
    id: json.id,
    message: json.message,
    proformaId: json.proformaId,
    clientName: json.clientName,
    email: json.email,
    selectedTransaction: json.selectedTransaction
  } as ClarificationRequestEntity;
}
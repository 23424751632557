import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProviderInfo from "./provider_info";
import ContainerList from "./container_list";
import ContainerProductsModal from "./container_products_modal";

import {
  SupplierContainerEntity,
  SupplierEntity,
} from "../../../../domain/entities";


interface ProviderCardProps {
  supplier: SupplierEntity;
}

const ProviderCard: React.FC<ProviderCardProps> = ({ supplier }) => {
  const [selectedContainer, setSelectedContainer] =
    useState<SupplierContainerEntity>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");



  // Calcular la deuda total de todos los contenedores
  const totalDebt =
    supplier.containers?.reduce(
      (sum, container) => sum + (container.debt || 0),
      0
    ) || 0;

  // Calcular la cantidad total de contenedores
  const totalContainers = supplier.containers?.length || 0;

  // Calcular la cantidad total de productos en todos los contenedores
  const totalProducts = supplier.containers?.reduce(
    (sum, container) => sum + (container.containerProducts?.length || 0),
    0
  ) || 0;

  // Abrir modal de productos del contenedor
  const handleOpenDialog = (container: SupplierContainerEntity) => {
    setSelectedContainer(container);
    setOpenDialog(true);
  };

  // Cerrar modal de productos del contenedor
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedContainer(null);
  };

  // Abrir modal de pago
  const handleOpenPaymentDialog = () => {
    setOpenPaymentDialog(true);
  };

  // Cerrar modal de pago
  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
    setPaymentAmount("");
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        width: "100%",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "4px",
        marginBottom: "25px",
        overflow: "hidden",
      }}
    >
      <Grid container spacing={1} style={{ width: "100%" }}>
        {/* Nombre del proveedor y datos */}
        <Grid item xs={12}>
          <Typography variant="h6" align="left" gutterBottom>
            {supplier.name || "Nombre Proveedor"}
          </Typography>
        </Grid>

        {/* Información del proveedor */}
        <Grid container item xs={6} spacing={1}>
          <ProviderInfo supplier={supplier} />
        </Grid>

        {/* Lista de contenedores */}
        <Grid item xs={6}>
          <ContainerList
            containers={supplier.containers}
            onSelectContainer={handleOpenDialog}
          />
        </Grid>

        {/* Mostrar deuda total */}
        <Grid item xs={12}>
          <Typography variant="h6" align="center" sx={{ marginTop: "15px" }}>
            Deuda Acumulada: ${totalDebt.toFixed(0)}
          </Typography>
        </Grid>

        {/* Mostrar cantidad total de contenedores y productos */}
        <Grid item xs={12}>
          <Typography variant="body1" align="center" sx={{ marginTop: "10px" }}>
            Total de Contenedores: {totalContainers}
          </Typography>
          <Typography variant="body1" align="center">
            Total de Productos: {totalProducts}
          </Typography>
        </Grid>

        {/* Modal para mostrar productos del contenedor */}
        <ContainerProductsModal
          container={selectedContainer}
          open={openDialog}
          onClose={handleCloseDialog}
          onOpenPaymentDialog={handleOpenPaymentDialog}
        />
      </Grid>
    </Box>
  );
};

export default ProviderCard;

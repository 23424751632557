import { Either } from '../../core/either';
import { ApiRepository } from '../repository/api';
import { ProductEntity } from '../entities';

interface PutProductParams {
    id: string;
    name: string;
    price: number;
    category: number;
    description: string;
    width:number;
    length:number;
    depth:number;
    weight: number;
    materials: string;
    image_url: string;
    supplier: string;
}

export default class PutProductUseCase {
    private repository: ApiRepository;

    constructor(repository: ApiRepository) {
        this.repository = repository;
    }
    async execute(params: PutProductParams): Promise<Either<ProductEntity>> {
        return this.repository.putProduct(
            params.id,
            params.name,
            params.price,
            params.category,
            params.description,
            params.width,
            params.length,
            params.depth,
            params.weight,
            params.materials,
            params.image_url,
            params.supplier
        );
    }

};
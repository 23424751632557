import apiRepository from "../../../data/repository";
import GetCompanyInvestmentsUsecase from "../../../domain/usecases/get_company_investments";
import { setStatus, StatusLayoutEnum } from "../../redux/slices/layout_slice";
import { setCompanyInvestments } from "../../redux/slices/company_investments_slice";

export const GetCompanyInvestmentsEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetCompanyInvestmentsUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            dispatch(setCompanyInvestments([data])); // Si el slice necesita un array, lo envolvemos en uno.
        }
    );
};
import { ProductEntity } from "../product/product_entity";

interface ProductContainerEntity {
  quantity: number;
  min_quantity: number;
  max_quantity: number;
  unit_base_price: number;
  increased_percent: number;
  interval: number;
  units_per_package: number;
  created_at: string;
  updated_at: string;
  container: string;
  product: string;
  metric_unit: number;
}

interface ClientInfoProformaDetailsEntity {
  id: string;
  product_container: ProductContainerEntity;
}

export interface ClientInfoProformaEntity {
  id: string;
  proforma: ClientInfoProformaDetailsEntity;
  quantity: number;
  price: number;
  dispatch: boolean;
  address: string;
  created_at: string;
  updated_at: string;
  company: string;
}

interface CompanyEntity {
  id: string;
  name: string;
  rut: string;
  address: string;
  phone: string;
  email: string;
  owner: string;
}

export interface ContainerCostumerEntity {
  company: CompanyEntity;
  proformas: {
    proforma: ClientInfoProformaEntity;
  }[];
}

export interface ContainerClientInfoEntity {
  id: string;
  capacity: number;
  used_capacity: number;
  fixed_cost: number;
  tax: number;
  status: number;
  created_at: string;
  updated_at: string;
  customers: ContainerCostumerEntity[];
  container_products: {
    product: ProductEntity;
    quantity: number;
    unit_base_price: number;
    min_quantity: number;
    max_quantity: number;
    increased_percent: number;
    interval: number;
    units_per_package: number;
    metric_unit: number;
  }[];
}

export function mapJsonToContainerClientInfoEntity(json): ContainerClientInfoEntity {
  return {
    id: json.id,
    capacity: json.capacity,
    used_capacity: json.used_capacity,
    fixed_cost: json.fixed_cost,
    tax: json.tax,
    status: json.status,
    created_at: json.created_at,
    updated_at: json.updated_at,
    customers: json.customers.map((customer) => ({
      company: {
        id: customer.company.id,
        name: customer.company.name,
        rut: customer.company.rut,
        address: customer.company.address,
        phone: customer.company.phone,
        email: customer.company.email,
        owner: customer.company.owner,
      },
      proformas: customer.proformas.map((proforma) => ({
        proforma: {
          id: proforma.proforma.id,
          product_container: {
            id: proforma.proforma.product_container.id,  // Ensure this matches the type definition
            quantity: proforma.proforma.product_container.quantity,
            min_quantity: proforma.proforma.product_container.min_quantity,
            max_quantity: proforma.proforma.product_container.max_quantity,
            unit_base_price: proforma.proforma.product_container.unit_base_price,
            increased_percent: proforma.proforma.product_container.increased_percent,
            interval: proforma.proforma.product_container.interval,
            units_per_package: proforma.proforma.product_container.units_per_package,
            created_at: proforma.proforma.product_container.created_at,
            updated_at: proforma.proforma.product_container.updated_at,
            container: proforma.proforma.product_container.container,
            product: proforma.proforma.product_container.product,
            metric_unit: proforma.proforma.product_container.metric_unit,
          },
          quantity: proforma.proforma.quantity,
          price: proforma.proforma.price,
          dispatch: proforma.proforma.dispatch,
          address: proforma.proforma.address,
          created_at: proforma.proforma.created_at,
          updated_at: proforma.proforma.updated_at,
          company: proforma.proforma.company,
        }
      })),
    })),
    container_products: json.container_products.map((product) => ({
      product: {
        id: product.product.id,
        name: product.product.name,
        price: product.product.price,
        image_url: product.product.image_url,
        description: product.product.description,
        category: product.product.category,
        size: product.product.size,
        weight: product.product.weight,
        materials: product.product.materials,
      },
      quantity: product.quantity,
      unit_base_price: product.unit_base_price,
      min_quantity: product.min_quantity,
      max_quantity: product.max_quantity,
      increased_percent: product.increased_percent,
      interval: product.interval,
      units_per_package: product.units_per_package,
      metric_unit: product.metric_unit,
    })),
  } as ContainerClientInfoEntity;
}

export interface ProductEntity {
    id: string;
    name: string;
    price: number;
    category: CategoryProductEnum;
    description: string;
    width:number;
    length:number;
    depth:number;
    weight: number;
    size:string;
    materials: string;
    imageUrl: string;
    supplier: string;
    createdAt: string;
    updatedAt: string;
}


export enum CategoryProductEnum {
    KITCHEN = "KITCHEN",
    CONTRUCTION = "CONTRUCTION",
    BATHROOM = "BATHROOM",
    ELECTRONIC = "ELECTRONIC",
    CLOTHING = "CLOTHING",
    FURNITURE = "FURNITURE",
    OTHER = "OTHER"
}

export function mapJsonToProductEntity(json): ProductEntity {
    return {
        id: json.id,
        name: json.name,
        price: json.price,
        description: json.description,
        imageUrl: json.image_url,
        width: json.width,
        weight: json.weight,
        length: json.length,
        depth: json.depth,
        materials: json.materials,
        category: Object.values(CategoryProductEnum)[json.category],
        size:json.size,
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        supplier: json.supplier,
    } as ProductEntity;
}

export interface FullProductEntity {
    id: string;
    current_imports: CurrentImportEntity[];
    past_imports: PastImportsInterface[];
    name: string;
    price: number;
    description: string;
    imageUrl: string;
    category: CategoryProductEnum;
    createdAt: string;
    updatedAt: string;
    length: number;
    width: number;
    depth:number;
    materials: string;
    weight:string;
    supplier: string;
}


export function mapJsonToFullProductEntity(json): FullProductEntity {
    return {
        id: json.id,
        current_imports: json.current_imports.map(mapJsonToCurrentImportEntity),
        past_imports: json.past_imports.map(mapJsonToPastImportsInterface),
        name: json.name,
        price: json.price,
        description: json.description,
        imageUrl: json.image_url,
        weight: json.weight,
        materials: json.materials,
        category: Object.values(CategoryProductEnum)[json.category],
        createdAt: json.created_at,
        updatedAt: json.updated_at,
        length: json.length,
        width:json.width,
        depth: json.depth,
        supplier: json.supplier,
    } as FullProductEntity;
}
export interface CurrentImportPaymentEntity{
    percentage: number,
    due_date: string
}
export interface CurrentImportEntity{
    id: string;
    quantity: number;
    min_quantity: number;
    max_quantity: number;
    unit_base_price: number;
    increased_percent: number;
    interval: number;
    units_per_package: number;
    unit_purchase_price:number;
    payments: CurrentImportPaymentEntity[];
}

export function mapJSonCurrentImportPaymentEntity(json){
    return {
        percentage: json.percentage,
        due_date: json.due_date
    } as CurrentImportPaymentEntity
}
export function mapJsonToCurrentImportEntity(json): CurrentImportEntity {
    return {
        id: json.id,
        quantity: json.quantity,
        min_quantity: json.min_quantity,
        max_quantity: json.max_quantity,
        unit_base_price: json.unit_base_price,
        increased_percent: json.increased_percent,
        interval: json.interval,
        units_per_package: json.units_per_package,
        unit_purchase_price: json.unit_purchase_price,
        payments: json.container_payment.map(mapJSonCurrentImportPaymentEntity),
    } as CurrentImportEntity;
}

export interface PastImportsInterface{
    container_product_id:string,
    total_quantity: number,
    initial_date:string,
    finish_date: string
}
export function mapJsonToPastImportsInterface(json): PastImportsInterface {
    return {
        container_product_id: json.container_product_id,
        total_quantity: json.total_quantity,
        initial_date: json.initial_date,
        finish_date: json.finish_date
    } as PastImportsInterface;
}